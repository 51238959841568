<template>
    <div>
      <div class="">
         <div class="narnoo-flex narnoo-justify-between	narnoo-mt-5">
           <div class="narnoo-flex narnoo-items-center">
             <a href="javascript:void(0)" class="narnoo-flex narnoo-items-center"  @click="prevDay" v-if="this.showPrevButton">
                <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                 <path fill-rule="evenodd" clip-rule="evenodd" d="M5.70711 10.2071C5.31658 10.5976 4.68342 10.5976 4.2929 10.2071L0.292894 6.20711C-0.0976312 5.81658 -0.0976312 5.18342 0.292894 4.79289L4.29289 0.792894C4.68342 0.402369 5.31658 0.402369 5.70711 0.792894C6.09763 1.18342 6.09763 1.81658 5.70711 2.20711L3.41421 4.5L15 4.5C15.5523 4.5 16 4.94771 16 5.5C16 6.05228 15.5523 6.5 15 6.5L3.41421 6.5L5.70711 8.79289C6.09763 9.18342 6.09763 9.81658 5.70711 10.2071Z" fill="#9CA3AF"/>
                </svg>
               <span class="narnoo-text-sm narnoo-text-gray-500 narnoo-mx-5">Previous 6 days</span>
             </a>
             
           </div>
            <div class="narnoo-flex narnoo-items-center	">
              <a href="javascript:void(0)" class="narnoo-flex narnoo-items-center"  @click="nextDay">
                 <span class="narnoo-text-sm narnoo-text-gray-500 narnoo-mx-5">Next 6 daysasdasdasd</span>
                  <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.2929 0.792893C10.6834 0.402369 11.3166 0.402369 11.7071 0.792893L15.7071 4.79289C16.0976 5.18342 16.0976 5.81658 15.7071 6.20711L11.7071 10.2071C11.3166 10.5976 10.6834 10.5976 10.2929 10.2071C9.90238 9.81658 9.90238 9.18342 10.2929 8.79289L12.5858 6.5H1C0.447716 6.5 -2.41411e-08 6.05228 0 5.5C2.41411e-08 4.94772 0.447716 4.5 1 4.5H12.5858L10.2929 2.20711C9.90238 1.81658 9.90238 1.18342 10.2929 0.792893Z" fill="#9CA3AF"/>
                  </svg>
              </a>
            
            </div>
         </div>
      </div>
  
      <div class="narnoo-mt-10 narnoo-my-2 narnoo-overflow-x-auto">
        <div class="narnoo-flex narnoo-m-0">
          <div class="narnoo-flex-grow">
            <button type="button" class="narnoo-inline-flex narnoo-items-center narnoo-mr-1 bg-btn-white focus:narnoo-outline-none ">
            </button>
          </div>
          <div class="narnoo-flex-grow" v-for="(range,dateRangeIndex) in dateRange" :key="dateRangeIndex">
            <button type="button" class="narnoo-inline-flex narnoo-items-center narnoo-mr-1 bg-btn-blue focus:narnoo-outline-none ">
               {{ range.weekday }}<br> {{ range.date }} <br>{{ range.monthName }}
            </button>
          </div>
        </div>
  
        <div class="narnoo-bg-yellow-50 narnoo-border-l-4 narnoo-border-yellow-400 narnoo-p-4" v-if="inputDates.dateList[0]==null">
          <div class="narnoo-flex">
            <div class="narnoo-flex-shrink-0">
              <svg xmlns="http://www.w3.org/2000/svg" fill="#fc3" width="24" height="24" viewBox="0 0 24 24">
                <path d="M12 1l-12 22h24l-12-22zm-1 8h2v7h-2v-7zm1 11.25c-.69 0-1.25-.56-1.25-1.25s.56-1.25 1.25-1.25 1.25.56 1.25 1.25-.56 1.25-1.25 1.25z"></path>
              </svg>
            </div> 
            <div class="narnoo-ml-3">
                <p class="narnoo-ext-sm narnoo-text-yellow-700">
                  Bookings are not available for the following dates. Please select different dates.
              </p>
            </div>
          </div>
        </div>
  
        <div class="narnoo-flex" v-for="(timeData,timeIndex) in timeSlots" :key="timeIndex">
          <div class="narnoo-flex-grow narnoo-gap-1">
            <button type="button" class="narnoo-inline-flex narnoo-items-center narnoo-mr-1 narnoo-mb-1 bg-btn-black focus:narnoo-outline-none ">
              {{ timeData.time ? timeData.time : timeData == '00:00:00' ? '' : timeData }}
            </button>
          </div>
          <div class="narnoo-flex-grow" v-for="(dateData,dateIndex) in parseCalendarData(timeIndex)" :key="dateIndex">
            <!-- <button type="button" class="narnoo-inline-flex narnoo-flex-col narnoo-items-center narnoo-mr-1 narnoo-mb-1 focus:narnoo-outline-none "
            v-bind:class="[dateData !=null && dateData.availability>0 ? 'bg-btn-green' : 'bg-btn-red']"
            @click="dateData!=null  && dateData.availability>0  ? getBookingDetails(timeData.time ? timeData.time : timeData,dateData.supplierId,dateData.productId,dateData.bookingCode,dateData.bookingDateDisplay,dateData.bookingDate,timeIndex,dateIndex,productIndex,dateData.price) : null"
            > -->
            <button type="button" class="narnoo-inline-flex narnoo-flex-col narnoo-items-center narnoo-mr-1 narnoo-mb-1 focus:narnoo-outline-none "
            v-bind:class="[dateData !=null && dateData.availability>0  && dateData.additionalAvailable === true? 'bg-btn-green' : 'bg-btn-red']"
            v-bind:disabled="dateData.additionalAvailable === false ? true : false"
            @click="getDetails(dateData,timeIndex,dateIndex,productIndex)"
            >
            <div class="narnoo-leading-tight narnoo-hidden narnoo-flex narnoo-flex-col narnoo-items-center" :id="`bookingBtnLoader-${timeIndex}-${dateIndex}-${productIndex}`">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="narnoo-w-6 narnoo-h-6 narnoo-animate-spin">
                <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
              </svg>
              
              <span class="narnoo-text-xs">Loading</span>
            </div>
             <div class="narnoo-leading-tight narnoo-block" :id="`bookingBtnContent-${timeIndex}-${dateIndex}-${productIndex}`">
               {{ dateData !=null && dateData.availability>0 ? 'BOOK' : 'X'}}<br>
             <span class='narnoo-text-xs'>{{ dateData !=null && dateData.availability>0 ?'(' + dateData.availability+')' : ''}}</span>
             </div>
            </button>
          </div>
        </div>
         
      </div>
    </div>
  </template>
  
  <script>
  /**
   * calendar component
   * - props : 
   * - inputDats - array of availability dates
   * - nextDays - function that comes from the modal component. navigate calendar 
   * - prevDays - function that comes from the modal component navigate calendar 
   * - productIndex - component item index.
   * - navigateSteps - function from parent modal component.
   */
  export default {
    props: ['tourOption','inputDates','nextDay','prevDay','productIndex','navigateSteps','bookingCutoff','showPrevButton'],
      mounted() {
        this.parseInputDates(this.inputDates)
        
      },
      data(){
          return{
              calendarData:[],
              timeSlots:null,
              dateRange:null,
              startDate:null,
              endDate:null,
              bookLoading: false
          }
      },
      methods: {
  
        /**
         * Function that renders the calendar according to selected time id
         * params :
         * - productTimes - ProductTime array block coming from the availability/item.vue component.
         * - dateRange - array of dates to be displayed
         * - dateList - available dates for booking in calendar display
         */
  
        generateCalendarWithTimeId(productTimes,dateRange,dateList,bookingCode,supplierId,productId){
  
          let timeList= productTimes
  
          this.timeSlots=timeList
  
          // loops tru each time available
  
          for (let t=0; t < timeList.length; t++) {
  
            let calendarRow = []
            let timeRow =  timeList[t]
  
            // for each time row, we filter out all availability dates corresponding to the time id
            for (let dr=0; dr < dateRange.length; dr++) {
              //  let pickDate = dateRange[dr].toISOString().split("T")[0]
                let pickDate = this.parseDateFormat(dateRange[dr])
  
               // filtered by booking date and time  
              //  let filteredDateByTime = dateList.filter(data => data.bookingDateDisplay.split(" ")[1].indexOf(timeRow.time)>-1 && (data.bookingDate.indexOf(" ")>-1) ? data.bookingDate.split(" ")[0] == pickDate : data.bookingDate == pickDate)
              
              //  let filteredDateByTime = dateList.filter(data => data.bookingDateDisplay.split(" ")[1].indexOf(timeRow.time)>-1 &&  data.bookingDateDisplay.split(" ")[0] == pickDate.split("-").reverse().join("-"))
               let filteredDateByTime = dateList.filter(data => data.bookingDateDisplay.split(" ")[1].indexOf(timeRow.time)>-1 &&  data.bookingDateDisplay.split(" ")[0] == pickDate)
               
               //check if there's available date otherwise save it as null in our calendar row
               // this is to make sure the calendar displays 6 days even if there's any missing dates.
               // this make the calendar consistent in layout too.
               if(filteredDateByTime.length>0){
                 filteredDateByTime.map(d=>{
                    d.timeString= timeRow.time
                    d.timeId = timeRow.id
                    d.supplierId = supplierId
                    d.bookingCode = bookingCode+":"+timeRow.id
                    d.productId = productId
                  })
                 calendarRow.push(filteredDateByTime[0])
               }
               else{
                 calendarRow.push(null)
               }
            }
  
            //saves the created calendar row for ui display
            this.calendarData.push(calendarRow)
  
          }
  
        },
  
        /**
         * function that renders the calendar for non existing/null Product times
         * params
         * - dateRange - array of dates to be displayed
         * - dateList - available dates for booking in calendar display
         */
  
        generateCalendarWithOutTimeId(dateRange,dateList,bookingCode,supplierId,productId){
  
          // since we dont have any product time id, we generated the calendar row/time slots from each booking date display
          // saves the trailing time string in the bookingDateDisplay as timeSlots
            let timeSlots = []
            for(let x = 0; x < dateList.length; x++) {
              let bookingDate = dateList[x].bookingDateDisplay
              if(!timeSlots.includes(bookingDate.split(" ")[1])) {
                timeSlots.push(bookingDate.split(" ")[1])
              }
            }
  
            this.timeSlots=timeSlots
  
            // loops tru all timeslots 
  
            for (let t=0; t < timeSlots.length; t++) {
  
              let calendarRow = []
  
              for (let dr=0; dr < dateRange.length; dr++) {
                // let pickDate = dateRange[dr].toISOString().split("T")[0]
                
                let pickDate = this.parseDateFormat(dateRange[dr])
  
               //check if there's available date otherwise save it as null in our calendar row
               // this is to make sure the calendar displays 6 days even if there's any missing dates.
               // this make the calendar consistent in layout too.
  
                // let filteredDateByTime = dateList.filter(data => data.bookingDateDisplay.split(" ")[1].indexOf(timeSlots[t])>-1 &&  data.bookingDateDisplay.split(" ")[0] == pickDate.split("-").reverse().join("-"))
                let filteredDateByTime = dateList.filter(data => data.bookingDateDisplay.split(" ")[1].indexOf(timeSlots[t])>-1 &&  data.bookingDateDisplay.split(" ")[0] == pickDate)
                
                if(filteredDateByTime.length>0){
                  filteredDateByTime.map(d=>{
                      d.timeString= timeSlots[t]
                      d.timeId = null
                      d.supplierId = supplierId
                      d.bookingCode = bookingCode
                      d.productId = productId
                    })
                  calendarRow.push(filteredDateByTime[0])
                }
                else{
                  calendarRow.push(null)
                }
              }
              this.calendarData.push(calendarRow)
  
            }
        },
  
        /**
         * function that runs initially when calendar component is loaded.
         * this function manages 3 areas in the calendar display ( startDate, end Date, and availablity of productTimes)
         * - params:
         * - calendarDates - the availability array result from the item component
         */
  
        parseInputDates(calendarDates){
          // converts the dates to standard iso format
          this.startDate = calendarDates.startDate.split("-").reverse().join("-")
          this.endDate = calendarDates.endDate.split("-").reverse().join("-")
            
          // make sure to hide the prev date button once starting date is the current date
          if(calendarDates.startDate.split("-").reverse().join("-") == new Date().toISOString().split("T")[0]){
            this.showPrevButton=false;
          }
  
          // genearate the array of dates that acts as columns of the calendar
          var getDaysArray = function(start, end) {
              for(var arr=[],dt=new Date(start); dt<=end; dt.setDate(dt.getDate()+1)){
                  arr.push(new Date(dt))
              }
              return arr
          };
  
          let dateRange = getDaysArray(new Date(calendarDates.startDate.split("-").reverse().join("-")),new Date(calendarDates.endDate.split("-").reverse().join("-")))
  
          // add some date details to create the dates heading for each date
          dateRange.map(d=>{
            let selectedDate = d.toISOString().split("T")[0]
              d.weekday=new Date(selectedDate).toLocaleString('default', { weekday: 'long' })
              d.monthName=new Date(selectedDate).toLocaleString('default', { month: 'long' })
              d.longDate = new Date(selectedDate)
              d.date = selectedDate.split("-")[2]
          })
  
          this.dateRange = dateRange
  
          let dateList = calendarDates.dateList

          //check the dateList availability in each timeString if all of it is not false
          //then use another timeString if the first timeString is not available
          
          for(let x=0; x<dateList.length; x++){
            let notAvailable = 0;
            let listAvailable = dateList[x].additionalAvailable
              let available = dateList[x].availability
  
              if(listAvailable == false && available < 1){
                notAvailable++
              }  
          }
          
          if(dateList==null) return;
          // checks that determines which type of calandar to generate
          if(calendarDates.productTimes!=null) {
            this.generateCalendarWithTimeId(calendarDates.productTimes,this.dateRange,dateList,calendarDates.bookingCode,calendarDates.supplierId,calendarDates.productId)
          }
          else{
            this.generateCalendarWithOutTimeId(this.dateRange,dateList,calendarDates.bookingCode,calendarDates.supplierId,calendarDates.productId)
          }
        },
  
        parseDateFormat(date) {
          return date.toISOString().split("T")[0].split("-").reverse().join("-")
        },
  
        /**
         * function that returns only the dates from an specific timeIndex.
         * this generate the actual bookable buttons in the ui.
         */
  
        parseCalendarData(timeIndex) {
          return this.calendarData[timeIndex]
        },

        async getDetails(dateData, timeIndex,dateIndex,productIndex) {
          // shows the loader ui
          document.getElementById(`bookingBtnLoader-${timeIndex}-${dateIndex}-${productIndex}`).classList.remove("narnoo-hidden")
          document.getElementById(`bookingBtnContent-${timeIndex}-${dateIndex}-${productIndex}`).classList.remove("narnoo-block")
          document.getElementById(`bookingBtnContent-${timeIndex}-${dateIndex}-${productIndex}`).classList.add("narnoo-hidden")
          this.$emit('getBookingDetails', dateData)
          this.$emit('getBookingtimeIndex', timeIndex)
        },
  
        async getBookingDetails(selectedTime,supplierId,productId,bookingCode,bookingDateDisplay,bookingDate,timeIndex,dateIndex,productIndex,guestOptions) {
  
          // prevent any other calls while there is a booking call currently on process
          if(this.bookLoading==true) return;
  
          this.bookLoading=true
  
          // shows the loader ui
          document.getElementById(`bookingBtnLoader-${timeIndex}-${dateIndex}-${productIndex}`).classList.remove("hidden")
          document.getElementById(`bookingBtnContent-${timeIndex}-${dateIndex}-${productIndex}`).classList.remove("block")
          document.getElementById(`bookingBtnContent-${timeIndex}-${dateIndex}-${productIndex}`).classList.add("hidden")
  
          await fetch(process.env.NARNOO_API_URL + '/booking/details/'+supplierId+'/'+productId+'/?id='+bookingCode+'&date='+bookingDateDisplay.split(" ")[0], {
            method: 'GET',
            headers: {
              'Authorization': `bearer ${this.$getLocal('business.token').value}`
            }
          })
          .then(response => response.json())
          .then(data =>this.navigateSteps(2,{
            supplierId:supplierId,
            productId:productId,
            bookingCode:bookingCode,
            bookingDate: bookingDate,
            bookingDateDisplay: bookingDateDisplay,
            timeId: this.timeSlots[timeIndex].id,
            productTime: selectedTime,
            tourOption : this.tourOption,
            details:data,
            guestOptions:guestOptions
          }) )
          .then(()=>{
            // hides the loader ui
            this.bookLoading=false
          })
          .catch(error => console.log('User authentication '+error))
  
  
        },
  
      }
  
  }
  </script>
  
  <style>
  .blocks{
    display: none !important;
  }
  .kalendar-wrapper.gstyle .sticky-top .days{
    padding-left: 0% !important;
  }
  .kalendar-wrapper{
    margin-left: 14% !important;
  }
  .custom-btn-g{
    /* position: absolute;
    top: 34%; */
  }
  .bg-btn-black{
    background: #6B7280;
    font-size: 16px;
    color: white;
    width: 80px;
    height: 58px;
    white-space: nowrap;
    justify-content: center;
  }
  .bg-btn-green{
    background: #059669;
    font-size: 16px;
    color: white;
    width: 80px;
    height: 58px;
    white-space: nowrap;
    justify-content: center;
  }
  .bg-btn-red{
    width: 80px;
    height: 58px;
    background: #E11D48;
    font-size: 16px;
    color: white;
    white-space: nowrap;
    justify-content: center;
  }
  .day-indicator{
    background-color:#3B82F6;
    border-right: solid 2px var(--table-cell-border-color) !important;
    color: white !important;
  }
   .today .letters-date{
    color: white !important;
  }
  
  .mg-le-2{
    margin-left: -2px;
  }
  
  .mg-le-3{
    margin-left: -3px;
  }
  .mg-le-5{
    margin-left: 5.4rem;
  }
  .bg-btn-white{
    line-height: 1;
    width: 80px;
    height: 58px;
    background: #ffffff;
    font-size: 13px;
    color: white;
    white-space: nowrap;
    justify-content: center;
  }
  .bg-btn-blue{
    line-height: 1;
    width: 80px;
    height: 58px;
    background: #3B82F6;
    font-size: 13px;
    color: white;
    white-space: nowrap;
    justify-content: center;
  }
  </style>