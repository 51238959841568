<template>
	<div id="entry">
		<!-- Error Modal Window -->
		<ErrorModal :errorModal="errorModal"/>
		<!-- Skeleton loader start -->
		<div class="narnoo-shadow-md narnoo-overflow-hidden"  v-if="initAvailabilityLoader">
			<div class="narnoo-px-4 narnoo-mt-3">
				<div class="narnoo-grid narnoo-gap-3 md:narnoo-grid-cols-12">
					<div class="narnoo-col-span-6 md:narnoo-col-span-4 lg:narnoo-col-span-4">
						<div class="skeleton-loader narnoo-bg-gray-200 narnoo-h-10 narnoo-rounded"></div>
					</div>
				</div>
			</div>
			<div class="narnoo-mt-3 md:narnoo-mt-0 lg:narnoo-mt-0">
				<form action="#" method="POST">
					<div class="narnoo-overflow-hidden">
						<div class="narnoo-px-4 narnoo-p-3 narnoo-pb-5">
							<div class="narnoo-grid narnoo-gap-3 md:narnoo-grid-cols-12">
								<div class="narnoo-col-span-6 md:narnoo-col-span-6 lg:narnoo-col-span-4">
									<div class="skeleton-loader narnoo-bg-gray-200 narnoo-h-10 narnoo-rounded"></div>
								</div>
								<div class="narnoo-col-span-6 md:narnoo-col-span-6 lg:narnoo-col-span-4">
									<div class="skeleton-loader narnoo-bg-gray-200 narnoo-h-10 narnoo-rounded"></div>
								</div>
								<div class="narnoo-col-span-12 md:narnoo-col-span-12 lg:narnoo-col-span-4">
									<div class="skeleton-loader narnoo-bg-gray-200 narnoo-h-10 narnoo-rounded"></div>
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
		<!-- Skeleton loader end -->
		<!-- initError alert -->
		<div v-if="initError" class="narnoo-bg-teal-100 narnoo-border-t-4 narnoo-border-red-500 narnoo-rounded-b narnoo-text-teal-900 narnoo-px-4 narnoo-py-3 narnoo-shadow-md" role="alert">
			<div class="narnoo-flex">
				<div class="narnoo-py-1">
				<svg class="narnoo-fill-current narnoo-h-6 narnoo-w-6 narnoo-text-red-500 narnoo-mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
				<path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/>
				</svg>
				</div>
				<div>
				<p class="narnoo-font-bold">Invalid configuration.</p>
				<p class="narnoo-text-sm">Please make sure you have a valid widget configuration.</p>
				</div>
			</div>
		</div>
		<!-- initError alert -->
		<!-- Configuration error when the user has the range date status as true when it should be retail_ui ?? Not 100% sure -->
		<div v-if="rangeDateStatus === 'true'" class="narnoo-bg-teal-100 narnoo-border-t-4 narnoo-border-red-500 narnoo-rounded-b narnoo-text-teal-900 narnoo-px-4 narnoo-py-3 narnoo-shadow-md" role="alert" >
			<div class="narnoo-flex">
				<div class="narnoo-py-1">
				<svg class="narnoo-fill-current narnoo-h-6 narnoo-w-6 narnoo-text-red-500 narnoo-mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
				<path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/>
				</svg>
				</div>
				<div>
					<p class="narnoo-font-bold">Update in Configuration</p>
					<p class="narnoo-text-sm">Kindly stop using the rangeDateStatus props and instead use the retail_ui with a value of "true".</p>
				</div>
			</div>
		</div>

		<!-- UI for the calendar -->
		<div :class="(this.customTheme === true || this.customTheme === 'true' ? 'narnoo-primary-bg' : 'narnoo-bg-blue-900 narnoo-primary-bg')" v-if="initAvailability">
			<!-- If using the retailUi view -->
			<div v-if="retailUi === 'true' || retailUi === true">
				<div class="md:narnoo-grid md:narnoo-grid-cols-1 md:narnoo-gap-6 ">
					<div class="narnoo-col-span-1">
					<div class="narnoo-px-5 narnoo-pt-4">
						<div class="narnoo-text-2xl narnoo-font-medium narnoo-leading-7 narnoo-text-gray-100 narnoo-primary-text">Select Date</div>
					</div>
					</div>
				</div>
				<div class="narnoo-mt-3 md:narnoo-mt-0 sm:narnoo-col-span-2">
					<form action="#" method="POST">
						<div class="narnoo-overflow-hidden">
							<div class="narnoo-px-4 narnoo-p-3 narnoo-pb-5"> 
								<div class="narnoo-grid narnoo-grid-cols-8 narnoo-gap-3"> 
									<!-- Date picker -->
									<div class="narnoo-col-span-8 sm:narnoo-col-span-8 lg:narnoo-col-span-3">
										<date-picker
											class="narnoo-mt-1 focus:narnoo-ring-indigo-500 focus:narnoo-border-indigo-500 narnoo-block narnoo-w-full narnoo-shadow-sm sm:narnoo-text-sm narnoo-border-gray-300 narnoo-rounded-md"
											:default-value="new Date()" 
											valueType="format"
											format="DD-MM-YYYY"
											placeholder="Start date"
											:disabled-date="disabledBeforeToday"
											v-model="search.startDate"
										>
										</date-picker>
									</div>
					
									<!-- Optional time picker this only shows when time !== null  -->
									<div class="narnoo-col-span-8 sm:narnoo-col-span-8 lg:narnoo-col-span-3">
										<date-picker
											class="narnoo-mt-1 focus:narnoo-ring-indigo-500 focus:narnoo-border-indigo-500 narnoo-block narnoo-w-full narnoo-shadow-sm sm:narnoo-text-sm narnoo-border-gray-300 narnoo-rounded-md"
											:default-value="new Date()" 
											valueType="format"
											format="DD-MM-YYYY"
											placeholder="End date"
											:disabled-date="disabledBeforeToday"
											v-model="search.endDate"
										>
										</date-picker> 
									</div>
					
									<!-- Button action -->
									<div class="narnoo-col-span-8 sm:narnoo-col-span-8 lg:narnoo-col-span-2">
										<button type="button" :class="(customTheme === true || customTheme === 'true' ? 'narnoo-search-btn narnoo-w-full narnoo-inline-flex narnoo-justify-center narnoo-mt-1 narnoo-py-2 narnoo-px-4 narnoo-border narnoo-border-transparent narnoo-shadow-sm narnoo-text-md narnoo-font-medium narnoo-rounded-md narnoo-text-white narnoo-bg-blue-600 hover:narnoo-bg-blue-700 focus:narnoo-outline-none focus:narnoo-ring-2 focus:narnoo-ring-offset-2 focus:narnoo-ring-indigo-500 narnoo-secondary-button' : 'narnoo-search-btn narnoo-w-full narnoo-inline-flex narnoo-justify-center narnoo-mt-1 narnoo-py-2 narnoo-px-4 narnoo-border narnoo-border-transparent narnoo-shadow-sm narnoo-text-md narnoo-font-medium narnoo-rounded-md narnoo-text-white narnoo-bg-blue-600 hover:narnoo-bg-blue-700 focus:narnoo-outline-none focus:narnoo-ring-2 focus:narnoo-ring-offset-2 focus:narnoo-ring-indigo-500 narnoo-dark-button')"
										@click="checkRangeAvailability()"
										>
											Check availability
										</button>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
			<!-- If using the standard widget view -->
			<div v-else>
				<div class="md:narnoo-grid md:narnoo-grid-cols-1 md:narnoo-gap-6 ">
					<div class="narnoo-col-span-1">
						<div class="narnoo-px-5 narnoo-pt-4">
							<div class="narnoo-text-2xl narnoo-font-medium narnoo-leading-7 narnoo-text-gray-100">Select participants and date</div>
						</div>
					</div>
				</div>
				<div class="narnoo-mt-3 md:narnoo-mt-0 sm:narnoo-col-span-2">
					<form action="#" method="POST">
						<div class="narnoo-overflow-hidden">
							<div class="narnoo-px-4 narnoo-p-3 narnoo-pb-5">
								<div class="narnoo-grid narnoo-grid-cols-8 narnoo-gap-3">
									<!-- Guest Picker -->
									<div class="narnoo-col-span-8 sm:narnoo-col-span-4"
									v-bind:class="[ timeSlot==null  ? 'lg:narnoo-col-span-3' : 'lg:narnoo-col-span-2' ]"
									>
										<div class="guest-input" v-on:click="guestDropdownToggle()">
											<input type="text" name="guests" :id="`guests-${bookingId}`" ref="guests" placeholder="Select Guests" class="narnoo-input narnoo-mt-1 focus:narnoo-ring-indigo-500 focus:narnoo-border-indigo-500 narnoo-block narnoo-w-full narnoo-shadow-sm sm:narnoo-text-sm narnoo-border-gray-300 narnoo-rounded-md" readonly>
										</div>
										<transition name="guest-options-slide">
											<div v-if="show" class="narnoo-object-center narnoo-z-10 narnoo-bg-white narnoo-shadow-md narnoo-border-2 narnoo-p-3 narnoo-border-gray-300 narnoo-absolute" ref="guestDropdown" v-on:mouseover="show=true" v-on:mouseleave="show=false" >
												<div class="narnoo-mb-1 narnoo-flex narnoo-justify-between narnoo-flex-wrap guestLabels"  v-for="(options, index) in guestOptions" :key="index">
													<div :id="`guest-option-label-${bookingId}-${index}`" :data-guestid="`${options.id}`" :data-label ="`${options.label}`" :class="(customTheme === true || customTheme === 'true' ? 'narnoo-w-1/2 narnoo-primary-text' : 'narnoo-w-1/2')">
													{{ options.label }}
													</div>
													<div class="narnoo-w-1/2">
														<div class="narnoo-flex narnoo-justify-center narnoo-rounded-lg" role="group">
															<button v-on:click="addGuest(index,options,'minus')" type="button" 
															:class="(customTheme === true || customTheme === 'true' ? 'narnoo-bg-white narnoo-text-blue-500 narnoo-border narnoo-border-r-0 narnoo-border-blue-500 narnoo-secondary-button  narnoo-px-2 narnoo-py-1 narnoo-outline-none focus:narnoo-shadow-outline' : 'narnoo-bg-white narnoo-text-blue-500 narnoo-border narnoo-border-r-0 narnoo-border-blue-500 narnoo-px-2 narnoo-py-1 narnoo-outline-none focus:narnoo-shadow-outline')">
																<svg xmlns="http://www.w3.org/2000/svg" class="narnoo-h-6 narnoo-w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
																	<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18 12H6" />
																</svg>
															</button>
															<input type="text" name="" :id="`guest-no-${bookingId}-${index}`" value="0" readonly placeholder="0" :class="(customTheme === true || customTheme === 'true' ? `guest-no-${bookingId} narnoo-text-center narnoo-w-full narnoo-bg-white narnoo-text-blue-500 narnoo-border-blue-500 narnoo-px-2 narnoo-py-1 narnoo-outline-none focus:narnoo-shadow-outline narnoo-secondary-border narnoo-primary-text narnoo-no-border-radius` : `guest-no-${bookingId} narnoo-text-center narnoo-w-full narnoo-bg-white narnoo-text-blue-500 narnoo-border-blue-500 narnoo-px-2 narnoo-py-1 narnoo-outline-none focus:narnoo-shadow-outline narnoo-no-border-radius`)">
															<button v-on:click="addGuest(index,options,'add')" type="button"
															:class="(customTheme === true || customTheme === 'true' ? 'narnoo-bg-white narnoo-text-blue-500 narnoo-border narnoo-border-l-0 narnoo-border-blue-500 narnoo-secondary-button  narnoo-px-2 narnoo-py-1 narnoo-outline-none focus:narnoo-shadow-outline' : 'narnoo-bg-white narnoo-text-blue-500 narnoo-border narnoo-border-l-0 narnoo-border-blue-500 narnoo-px-2 narnoo-py-1 narnoo-outline-none focus:narnoo-shadow-outline')">
																<svg xmlns="http://www.w3.org/2000/svg" class="narnoo-h-6 narnoo-w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
																	<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
																</svg>
															</button>
														</div>
													</div>
												</div>
											</div>
										</transition>
									</div>

									<!-- Date picker -->
									<div class="narnoo-col-span-8 sm:narnoo-col-span-4 "
									v-bind:class="[ timeSlot==null ? 'lg:narnoo-col-span-3' : 'lg:narnoo-col-span-2' ]">
										<div id="datePicker" class="">
											<date-picker v-if="!rangeDate"
											class=" narnoo-mt-1 narnoo-block narnoo-w-full narnoo-shadow-sm sm:narnoo-text-sm narnoo-rounded-md"
											v-model="availability.schedule" 
											:default-value="new Date()" 
											valueType="format"
											format="DD-MM-YYYY"
											:disabled-date="disabledBeforeToday"
											@click="removeStyle()"
											>
											</date-picker>
											<date-picker v-if="rangeDate" range
											class=" narnoo-mt-1 focus:narnoo-ring-indigo-500 focus:narnoo-border-indigo-500 narnoo-block narnoo-w-full narnoo-shadow-sm sm:narnoo-text-sm narnoo-border-gray-300 narnoo-rounded-md"
											v-model="availability.range" 
											:default-value="new Date()" 
											valueType="format"
											format="DD-MM-YYYY"
											:disabled-date="disabledBeforeToday"
											>
											</date-picker>
										</div>
									</div>

									<!-- Optional time picker this only shows when time !== null  -->
									<div class="narnoo-col-span-8 sm:narnoo-col-span-8 lg:narnoo-col-span-2" v-if="timeSlot!=null">
										<select  class="narnoo-mt-1 narnoo-block narnoo-w-full narnoo-pl-3 narnoo-pr-10 narnoo-py-2 narnoo-text-base narnoo-border-gray-300 focus:narnoo-outline-none focus:narnoo-ring-indigo-500 focus:narnoo-border-indigo-500 sm:narnoo-text-sm narnoo-rounded-md" ref="timeSlot">
											<option v-for="(time,index) in timeSlot" :key="index" :value="`${time.id}`" :selected = "time.default" > {{time.time}}</option>
										</select>             
									</div>

									<!-- Button action -->
									<div class="narnoo-col-span-8 sm:narnoo-col-span-8 lg:narnoo-col-span-2">
										<button type="button" :class="(customTheme === true || customTheme === 'true' ? 'narnoo-search-btn narnoo-w-full narnoo-inline-flex narnoo-justify-center narnoo-mt-1 narnoo-py-2 narnoo-px-4 narnoo-border narnoo-border-transparent narnoo-shadow-sm narnoo-text-md narnoo-font-medium narnoo-rounded-md narnoo-text-white narnoo-bg-blue-600 hover:narnoo-bg-blue-700 focus:narnoo-outline-none focus:narnoo-ring-2 focus:narnoo-ring-offset-2 focus:narnoo-ring-indigo-500 narnoo-secondary-button' : 'narnoo-search-btn narnoo-w-full narnoo-inline-flex narnoo-justify-center narnoo-mt-1 narnoo-py-2 narnoo-px-4 narnoo-border narnoo-border-transparent narnoo-shadow-sm narnoo-text-md narnoo-font-medium narnoo-rounded-md narnoo-text-white narnoo-bg-blue-600 hover:narnoo-bg-blue-700 focus:narnoo-outline-none focus:narnoo-ring-2 focus:narnoo-ring-offset-2 focus:narnoo-ring-indigo-500 narnoo-dark-button')"
										v-on:click="checkAvailability()"
										>
											Check availability
										</button>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
			<!-- Alert for cart button - takes the user to the cart page if it's set-->
			<CartAlert v-if="(displayCartButton===true || displayCartButton ==='' || displayCartButton ==='true' || displayCartButton===undefined) && !initAvailabilityLoader && cartCount>0" :checkoutUrl="checkoutUrl"/>
		</div>

		<!-- Is searching skeleton loader [start] -->
		<div v-if="isSearchLoading">
			<div class="narnoo-w-full narnoo-mx-auto narnoo-mt-7">
				<div class="tab narnoo-w-full narnoo-overflow-hidden narnoo-border narnoo-border-gray-300 narnoo-p-4">
					<div class="narnoo-grid narnoo-grid-cols-12 narnoo-gap-3 narnoo-overflow-hidden">
						<div class="narnoo-col-span-12 md:narnoo-col-span-12 lg:narnoo-col-span-4">
							<div class="skeleton-loader narnoo-bg-gray-200 narnoo-h-10 narnoo-rounded"></div>
						</div>
						<div class="narnoo-col-span-12 md:narnoo-col-span-12 lg:narnoo-col-span-4">
							<div class="skeleton-loader narnoo-bg-gray-200 narnoo-h-10 narnoo-rounded"></div>
						</div>
						<div class="narnoo-col-span-12 md:narnoo-col-span-12 lg:narnoo-col-span-4">
							<div class="skeleton-loader narnoo-bg-gray-200 narnoo-h-10 narnoo-rounded"></div>
						</div>
					</div>

					<div class="tab-content narnoo-overflow-hidden narnoo-leading-normal narnoo-w-full narnoo-mt-1">
						<div class="narnoo-border-gray-200 narnoo-pl-0 narnoo-pb-1 narnoo-border-l-0 narnoo-border-t-2"></div>
						<div class="narnoo-grid narnoo-grid-cols-12">
							<div class="narnoo-col-start-1 narnoo-col-span-1">
								<div class="skeleton-loader narnoo-bg-gray-200 narnoo-h-8 narnoo-rounded"></div>
							</div>
							<div class="narnoo-col-start-12 narnoo-col-span-1">
								<div class="skeleton-loader narnoo-bg-gray-200 narnoo-h-8 narnoo-rounded"></div>
							</div>
						</div>
						<!-- //calendar -->
						<div v-if="rangeDate" class="narnoo-grid narnoo-grid-cols-7 narnoo-gap-1 narnoo-mx-auto narnoo-w-1/2">
							<div class="narnoo-col-span-1">
								<div class="skeleton-loader narnoo-bg-gray-200 narnoo-h-14 narnoo-rounded"></div>
							</div>
							<div class="narnoo-col-span-1">
								<div class="skeleton-loader narnoo-bg-gray-200 narnoo-h-14 narnoo-rounded"></div>
							</div>
							<div class="narnoo-col-span-1">
								<div class="skeleton-loader narnoo-bg-gray-200 narnoo-h-14 narnoo-rounded"></div>
							</div>
							<div class="narnoo-col-span-1">
								<div class="skeleton-loader narnoo-bg-gray-200 narnoo-h-14 narnoo-rounded"></div>
							</div>
							<div class="narnoo-col-span-1">
								<div class="skeleton-loader narnoo-bg-gray-200 narnoo-h-14 narnoo-rounded"></div>
							</div>
							<div class="narnoo-col-span-1">
								<div class="skeleton-loader narnoo-bg-gray-200 narnoo-h-14 narnoo-rounded"></div>
							</div>
							<div class="narnoo-col-span-1">
								<div class="skeleton-loader narnoo-bg-gray-200 narnoo-h-14 narnoo-rounded"></div>
							</div>
						</div>

						<div v-if="!rangeDate" class="narnoo-mt-2">
							<div class="narnoo-grid narnoo-grid-cols-12 narnoo-gap-1 narnoo-mx-auto">
								<div class="narnoo-col-span-3">
									<div class="skeleton-loader narnoo-bg-gray-200 narnoo-h-8 narnoo-rounded"></div>
								</div>
							</div>
							<div class="narnoo-grid narnoo-grid-cols-12 narnoo-gap-1 narnoo-mx-auto narnoo-mt-1">
								<div class="narnoo-col-span-3">
									<div class="skeleton-loader narnoo-bg-gray-200 narnoo-h-8 narnoo-rounded"></div>
								</div>
							</div>
						</div>
						<div class="narnoo-border-gray-200 narnoo-border-t-2 narnoo-my-1"></div>
						<div class="md:narnoo-w-7/12 narnoo-ml-auto narnoo-mt-1">
							<div class="narnoo-grid narnoo-grid-cols-12">
								<div class="narnoo-col-span-12 md:narnoo-col-span-3 lg:narnoo-col-span-4">
									<div class="skeleton-loader narnoo-bg-gray-200 narnoo-h-6 narnoo-rounded"></div>
								</div>
							</div>
							
							<div class="narnoo-border-gray-200 narnoo-border-t-2 narnoo-my-1"></div>
							<div class="narnoo-grid narnoo-grid-cols-12 narnoo-my-1">
								<div class="narnoo-col-start-1 narnoo-col-span-4 md:narnoo-col-span-3 lg:narnoo-col-span-4">
									<div class="skeleton-loader narnoo-bg-gray-200 narnoo-h-4 narnoo-rounded"></div>
								</div>
								<div class="narnoo-col-start-11 md:narnoo-col-start-10 narnoo-col-span-2 md:narnoo-col-span-3">
									<div class="skeleton-loader narnoo-bg-gray-200 narnoo-h-4 narnoo-rounded"></div>
								</div>
							</div>
							<div class="narnoo-grid narnoo-grid-cols-12 narnoo-my-1">
								<div class="narnoo-col-start-1 narnoo-col-span-4 md:narnoo-col-span-3 lg:narnoo-col-span-4">
									<div class="skeleton-loader narnoo-bg-gray-200 narnoo-h-4 narnoo-rounded"></div>
								</div>
								<div class="narnoo-col-start-11 md:narnoo-col-start-10 narnoo-col-span-2 md:narnoo-col-span-3">
									<div class="skeleton-loader narnoo-bg-gray-200 narnoo-h-4 narnoo-rounded"></div>
								</div>
							</div>
							<div class="narnoo-border-gray-200 narnoo-border-t-2 narnoo-my-1"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- Is searching skeleton loader [end] -->

		<!-- Not bookable product -->
		<div class="narnoo-border-t-4 narnoo-border-red-500" v-if="noLiveBooking">
			<div class="narnoo-text-center narnoo-flex narnoo-justify-center narnoo-items-center narnoo-mt-5 narnoo-max-w-1/2"  v-if="isGuestOptionAvailableInAvailability==true">
				<svg xmlns="http://www.w3.org/2000/svg" class="narnoo-mx-5 narnoo-h-12 narnoo-w-12 narnoo-text-gray-400" viewBox="0 0 20 20" fill="currentColor">
					<path vector-effect="non-scaling-stroke" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 100-2 1 1 0 000 2zm7-1a1 1 0 11-2 0 1 1 0 012 0zm-7.536 5.879a1 1 0 001.415 0 3 3 0 014.242 0 1 1 0 001.415-1.415 5 5 0 00-7.072 0 1 1 0 000 1.415z" clip-rule="evenodd" />
				</svg>
				<p class="narnoo-mt-1 narnoo-text-sm narnoo-text-gray-500">
				Live booking unavailable at this time
				</p>
			</div>
		</div>
		<!-- Availability is not found -->
		<!-- I think we need a more elegant UI solution here -->
		<div class="narnoo-border-t-4 narnoo-border-red-500" v-if="availabilityNotFound">
			<!-- New message for no product options -->
			<div class="narnoo-text-center narnoo-flex narnoo-justify-center narnoo-items-center narnoo-mt-5 narnoo-max-w-1/2"  v-if="isGuestOptionAvailableInAvailability==true">
				<svg xmlns="http://www.w3.org/2000/svg" class="narnoo-mx-5 narnoo-h-12 narnoo-w-12 narnoo-text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
				<path stroke-linecap="round" stroke-linejoin="round" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
				</svg>
				<p class="narnoo-mt-1 narnoo-text-sm narnoo-text-gray-500">
				Unfortunately we are no longer accepting bookings for this date. Please find the next available tour dates
				</p>
			</div>

			<!-- new alert messaage for non existing guestOption in availability response -->
			<div class="narnoo-text-center narnoo-flex narnoo-justify-center narnoo-items-center narnoo-mt-5 narnoo-max-w-1/2" v-if="isGuestOptionAvailableInAvailability==false">
				<svg xmlns="http://www.w3.org/2000/svg" class="narnoo-mx-5 narnoo-h-12 narnoo-w-12 narnoo-text-gray-400" viewBox="0 0 20 20" fill="currentColor">
				<path vector-effect="non-scaling-stroke" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 100-2 1 1 0 000 2zm7-1a1 1 0 11-2 0 1 1 0 012 0zm-7.536 5.879a1 1 0 001.415 0 3 3 0 014.242 0 1 1 0 001.415-1.415 5 5 0 00-7.072 0 1 1 0 000 1.415z" clip-rule="evenodd" />
				</svg>
				<p class="narnoo-mt-1 narnoo-text-sm narnoo-text-gray-500">
				Unfortunately there is no availabilty for the selected guest participant. Please select a different option when selecting participants.
				</p>
			</div>

			<div class="narnoo-w-full" v-if ="showDaysUiLoader">
				<div  class="narnoo-overflow-x-auto" style="max-width:620px;margin-left:auto;margin-right:auto">
					<div class="narnoo-animate-pulse narnoo-mb-3 narnoo-px-2">
					<div class="narnoo-flex narnoo-justify-between narnoo-py-4">
						<div class="narnoo-h-4 narnoo-bg-gray-200 narnoo-w-32 narnoo-rounded-lg"></div>
						<div class="narnoo-h-4 narnoo-bg-gray-200 narnoo-w-32 narnoo-rounded-lg"></div>
					</div>
					<div v-for="row in 1" :key="row" class="narnoo-my-2 narnoo-flex narnoo-items-center narnoo-overflow-hidden narnoo-justify-between narnoo-border-gray-300 narnoo-rounded-md">
						<div v-for="i in 7" :key="i" class="narnoo-flex narnoo-p-3 narnoo-flex-auto narnoo-mx-1 narnoo-space-y-1 narnoo-flex-col narnoo-items-center narnoo-w-14 narnoo-h-14 narnoo-bg-gray-300">
							<div class="narnoo-h-2 narnoo-bg-gray-200 narnoo-w-full narnoo-rounded-lg"></div>
							<div class="narnoo-h-2 narnoo-bg-gray-200 narnoo-w-3/5 narnoo-rounded-lg"></div>
							<div class="narnoo-h-2 narnoo-bg-gray-200 narnoo-w-full narnoo-rounded-lg"></div>
						</div>
					</div>
					</div>
				</div>
			</div>

			<div class="narnoo-overflow-x-auto" v-if="showDaysUi && !showDaysUiLoader">
				<div class="narnoo-ml-auto narnoo-mr-auto" style="width:624px;">
					<div class="narnoo-pb-5">
						<div class="narnoo-flex narnoo-justify-between narnoo-mt-5">
							<div class="narnoo-flex narnoo-items-center">
								<a href="javascript:void(0)" class="narnoo-flex narnoo-ml-5 narnoo-items-center" v-if="showPrevButton"  @click="prevDays()">
									<svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path fill-rule="evenodd" clip-rule="evenodd" d="M5.70711 10.2071C5.31658 10.5976 4.68342 10.5976 4.2929 10.2071L0.292894 6.20711C-0.0976312 5.81658 -0.0976312 5.18342 0.292894 4.79289L4.29289 0.792894C4.68342 0.402369 5.31658 0.402369 5.70711 0.792894C6.09763 1.18342 6.09763 1.81658 5.70711 2.20711L3.41421 4.5L15 4.5C15.5523 4.5 16 4.94771 16 5.5C16 6.05228 15.5523 6.5 15 6.5L3.41421 6.5L5.70711 8.79289C6.09763 9.18342 6.09763 9.81658 5.70711 10.2071Z" fill="#9CA3AF"/>
									</svg>
									<span class="narnoo-text-sm narnoo-text-gray-500 narnoo-mx-5">Previous 7 days</span>
								</a>
							</div>
							<div class="narnoo-flex narnoo-items-center	">
								<a href="javascript:void(0)" class="narnoo-flex narnoo-items-center narnoo-mr-5" @click="nextDays()" >
								<span class="narnoo-text-sm narnoo-text-gray-500 narnoo-mx-5">Next 7 days</span>
									<svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path fill-rule="evenodd" clip-rule="evenodd" d="M10.2929 0.792893C10.6834 0.402369 11.3166 0.402369 11.7071 0.792893L15.7071 4.79289C16.0976 5.18342 16.0976 5.81658 15.7071 6.20711L11.7071 10.2071C11.3166 10.5976 10.6834 10.5976 10.2929 10.2071C9.90238 9.81658 9.90238 9.18342 10.2929 8.79289L12.5858 6.5H1C0.447716 6.5 -2.41411e-08 6.05228 0 5.5C2.41411e-08 4.94772 0.447716 4.5 1 4.5H12.5858L10.2929 2.20711C9.90238 1.81658 9.90238 1.18342 10.2929 0.792893Z" fill="#9CA3AF"/>
									</svg>
								</a>
							</div>
						</div>
					</div>

					<div class="narnoo-flex narnoo-flex-wrap narnoo-mx-5 narnoo-gap-0 narnoo-gap-1" >
						<div class="" v-for="(range,dateRangeIndex) in dateRange" :key="dateRangeIndex">
							<button type="button" class="focus:narnoo-outline-none"
							v-bind:class="[range !=null && range.availability>0 ? 'bg-btn-green' : 'bg-btn-red']"
							@click="range!=null  && range.availability>0  ? checkAvailability(range.pickDate) : null"
							>
								{{range.weekday}} <br>  {{range.date}}  <br> {{range.monthName}}
							</button>
						</div>
					</div>
				</div>
			</div>
			<!-- <div class="narnoo-bg-yellow-50 narnoo-border narnoo-border-red-400 narnoo-text-red-700 narnoo-px-4 narnoo-py-3 narnoo-rounded narnoo-relative" role="alert">
				<span class="narnoo-block sm:narnoo-inline">No available products for this date.</span>
				<span class="narnoo-absolute narnoo-top-0 narnoo-bottom-0 narnoo-right-0 narnoo-px-4 narnoo-py-3">
				<svg class="narnoo-fill-current narnoo-h-6 narnoo-w-6 narnoo-text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
				</span>
			</div> -->
		</div>
		
		<!-- Availability Result component -->
		<AvailabilityResult 
		v-bind:errorModal="errorModal" 
		v-bind:timeSlot="timeSlot" 
		v-bind:availabilityResults="availabilityResults" 
		v-bind:displayExtras="displayExtras"
		v-bind:checkoutUrl="checkoutUrl"
		v-bind:pickupId="pickupId" 
		v-bind:displayDescription="displayDescription"   
		v-bind:businessId="businessId"   
		v-bind:operatorId="operatorId"   
		v-bind:bookingCutoff="bookingCutoff" 
		v-bind:generateMiniCalender="generateMiniCalender"  
		v-bind:rangeDate="rangeDate"
		v-bind:rangeData="calendarData"
		v-bind:navigateSteps="navigateSteps"
		v-bind:nextDays="nextDays"
		v-bind:prevDays="prevDays"
		v-bind:showPrevButton="showPrevButton"
		v-bind:customTheme="customTheme"
		v-bind:retailUi="retailUi"
		v-bind:availabilityData="availabilityData"
		v-bind:steps="steps"
		v-bind:currentStep="currentStep"
		v-bind:productName="productName"
		v-bind:bookingDetails="bookingDetails"
		v-bind:useRangeCalendar="useRangeCalendar"
		v-bind:reloadCalendar="reloadCalendar"
		v-bind:calendarIndex="calendarIndex"
		v-bind:displayGuestPrice="displayGuestPrice"
		v-if="showAvailabilityResults && isSearchLoading==false">
		</AvailabilityResult>
	</div>
</template>

<script>
//Date picker component
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

const { encrypt,decrypt } = require('../helpers/crypto');

import * as axios from "axios";

import AvailabilityResult from './AvailabilityResult.vue';
import ErrorModal from './ErrorModal.vue';

import CartAlert from './CartAlert.vue';

import moment from 'moment';

import Analytics from '@expo/rudder-sdk-node';

import { v4 as uuidv4 } from 'uuid';

/**
 * Analytics
 * @description The client to manage our Rudderstack analytics.
 */
const client = new Analytics(process.env.VUE_APP_WRITE_KEY, process.env.VUE_APP_DATA_PLAN_URL);

/**
 * Set up our UI steps
 */
const steps = [
    { id: 'Step 1', name: 'Check Availablity', href: '#', status: 'current' },
    { id: 'Step 2', name: 'Select Guests', href: '#', status: 'upcoming' },
    { id: 'Step 3', name: 'Add to Cart', href: '#', status: 'upcoming' },
]

/**
 * We require a guest quantity button something like
 * https://codepen.io/JuniorE/pen/BZYGMa
 * 
 * We need to be able to select all the required guests i.e: 1 x Adult + 1 x Child etc
 * 
 * Look like a checkbox type examples:
 * https://codepen.io/RobotsPlay/pres/pyNLdL
 * 
 */

export default {
	name: "Availability",
	components: { DatePicker , AvailabilityResult , ErrorModal, CartAlert },
	/**
	 * As per initial app props
	 * These are in CamelCase
	 */
	props: [
		'accessKey',
		'operatorId',
		'bookingId',
		'checkoutUrl',
		'bookingCode',
		'pickupId',
		'displayDescription',
		'displayCartButton',
		'displayExtras',
		'bookingCutoff',
		'rangeDateStatus',
		'reservationId',
		'customTheme',
		'retailUi',
		'displayGuestPrice'
	],
	data() {
		/**
		 * @param {Array} steps - Sets up the UI wizard steps
		 * @param {int} currentSteps - Current step the user is on
		 * @param {Array} cartList - The list if items in the users shopping cart
		 * @param {Array} allProducts - All the products?
		 * @param {String} featuredImage - A response from the API with the product image.
		 * @param {Boolean} show - Show something?
		 * @param {Int} businessId - The Narnoo ID of the business making the request - Referred to in this case as an AGENT.
		 * @param {String} businessType - The business type from within Narnoo. ( operator | distributor ) Majority of these will be distributor
		 * @param {Int} operatorID - The operator as per the prop
		 * @param {Int} productId - The operator's product ID
 		 * @param {Date} startDate - Availability start date
		 * @param {Date} endDate - Availability end date
		 * @param {String} prudctDescription - Product Description from the API ( spelling mistake )
		 * @param {Array} guestOptions - The guest options ie. Adult / child and family. this
		 * @param {Array} timeSlot - Are there any timeslots available for this product
		 * @param {Array} guestOptionsData
		 * @param {Object} availability
		 * @param {Boolean} useRangeCalendar - Are we using the range calendar or just the single date selector
		 * @param {Object} search - The start and end dates to seach in the range calendar
		 * @param {Array} availabilityResults - The availability results found in the search
		 * @param {Boolean} availabilityNotFound - If we have found availability or not
		 * @param {Boolean} isSearchLoading - Is the UI in search mode
		 * @param {Boolean} showAvailabilityResults - Are there availability results to show
		 * @param {Boolean} initAvailability
		 * @param {Boolean} initAvailabilityLoader
		 * @param {Boolean} initError - Was there an initial error
		 * @param {Array} notAvailableDates - API call which returns when products aren't available. Blocks these off in the Calendar UI.
		 * @param storedProductTimes
		 * @param {Int} productAvailabilityCouter
		 * @param {String} bookingPlatForm - Which booking platform was the supplier is using.
		 * @param {String} productName - The name of the product
		 * @param {Boolean} showDaysUi - ?
		 * @param dateRange - ?
		 * @param {Boolean} showPrevButton
		 * @param {Array} allProductOptionsAvailabilityResponse
		 * @param showDaysUiLoader
		 * @param isGuestOptionAvailableInAvailability
		 * @param {Object} errorModal
		 * @param {Int} cartCount - The number of items in the cart.
		 * @param {Boolean} noLiveBooking - If there aren't any bookable products
		 * @param {Array} calendarData - Calendar UI data so we can show availability or block off dates.
		 * @param {Boolean} rangeDate
		 * @param {Boolean} nextStatus - Mini Calendar next status
		 * @param {Boolean} prevStatus - Mini Calendar prev status
		 * @param bookingProducts
		 * @param {Array} availabilityList - ?
		 * @param {Array} availabilityData - ?
		 * @param bookingDetails - ?
		 * @param {Boolean} reloadCalendar - Mini calender needs to be reloaded
		 * @param {Int} calendarIndex - Where the user is in the mini calendar
		 */
		return {
			steps: steps,
			currentStep: 1,
			cartList:[],
			allProducts:[],
			featuredImage:null,
			show:false,
			businessId:null,
			businessType: null,
			operatorID: null,
			productId: null,
			startDate: null,
			endDate: null,
			prudctDescription: null,
			guestOptions:[],
			timeSlot:[],
			guestOptionsData:[],
			availability:{
				schedule: null,
				guest: null,
				time: null,
				range: null
			},
			useRangeCalendar:false,
			search: {
				startDate: null,
				endDate: null,
			},
			availabilityResults : [],
			availabilityNotFound:false,
			isSearchLoading:false,
			showAvailabilityResults:false,
			initAvailability:false,
			initAvailabilityLoader: true,
			initError:false,
			notAvailableDates:[],
			storedProductTimes:null,
			productAvailabilityCouter:0,
			bookingPlatForm:null,
			productName:null,
			showDaysUi:false,
			dateRange:null,
			showPrevButton:false,
			allProductOptionsAvailabilityResponse:[],
			showDaysUiLoader:false,
			isGuestOptionAvailableInAvailability:true,
			errorModal:{
				show:false,
				errors:[]
			},
			cartCount:0,
			noLiveBooking:false,
			calendarData: [],
			rangeDate: false,
			nextStatus: false,
			prevStatus: false,
			bookingProducts: null,
			availabilityList:[],
			availabilityData:[],
			bookingDetails: null,
			reloadCalendar:false,
			calendarIndex: 0,
		};
	},
	mounted() {
		if(this.rangeDateStatus === undefined || this.rangeDateStatus === '' || this.rangeDateStatus === 'false'){
			this.rangeDate = false
		}
		else{
			this.rangeDate = true
		}
		/**
		 * @description - If a reservationID is set then we need to store this for the cart.
		 * @comment - This is for systems like RESLY where users can charge bookings to their rooms.
		 * @comment - Need to manage states so if there isn't one set we need to remove from localstorage.
		 */
		if(this.reservationId){
			localStorage.setItem("reservationId",JSON.stringify(encrypt(this.reservationId)))
		}
		else{
			localStorage.removeItem("reservationId");
		}
		//Rudderstack CLIENT
		this.loadRudderStack();
		//extend javascript date object to have add and subtract date
		Date.prototype.addDays = function(days) {
			this.setDate(this.getDate() + parseInt(days));
			return this;
		};
		Date.prototype.subtractDays = function(days) {
			this.setDate(this.getDate() - parseInt(days));
			return this;
		};
		
		/**
		 * Get information from localstorage
		 * cart - check if there are any items within the cart key of localstorage
		 * accessKey - check if the accessKey has been set for this widget
		 * widgetInit - store the first initalisation call from Narnoo api. This sets up and controls the widget.
		 * cartData - check if there is any cart data
		 */
		let cart = JSON.parse(localStorage.getItem("cart"));
		let accessKey = JSON.parse(localStorage.getItem("accessKey"));
		let widgetInit = JSON.parse(localStorage.getItem("widgetInitData"));
		let cartData = JSON.parse(localStorage.getItem("cartData"));
		let currency = JSON.parse(localStorage.getItem("currency"));

		/**
		 * @description - If there is cart data then we need to retrieve this. Else set up empty variables.
		 */
		if(cart!=null) {
			cart = JSON.parse(decrypt(JSON.parse(localStorage.getItem("cart"))))
		}else{
			localStorage.setItem("cart",JSON.stringify(encrypt('[]')));
			cart =  JSON.parse(decrypt(JSON.parse(localStorage.getItem("cart"))))
		} 

		/**
		 * Define our cart list based on what we found in localstorage
		 */
		this.cartList = cart;
		(accessKey!=null) ? null : localStorage.setItem("accessKey",JSON.stringify(encrypt(this.accessKey)));
		(widgetInit!=null) ? null : localStorage.setItem("widgetInitData",JSON.stringify(encrypt('[]')));
		(cartData!=null) ? null : localStorage.setItem("cartData",JSON.stringify(encrypt('[]')));
		(currency!=null) ? null : localStorage.setItem("currency",JSON.stringify(encrypt('[]')));

		this.cartCount = JSON.parse(decrypt(JSON.parse(localStorage.getItem("cart")))).length;

		this.initializeWidget();

		this.expCartChecker();
	},
	methods: {
		/**
		 * expCartChecker
		 * 
		 * @description - This is an interval to check on the length of time a product is in the cart.
		 * @comment - If there are cart items then we need to check each for their expiry date.
		 * 
		 */
		expCartChecker(){
			let expChecker = setInterval(()=>{

				this.cartList =JSON.parse(decrypt(JSON.parse(localStorage.getItem("cart"))))

				if(this.cartList && this.cartList.length>0){
					this.CheckExpiredItems();
				}
				else{
					this.cartCount=0
					clearInterval(expChecker);
				}
				
			},5000);
		},

		async loadRudderStack(){

		if(localStorage.getItem("anonymousId")){
			return
		}

		localStorage.setItem("anonymousId",uuidv4().toString())
		
		client.identify({
			anonymousId: localStorage.getItem("anonymousId"),
			traits: {
			accessKey:this.accessKey
			},
		})

		await client.flush();

		},

		generateIssuePayload (issue){
			let issuePayload = {
			url : window.location.href,
			accessKey : this.accessKey,
			businessId : this.businessId,
			businessType : this.businessType,
			operatorId : this.operatorId,
			productId :this.productId,
			issue : issue
			}

			return issuePayload;
		},
		/**
		 * InitializeWidget
		 * @description - The initial API call to Narnoo to set up this widget. This returns the operator's product data we need to continue on with the booking process.
		 * 
		 * @commment - The /initiate/booking_widget/{accessKey}/1650/1933 - Returns a number of details regarding the product we need to book.
		 * 
		 */
		initializeWidget(){
			axios.get(process.env.VUE_APP_API_URL+"/initiate/booking_widget/"+this.accessKey+"/"+this.operatorId+"/"+this.bookingId,this.getHeaders()).then( async (response)=>{
				if(response.data.success){
					//response.data.data.booking - the items in the API which are bookable.
					this.bookingProducts=response.data.data.booking
					//response.data.data.product - provides product information
					this.productName = response.data.data.product.title
					//response.data.data.booking.bookingPlatform - Stores the booking platform
					this.bookingPlatForm = response.data.data.booking.bookingPlatform
					//Is available
					this.initAvailability=true;
					//Loader can be set to false since the data has loaded.
					this.initAvailabilityLoader=false;
					this.businessId = response.data.data.businessId
					this.businessType = response.data.data.businessType
					//Are there any product times from this product can be NULL.
					this.storedProductTimes = response.data.data.booking.productTimes;

					//Sets up the UI
					if(this.retailUi === 'true' || this.retailUi === true){
						this.parseBookingData(this.bookingProducts)
					}

					/**
					 * Product Times can be null and if that is the case we need to address this.
					 */
					if(response.data.data.booking.productTimes!=null && response.data.data.booking.productTimes.length==1){
						if(response.data.data.booking.productTimes[0].time=="00:00:00") {
							this.timeSlot=null;
						}
						else{
							this.timeSlot = response.data.data.booking.productTimes;
						}
					}
					else if(response.data.data.booking.productTimes!=null){
						this.timeSlot = response.data.data.booking.productTimes;
					}
					else{
						this.timeSlot=null;
					}

					//this.businessId = response.data.data.businessId;
					//this.businessType = response.data.data.businessType;
					//Operator ID from API
					this.operatorID = response.data.data.operatorId;
					//Product ID which is the bookingID from the API
					this.productId = response.data.data.bookingId;
					/**
					 * Product Description from English as default
					 * @todo Add localiztion to our other languages
					 */
					this.productDescription = response.data.data.product.description!=null ? response.data.data.product.description.summary[0].english.text : null;
					// Fetch details of products in array
					this.bookingCodes = response.data.data.booking.bookingCodes
					//Assign the featured image or set a default
					this.featuredImage = response.data.data.product.featureImage.image400 ? response.data.data.product.featureImage.image400 : "https://via.placeholder.com/300x300.png?text=No+Image";
					//Build a new widget data object
					let newWidgetDataObj = {
						color: '#fff',
						businessId : this.businessId,
						businessType : this.businessType,
						operatorId : this.operatorID,
						bookingId : this.bookingId,
						bookingCode: this.bookingCode,
						customTheme: this.customTheme,
						checkoutUrl: this.checkoutUrl,
						featuredImage: this.featuredImage
					}

					let encryptedWidgetInitData = encrypt(JSON.stringify(newWidgetDataObj))
					localStorage.setItem("widgetInitData",JSON.stringify(encryptedWidgetInitData))

					//Set the currency object in localstorage
					let supplierCurrency = {
						operatorId:response.data.data.operatorId,
						currency:response.data.data.booking.bookingCodes[0].optionPrice[0].currency ?? null
					}
					localStorage.setItem("currency",JSON.stringify(supplierCurrency))

					/**
					 * Check business's primary payment platform.
					 * @description - used to check which platform the business uses to charge customers at checkout. We set and store this so that the Cart widget can access this at point of sale.
					 * 
					 * @comment This is the agent business and not the operator or business which product we are accessing
					 */
					axios.get(process.env.VUE_APP_API_URL+"/booking/payment_primary/"+this.businessId+"/"+this.businessType,this.getHeaders()).then((response)=>{
						//Store this data in localstorage.
						if(response.data.success){
							let primaryPaymentObj = response.data.data
							let encryptedPrimaryPaymentObj = encrypt(JSON.stringify(primaryPaymentObj));
							localStorage.setItem("primaryPayment",JSON.stringify(encryptedPrimaryPaymentObj));
						}
					})
					.catch( async ()=>{
						//Show errors
						this.errorModal.show=true
						this.errorModal.errors=["Unable to get primary payment connected to this product."]
						//Track this error in Rudderstack
						client.track({
							anonymousId: localStorage.getItem("anonymousId"),
							event: "error",
							properties: {
								issue:this.generateIssuePayload("unable to get primary payment")
							},
						})

						await client.flush()
					})
					/**
					 * allProducts 
					 * @description - Some suppliers will have a list of products under the single bookingId.
					 * @comment - response.data.data.booking.bookingCodes always comes back as an array but most of the time it's just 1 item.
					 */
					let allProducts = response.data.data.booking.bookingCodes;
					let selectedProductOptions = []
				
					allProducts.forEach((product,productIndex) => {
						//Need to debug this to understand it
						if (this.bookingCode!=undefined && this.bookingCode!="") {
							if(product.id.indexOf(this.bookingCode)>-1) {
								this.allProducts.push(product);
								//Add the pricing options for this product into the guestOptions array.
								this.guestOptions = product.optionPrice;
							}
						}
						else{
							this.allProducts.push(product);
							let currentOptionPrice = response.data.data.booking.bookingCodes[productIndex].optionPrice

							if(typeof currentOptionPrice!=undefined && currentOptionPrice != false && currentOptionPrice.length>selectedProductOptions.length) {

								selectedProductOptions=currentOptionPrice

							}

							this.guestOptions = selectedProductOptions
						}
						
					});
					
					/**
					 * Manages the guest/ticket options.
					 * 	
					 * If no options then:
					 *  - We can't book this product live
					 *  - Send an issues statement to Narnoo for further investigation
					 *  - Update rudderstack
					 * 
					 */
					if(this.guestOptions.length==0) {
						this.noLiveBooking = true;

						let issuePayload = this.generateIssuePayload("no guest option")
						
						axios.post(process.env.VUE_APP_API_BASE_URL+"/issues/product",issuePayload).then( async ()=>{});
						
						client.track({
							anonymousId: localStorage.getItem("anonymousId"),
							event: "error",
							properties: {
								issue:this.generateIssuePayload("no guest option")
							},
						})

						await client.flush()

						return;
					}
					/**
					 * Build out availability data
					 */
					if(this.allProducts.length==1 && (this.timeSlot==null || this.timeSlot.length==1)) {
						var availabilityBasicBookingCode = null;
						if (this.bookingCode!=undefined && this.bookingCode!="") {
							if(this.timeSlot==null && this.storedProductTimes!=null) {
								availabilityBasicBookingCode = this.bookingCode + ":" +this.storedProductTimes[0].id;
							}
							else{
								if (this.$refs.timeSlot && this.$refs.timeSlot.value!=='') {
									availabilityBasicBookingCode = this.bookingCode + ":" +this.$refs.timeSlot.value;
								} 
								else{
									availabilityBasicBookingCode = this.bookingCode
								}
							}
						}
						else{
							availabilityBasicBookingCode = (this.timeSlot==null) ? this.allProducts[0].id : this.allProducts[0].id+":"+this.timeSlot[0].id;
						}
						
						/**
						 * Deal with UI options.
						 * This is the retail UI
						 * @comment - I think the reason there are two checks for a boolean value is in case the user embbeds a string in the prop?
						 */
						if(this.retailUi !== 'true' && this.retailUi !== true) {
							axios.get(process.env.VUE_APP_API_URL+"/booking/availability_basic/"+this.businessId+"/"+this.businessType+"/"+this.operatorID+"/"+this.bookingId+"?bookingCode="+availabilityBasicBookingCode,this.getHeaders()).then((response)=>{
							
								if(response.data.success && response.data.data.productAvailability) {

									if(response.data.data.productAvailability.error){
										return;
									}

									response.data.data.productAvailability.forEach(d => {
										if(d.availability==false){
											this.notAvailableDates.push(d.date);
										}
									});
								}
							});
						}
						
						
					}
				}
			})
			.catch( async ()=>{
				this.initAvailability=false;
				this.initError=true;
				this.initAvailabilityLoader=false;

				let issuePayload = this.generateIssuePayload("initialization error")
			
				axios.post(process.env.VUE_APP_API_BASE_URL+"/issues/product",issuePayload).then( async ()=>{});
				
				client.track({
					anonymousId: localStorage.getItem("anonymousId"),
					event: "error",
					properties: {
						issue:this.generateIssuePayload("initialization error")
					},
				})

				await client.flush()
			})
			
		},

		/**
		 * parseBookingData
		 * 
		 * @description - Looks through all the returned booking data from the supplier in the API call
		 * @comment - Might be used in the mini calendar
		 * 
		 * @param {Array} bookingData
		 *  
		 */
		parseBookingData(bookingData){
			this.availabilityData=[]
			this.isSearchLoading=true

			// sort out all products
			// check if end user provided a specific bookingCode in widget initialization otherwise we load all available product options
			let bookingItems=[]
			
			bookingData.bookingCodes.forEach(product => {
				if (this.bookingCode!=undefined && this.bookingCode!="") {
					if(product.id.indexOf(this.bookingCode)>-1) {
						bookingItems.push(product);
					}
				}
				else{
					bookingItems.push(product);
				}
			});

			let formatDate = (dateVal) => {
				return dateVal.toISOString().split("T")[0].split("-").reverse().join("-")
			}

			/**
			 * loop through each product options and check for its availability
			 * 
			 */ 
			let productPromises = bookingItems.map( async (item)=>{
			
				let searchParams = null
				let startDate = null
				let endDate = null
				/**
				 * If using the rangeCalendar we automatically look 5 days ahead
				 */
				if(!this.useRangeCalendar) { 
					startDate = new Date();
					endDate = new Date().addDays(5);
				}
				else{
					startDate = this.search.startDate
					endDate = this.search.endDate
				}

				/**
				 * Loop through the various product booking codes for availability information
				 */

				// if productTimes is null using the bookingCode
				if(bookingData.productTimes==null) {
					let user = window.btoa(process.env.VUE_APP_USER_ID + ":" + process.env.VUE_APP_ACCESS_TOKEN);
					searchParams = {
						headers: { 'Authorization': "Basic " + user },
						params: {
							bookingCode: item.id,
							startDate : this.useRangeCalendar ? startDate : formatDate(startDate),
							endDate : this.useRangeCalendar ? endDate : formatDate(endDate)
						}
					}
					try{
						let availability = await this.initCheckAvailability(searchParams)
						this.availabilityList.push(  availability.data.data.productAvailability )
					}
					catch(err){
						console.log(err)
					}
					
				} else{
					/**
					 * @description - if productTimes is not null, loop through each time and check for availability.
					 * @comment - A slow method as we have to do multiple API calls. We only have one reservation system that uses this option.
					 */
					for(let x =0; x < bookingData.productTimes.length;x++) {
					let time = bookingData.productTimes[x]

					let user = window.btoa(process.env.VUE_APP_USER_ID + ":" + process.env.VUE_APP_ACCESS_TOKEN);
					searchParams = {
						headers: { 'Authorization': "Basic " + user },
						params: {
							bookingCode: item.id+":"+time.id,
							startDate : this.useRangeCalendar ? startDate : formatDate(startDate),
							endDate : this.useRangeCalendar ? endDate : formatDate(endDate)
						}
					}
					try{
						
						let availability = await this.initCheckAvailability(searchParams)
						this.availabilityList.push(  availability.data.data.productAvailability )
					}
					catch(err){
						console.log(err)
					}
					

					}
					
				}
				/**
				 * Deal with product pricing from availablity list. Availability list gives the most accurate results for pricing.
				 */
				let prices = 0
				let removeZeroPrices = null
				let lowestPrice = 0
				if(this.availabilityList[this.availabilityList.length - 1][0]?.price){
					prices = this.availabilityList[this.availabilityList.length - 1][0].price.map(function(element) {return element.price})
					removeZeroPrices = prices.filter(val => val != "0" && val != "0.00")
					lowestPrice = Math.min(...removeZeroPrices)
				}
				
				/**
				 * Add the availability items to the availabilityData array.
				 */
				this.availabilityData.push({
					id: item.id,
					title: item.label,
					description: this.productDescription,
					guestPricing : this.availabilityList[this.availabilityList.length - 1][0]?.price,
					productTimes : bookingData.productTimes,
					lowestPrice: lowestPrice,
					productAvailability: {
						startDate:this.useRangeCalendar ? startDate : formatDate(startDate),
						endDate:this.useRangeCalendar ? endDate : formatDate(endDate),
						dateList:this.availabilityList,
						productTimes : bookingData.productTimes,
						productId : this.bookingId,
						supplierId : this.operatorId,
						bookingCode : item.id
					},
					bookingCodes: this.bookingCodes
				})
			})
			/**
			 * Wait till all products have been looped.
			 */
			Promise.all(productPromises).then(()=>{
				this.isSearchLoading=false
				if(this.availabilityData.length>0 && !this.availabilityList[this.availabilityList.length - 1][0]?.error) {
					this.availabilityNotFound=false
					this.showAvailabilityResults=true
				}
				else{
					this.availabilityNotFound=true
				}
			})
		},
		/**
		 * initCheckAvailability
		 * @description - Performs the API request to the Narnoo API endpoint to return availability data.
		 * 
		 * @param {Object} searchParams 
		 */
		initCheckAvailability(searchParams){

			return axios.get(process.env.VUE_APP_API_URL+"/booking/availability_widget/"+this.businessId+"/"+this.businessType+"/"+this.operatorId+"/"+this.bookingId,searchParams)

		},
		/**
		 * checkRangeAvailability
		 * 
		 * @description - Checks the availabilty over a start and end date range.
		 * 
		 * @param {object} this.search
		 */
		checkRangeAvailability(){
			if(this.search.startDate==null || this.search.endDate==null) return

			this.steps[0].status="current"
			this.currentStep=1
			this.isSearchLoading=true
			this.useRangeCalendar = true 
			if (this.bookingProducts == null || this.bookingProducts == '') {
				axios.get(process.env.VUE_APP_API_URL+"/initiate/booking_widget/"+this.accessKey+"/"+this.operatorId+"/"+this.bookingId,this.getHeaders()).then((response)=>{
			
					if(response.data.success){
						this.bookingProducts=response.data.data.booking
						this.parseBookingData(this.bookingProducts)
					}

				})
				.catch(err=>{
					console.log(err)
				})
			} else {
				this.parseBookingData(this.bookingProducts)
			}
			
		},
		
		getHeaders(){
			var user = window.btoa(process.env.VUE_APP_USER_ID + ":" + process.env.VUE_APP_ACCESS_TOKEN);
			var config = {
				headers: { 'Authorization': "Basic " + user }
			};

			return config;
		},

		/**
		 * guestDropdownToggle
		 * @description - Builds the toggle button for guest selector
		 * 
		 */
		guestDropdownToggle() {
			this.show= !this.show;
			if(this.show){
				setTimeout(()=>{ 
					this.$refs.guestDropdown.style.width = this.$refs.guests.clientWidth + "px";
				
				// check if parent element is small enough to force all its child element to wrap by setting its width to 100%
				if(this.$refs.guests.clientWidth <= 363) {
					let guestRows = document.getElementsByClassName("guestLabels")
					guestRows.forEach(guestEl=>{
						guestEl.children.forEach(group=>{
							group.classList.add("narnoo-w-full")
						})
					})
				}

				this.guestOptionsData.map(val=>{
					let index = parseInt(val.index);
					document.getElementById(`guest-no-${this.bookingId}-${index}`).value = val.value;
				})
				},.01)
			}
		},
		/**
		 * nextDays
		 * 
		 * @description - If the retail UI is being used then we need to manage the scrolling next days availability search
		 * 
		 * @param {*} index 
		 * @param {*} end 
		 */
		async nextDays(index = null, end = null){

			if(this.retailUi === 'true' || this.retailUi === true) {
				let startDate = new Date(end).addDays(1)
				let endDate = new Date(end).addDays(6)
				this.loadProduct(this.bookingProducts,startDate,endDate,index)
			} else {
				let formatDate = (dateVal) => {
					return dateVal.toISOString().split("T")[0].split("-").reverse().join("-")
				}

				let currentDate = new Date()

				this.startDate = this.endDate;
				this.endDate = new Date(this.endDate.split("-").reverse().join("-"))

				if(this.endDate > currentDate){
				this.showPrevButton=true
				}
				this.endDate= formatDate(this.endDate.addDays(7))

				this.reloadDayUi()
			}
		},
		async prevDays(index = null, start = null){
			if(this.retailUi === 'true' || this.retailUi === true) {
				let startDate = new Date(start).subtractDays(6)
				let endDate =  new Date(start).subtractDays(1)

				this.loadProduct(this.bookingProducts,startDate,endDate,index)
			} else {
				let currentDate = new Date().addDays(1)

				let formatDate = (dateVal) => {
				return dateVal.toISOString().split("T")[0].split("-").reverse().join("-")
				}

				this.endDate = new Date(this.endDate.split("-").reverse().join("-"))

				this.endDate =  formatDate(this.endDate.subtractDays(7))
				this.startDate = new Date(this.endDate.split("-").reverse().join("-"))
				this.startDate = this.startDate.subtractDays(7)
				if(currentDate >= this.startDate){
				this.showPrevButton=false
				}
				this.startDate = formatDate(this.startDate)

				this.reloadDayUi()
			}

		},
		/**
		 * loadProduct
		 * 
		 * @description - Loads the availablity of the products within this search
		 * 
		 * @param {*} bookingData 
		 * @param {*} startDate 
		 * @param {*} endDate 
		 * @param {*} productIndex 
		 */
		async loadProduct(bookingData,startDate,endDate,productIndex) {

			this.reloadCalendar=true
			this.calendarIndex = productIndex
			
			let item = bookingData.bookingCodes[productIndex]

			let formatDate = (dateVal) => {
				return dateVal.toISOString().split("T")[0].split("-").reverse().join("-")
			}

			let searchParams = null

			// if productTimes is null using the bookingCode
			if(bookingData.productTimes==null) {
				let user = window.btoa(process.env.VUE_APP_USER_ID + ":" + process.env.VUE_APP_ACCESS_TOKEN);
				searchParams = {
					headers: { 'Authorization': "Basic " + user },
					params: {
						bookingCode: item.id,
						startDate : formatDate(startDate),
						endDate : formatDate(endDate)
					}
				}
				
				let availability = await this.initCheckAvailability(searchParams)
				this.availabilityList.push(  availability.data.data.productAvailability )
			}
			else{
				// if productTimes is not null, loop through each time and check for availability
				for(let x =0; x < bookingData.productTimes.length;x++) {
					let time = bookingData.productTimes[x]

					let user = window.btoa(process.env.VUE_APP_USER_ID + ":" + process.env.VUE_APP_ACCESS_TOKEN);
					searchParams = {
						headers: { 'Authorization': "Basic " + user },
						params: {
							bookingCode: item.id+":"+time.id,
							startDate : formatDate(startDate),
							endDate : formatDate(endDate)
						}
					}
					
					try{
						let availability = await this.initCheckAvailability(searchParams)
						this.availabilityList.push(  availability.data.data.productAvailability )
					}
					catch(err){
						console.log(err)
					}

				}
			
			}

			let prices = 0
			let removeZeroPrices = null
			let lowestPrice = 0
			
			if(this.availabilityList[this.availabilityList.length - 1][0]?.price){
				prices = this.availabilityList[this.availabilityList.length - 1][0].price.map(function(element) { return element.price})
				removeZeroPrices = prices.filter(val => val != "0" && val != "0.00")
				lowestPrice = Math.min(...removeZeroPrices)
			}
			if (lowestPrice > 0) {
				this.availabilityData[productIndex].lowestPrice = lowestPrice;
				this.availabilityData[productIndex].guestPricing = this.availabilityList[this.availabilityList.length - 1][0]?.price
				this.availabilityData[productIndex].productTimes = bookingData.productTimes
			}
			
			this.availabilityData[productIndex].productAvailability = {
				startDate:formatDate(startDate),
				endDate:formatDate(endDate),
				dateList:this.availabilityList,
				productTimes : bookingData.productTimes,
				productId : this.bookingId,
				supplierId : this.operatorId,
				bookingCode : item.id
			}

			this.reloadCalendar=false

		},
		/**
		 * Reloads the DayUi for the next available days
		 */
		async reloadDayUi(){
			let formatDate = (dateVal) => {
				return dateVal.toISOString().split("T")[0].split("-").reverse().join("-")
			}
			this.showDaysUiLoader=true
			let nextDaysAllProductOptionsAvailabilityResponse = []

			let checkAllProductsAvailability = this.allProducts.map(product=>{

			var user = window.btoa(process.env.VUE_APP_USER_ID + ":" + process.env.VUE_APP_ACCESS_TOKEN);

			let generatedBookingCode = () => {
				if(this.timeSlot==null && this.storedProductTimes!=null) {
				return product.id + ":" +this.storedProductTimes[0].id;
				}
				else{
				if (this.$refs.timeSlot && this.$refs.timeSlot.value!=='') {
					return product.id + ":" +this.$refs.timeSlot.value;
				} 
				else{
					return product.id
				}
				}
			}

			var config = {
				headers: { 'Authorization': "Basic " + user },
				params: {
				bookingCode:generatedBookingCode() ,
				startDate : this.startDate,
				endDate : this.endDate
				}
			};

			return axios.get(process.env.VUE_APP_API_URL+"/booking/availability_widget/"+this.businessId+"/"+this.businessType+"/"+this.operatorID+"/"+this.productId,config).then((response)=>{
				
				if(response.data.success){

					if(response.data.data.productAvailability==null){ 
						
						return;
					}
					if(response.data.data.productAvailability.error){
						return;
					}
					
					nextDaysAllProductOptionsAvailabilityResponse = nextDaysAllProductOptionsAvailabilityResponse.concat(response.data.data.productAvailability)
					
					}
				})
				.catch( async ()=>{
					
					client.track({
						anonymousId: localStorage.getItem("anonymousId"),
						event: "error",
						properties: {
						issue:this.generateIssuePayload("error found in check availability api call"),
						availabilityDetails: {
							bookingCode:generatedBookingCode(),
							startDate : this.startDate,
							endDate : this.endDate
						}
						},
					})

					await client.flush()
					
					return;
				})

			});

			Promise.all(checkAllProductsAvailability).then(()=>{
				this.isSearchLoading=false;
				let dayArrayStart = new Date(this.startDate.split("-").reverse().join("-")).addDays(1)
				let dayArrayEnd = new Date(this.endDate.split("-").reverse().join("-"))

				let dayArr=[]
				
				for(let dt=dayArrayStart; dt<=dayArrayEnd; dt.setDate(dt.getDate()+1)){
					dayArr.push(new Date(dt))
				}

				dayArr.map(d=>{
					let selectedDate = d.toISOString().split("T")[0]
					
					let currentDate = d.toISOString().split("T")[0].split("-").reverse().join("-")

					let filteredDateByTime = nextDaysAllProductOptionsAvailabilityResponse.filter(data=>data.bookingDateDisplay.split(" ")[0]===currentDate && data.availability!==null && data.availability>0)

					if (filteredDateByTime.length>0) {
						filteredDateByTime.map(o => o.availability).reduce((a,c) => {return a+c});
						d.availability = filteredDateByTime[0].availability
					}
					else{
						d.availability = 0
					}

					d.weekday=new Date(selectedDate).toLocaleString('default', { weekday: 'long' })
					d.monthName=new Date(selectedDate).toLocaleString('default', { month: 'long' })
					d.longDate = new Date(selectedDate)
					d.date = selectedDate.split("-")[2]
					d.pickDate = formatDate(new Date(selectedDate))
				})

				this.dateRange = dayArr
				this.showDaysUi = true
				this.showDaysUiLoader=false
			})
		},
		/**
		 * checkAvailability
		 * 
		 * @description - A main method which checks the availability of the users selection
		 * 
		 * @comment - Manages the UI
		 * @comment - Does a lot of the availability checks
		 * 
		 * @param {*} pickDate 
		 */
		async checkAvailability(pickDate=null){
			let date = this.rangeDate ? this.availability.range != null ? this.availability.range[0] : this.availability.range : this.availability.schedule
			
			// validation if the input field is empty
			if(document.getElementById(`guests-${this.bookingId}`).value==='' && date==null){
				let date = document.getElementById('datePicker')
				this.$refs.guests.classList.add('narnoo-border-red-500')
				this.$refs.guests.classList.add('narnoo-border-2')
				date.classList.add('narnoo-border-red-500')
				date.classList.add('narnoo-border-2')
				date.children[0].classList.remove('narnoo-mt-1')
				date.classList.add('narnoo-mt-1')
				date.classList.add('narnoo-rounded')
				return;
			} else if(document.getElementById(`guests-${this.bookingId}`).value===''){
				let date = document.getElementById('datePicker')
				this.$refs.guests.classList.add('narnoo-border-red-500')
				this.$refs.guests.classList.add('narnoo-border-2')
				date.classList.remove('narnoo-border-red-500')
				date.classList.remove('narnoo-border-2')
				date.children[0].classList.add('narnoo-mt-1')
				date.classList.remove('narnoo-mt-1')
				date.classList.remove('narnoo-rounded')
				return
			}

			this.isGuestOptionAvailableInAvailability=true
			this.availabilityResults=[];
			this.availabilityNotFound=false;
			this.showAvailabilityResults=false;
			this.productAvailabilityCouter=0;

			//initiate date
			if(!(this.nextStatus || this.prevStatus)){
				if(pickDate!==null){
					this.startDate = pickDate
					this.availability.schedule = pickDate
				}
				else{
					this.startDate = (this.rangeDate === true ? this.availability.range[0] : this.availability.schedule)
					if(this.startDate === null && document.getElementById(`guests-${this.bookingId}`).value!=''){
						let date = document.getElementById('datePicker')
						date.classList.add('narnoo-border-red-500')
						date.classList.add('narnoo-border-2')
						date.children[0].classList.remove('narnoo-mt-1')
						date.classList.add('narnoo-mt-1')
						date.classList.add('narnoo-rounded')
						this.$refs.guests.classList.remove('narnoo-border-red-500')
						this.$refs.guests.classList.remove('narnoo-border-2')
						return;
					}
					else if(this.startDate != null && document.getElementById(`guests-${this.bookingId}`).value!=''){
						let date = document.getElementById('datePicker')
						date.classList.remove('narnoo-border-red-500')
						date.classList.remove('narnoo-border-2')
						date.children[0].classList.add('narnoo-mt-1')
						date.classList.remove('narnoo-mt-1')
						date.classList.remove('narnoo-rounded')
						this.$refs.guests.classList.remove('narnoo-border-red-500')
						this.$refs.guests.classList.remove('narnoo-border-2')
					}
				}

				//If the StartDate is not empty. use it as endDate
				this.endDate = (this.rangeDate === true ? this.availability.range[1] : this.availability.schedule);

				//date format proccess to DD-MM-YY to 
				let formatDate = (dateVal) => {
					return dateVal.toISOString().split("T")[0].split("-").reverse().join("-")
				}

				//Start adding 6 days to the endDate
				this.endDate = new Date(this.endDate.split("-").reverse().join("-"))
				this.endDate= (this.rangeDate === true ? formatDate(this.endDate) : formatDate(this.endDate.addDays(6)))
				
			}

			this.isSearchLoading=true;

			//validation if the need parameters is null or empty then return if its null
			if(this.guestOptionsData.length==0 ) {
				this.$refs.guests.classList.add('narnoo-border-red-500')
				this.$refs.guests.classList.add('narnoo-border-2')
				return;
			}
			else if(this.startDate==null || this.endDate==null){
				let date = document.getElementById('datePicker')
				date.classList.add('narnoo-border-red-500')
				date.classList.add('narnoo-border-2')
				date.children[0].classList.remove('narnoo-mt-1')
				date.classList.add('narnoo-mt-1')
				date.classList.add('narnoo-rounded')
				return
			}

			// most of this data is initialize upon loading and the other is coming from the field
			// sending data to the env
			client.track({
				anonymousId: localStorage.getItem("anonymousId"),
				event: "Check availability",
				properties: {
					guestOptions:  document.getElementById(`guests-${this.bookingId}`).value,
					time: this.$refs.timeSlot!==undefined ? this.$refs.timeSlot.value : null,
					date: pickDate!=null ? pickDate : this.availability.schedule,
					productTitle : this.productName,
					supplierId:this.operatorId
				},
			})
			//sending track data to server
			await client.flush()

			//allproducts is initialize upon loading
			let checkAllProductsAvailability = this.allProducts.map(product=>{
				var user = window.btoa(process.env.VUE_APP_USER_ID + ":" + process.env.VUE_APP_ACCESS_TOKEN);
				/**
					* 
					* Booking code points - deal with multiple
					* 
					*/
				//function to get the bookingCode
				let generatedBookingCode = () => {
					if(this.timeSlot==null && this.storedProductTimes!=null) {
						return product.id + ":" +this.storedProductTimes[0].id;
					}
					else{
						if (this.$refs.timeSlot && this.$refs.timeSlot.value!=='') {
							return product.id + ":" +this.$refs.timeSlot.value;
						} 
						else{
							return product.id
						}
					}
				}
				//function to generate timeID
				let generateTimeId = () => {
					if(this.timeSlot==null && this.storedProductTimes!=null) {
						return this.storedProductTimes;
					}
					else{
					if (this.timeSlot!=null) {
						return this.timeSlot.filter(e=>e.id==this.$refs.timeSlot.value);
					} 
					else{
						return null
					}
					}
				}

				//initializing config and ApiUrlParams for API request
				var config = {
					headers: { 'Authorization': "Basic " + user },
					params: {
						bookingCode:generatedBookingCode() ,
						startDate : this.startDate,
						endDate : this.endDate
					}

				};

				var apiUrlParams = {
					businessId: this.businessId,
					businessType : this.businessType,
					operatorID : this.operatorID,
					productId: this.productId,
					featuredImage : this.featuredImage
				}


				/**
				* 
				* We need to be able to deal with products that have multiple results for different timeslots on the same day
				* 
				*/
				return axios.get(process.env.VUE_APP_API_URL+"/booking/availability_widget/"+this.businessId+"/"+this.businessType+"/"+this.operatorID+"/"+this.productId,config).then((response)=>{

					if(response.data.success){
						let computedPrices = [];

						if(response.data.data.productAvailability==null){ 
							return;
						}
						else if(response.data.data.productAvailability.error){
							return;
						}
						else{
							//get the timeSlot on eachDate
							let startDateAvailable = response.data.data.productAvailability.filter(data=>data.bookingDateDisplay.split(" ")[0]===this.startDate && data.availability>0 && data.price!==null)
							if(startDateAvailable.length>0){

								this.productAvailabilityCouter++;
								this.guestOptionsData.map((guestOption)=>{
									//get the price for the selected guest
									let availabilityPricing = response.data.data.productAvailability[0].price
									let foundPricing = availabilityPricing.filter(data=>data.id==guestOption.id)

									if(foundPricing.length==0){
										this.isGuestOptionAvailableInAvailability=false
									}
									else{
										let availabilityPricing = foundPricing[0]
										let computedPrice = {};
										availabilityPricing.currency = this.bookingProducts.bookingCodes[0].optionPrice[0].currency

										//initializing data
										computedPrice.price = availabilityPricing.price;
										computedPrice.label = availabilityPricing.label;
										computedPrice.currency = availabilityPricing.currency;
										computedPrice.group = availabilityPricing.group;
										computedPrice.id = availabilityPricing.id;
										computedPrice.collectLevy = availabilityPricing.collectLevy;
										computedPrice.levy = availabilityPricing.levy;
										computedPrice.commission = availabilityPricing.commission;
										computedPrice.pax = availabilityPricing.pax;
										computedPrice.minQuantity = availabilityPricing.minQuantity;
										computedPrice.maxQuantity = availabilityPricing.maxQuantity;

										if(guestOption.id==computedPrice.id){
										computedPrice.qty = guestOption.value;
										}
										else{
										computedPrice.qty=0;
										}
										computedPrice.total = (computedPrice.qty * computedPrice.price) 
										computedPrices.push(computedPrice);
									}
								})

								if(computedPrices.length < this.guestOptionsData.length){
									computedPrices=null
								}
								
								// totalcount of selected guest base on the quantity
								
								let totalGuestSelected = this.guestOptionsData.map(o => o.value).reduce((a, c) => { return a + c });

								let availabilityResults = {
									totalGuests : totalGuestSelected,
									description : this.productDescription,
									availabilityDetails :startDateAvailable,
									lowestPrice : computedPrices ? computedPrices.map(o => o.total).reduce((a, c) => { return a + c }) : null,
									prices : computedPrices,
									productTitle : product.label,
									productName: this.productName,
									bookingPlatForm: this.bookingPlatForm,
									timeId: generateTimeId(),
									apiData : {
										headers: {
											headers: { 'Authorization': "Basic " + user },
											params: {
												bookingCode: generatedBookingCode(),
												bookingDate : this.startDate,
											}
										},
										apiUrlParams : apiUrlParams
									}
								}
								this.availabilityResults.push(availabilityResults);
								this.availabilityResults.sort((a,b)=> (a.lowestPrice > b.lowestPrice ? 1 : -1));
							}
						
						}

						//this is the listeddate base on the range inputed in fields
						this.allProductOptionsAvailabilityResponse = this.allProductOptionsAvailabilityResponse.concat(response.data.data.productAvailability)
						const cutoffTime = this.bookingCutoff;
						const now = new Date();

						//check if the date is in cutoff
						for (let i = 0; i < this.allProductOptionsAvailabilityResponse.length; i++) {
							const bookingDate = this.allProductOptionsAvailabilityResponse[i].bookingDateDisplay;
							const isAllowed = this.isAllowedByCutOff(bookingDate);
							
							if (isAllowed) {
							const bookingDateObj = new Date(bookingDate);
							const timeDiff = (bookingDateObj.getTime() - now.getTime()) / (1000 * 60 * 60);
							if (timeDiff <= cutoffTime) {
								// do something if booking date is within cutoff time
								this.allProductOptionsAvailabilityResponse[i].additionalAvailable = false;
							} else {
								// do something if booking date is outside cutoff time
								this.allProductOptionsAvailabilityResponse[i].additionalAvailable = true;
							}
							} else {
							// do something if booking date is not allowed
							this.allProductOptionsAvailabilityResponse[i].additionalAvailable = false;
							}
						}

						let rangeData = {
							tourOption: this.productName,
							inputDates: {
								bookingCode: generatedBookingCode(),
								dateList: this.allProductOptionsAvailabilityResponse,
								startDate: this.startDate,
								endDate: this.endDate,
								productId: product.id,
								supplierId: this.operatorID,
								productTimes: this.storedProductTimes,
							}

						}
						this.calendarData.unshift(rangeData)
					}
				})
				.catch( async ()=>{
					this.isSearchLoading=false;
					
					client.track({
						anonymousId: localStorage.getItem("anonymousId"),
						event: "error",
						properties: {
							issue:this.generateIssuePayload("error found in check availability api call"),
							availabilityDetails: {
								bookingCode:generatedBookingCode(),
								startDate : this.startDate,
								endDate : this.endDate
							}
						},
					})
					await client.flush()
					return;
				})
			});

			Promise.all(checkAllProductsAvailability).then(()=>{
			this.isSearchLoading=false;
				if(this.productAvailabilityCouter>=1) {
					this.availabilityResults =  this.availabilityResults.filter(data =>data.prices!= null)
					if(this.availabilityResults.length==0) {
						if(this.isGuestOptionAvailableInAvailability==false) {
							this.availabilityNotFound=true;
							this.showAvailabilityResults=false;
							return;
						}
					}
					this.availabilityNotFound=false;
					this.showAvailabilityResults=true;
					// this.nextStatus = false
					// this.prevStatus = false
				}
				else{
					this.generateMiniCalender()
				}
			})
		},
		/**
		 * isAllowedByCutOff
		 * @description - Checks if the item is allowed based on user prop settings.
		 * @param {Date} bookingDate 
		 */
		isAllowedByCutOff(bookingDate){
			bookingDate = bookingDate.split(" ")

			if(bookingDate.length>1){
				bookingDate = `${bookingDate[0].split("-").reverse().join("-")} ${bookingDate[1]}`
			}

			if (this.bookingCutoff !== null && this.bookingCutoff !== undefined && this.bookingCutoff !== '' ) {

				let userDate = new Date()
				let attemptedBookingDate = new Date(bookingDate)
				let dateDiff = (attemptedBookingDate.valueOf() - userDate.valueOf() ) / 36e5;
				
				// make sure datedifference is not a negative one. negative date difference means user tries to book but his current time already over the booking date time.
				if(dateDiff < 0 ) {
					return false
				}
				else if (parseFloat(this.bookingCutoff)>= dateDiff) {
					// if bookingcutoff hours is greater then that means we will not allow the booking since date difference is within the bookingcutoff
					return false
				}
				else{
					// we return true if date diff is outside bookingcutoff 
					return true
				}
			}
			else{
				// if user did not configure booking cutoff we automatically set the booking as allowed
				return true
			}
		},

		navigateSteps(index,bookingDetails) {
			this.steps[index-1].status="current"
			this.currentStep=index
			if(bookingDetails) {
				this.bookingDetails = bookingDetails
			}
		},
		/**
		 * generateMiniCalender
		 * 
		 * @description - Used to generate the mini calendar in the UI.
		 */
		generateMiniCalender () {
		let formatDate = (dateVal) => {
			return dateVal.toISOString().split("T")[0].split("-").reverse().join("-")
		}
		this.availabilityNotFound=true;
		this.showAvailabilityResults=false;

		//since we dont get any availablity we show the mini day ui
		// genearate the array of dates that acts as columns of the calendar
		let dayArrayStart = new Date(this.startDate.split("-").reverse().join("-")).addDays(1)
		let dayArrayEnd = new Date(this.endDate.split("-").reverse().join("-"))

		let dayArr=[]

		for(let dt=dayArrayStart; dt<=dayArrayEnd; dt.setDate(dt.getDate()+1)){
			dayArr.push(new Date(dt))
		}

		dayArr.map(d=>{
			let selectedDate = d.toISOString().split("T")[0]
			let currentDate = d.toISOString().split("T")[0].split("-").reverse().join("-")
			let filteredDateByTime = this.allProductOptionsAvailabilityResponse.filter(data=>data.bookingDateDisplay.split(" ")[0]===currentDate && data.availability!==null && data.availability>0)

			if (filteredDateByTime.length>0) {
			filteredDateByTime.map(o => o.availability).reduce((a,c) => {return a+c});
			d.availability = filteredDateByTime[0].availability
			}
			else{
			d.availability = 0
			}

			d.weekday=new Date(selectedDate).toLocaleString('default', { weekday: 'long' })
			d.monthName=new Date(selectedDate).toLocaleString('default', { month: 'long' })
			d.longDate = new Date(selectedDate)
			d.date = selectedDate.split("-")[2]
			d.pickDate = formatDate(new Date(selectedDate))
		})

		this.dateRange = dayArr
		this.showDaysUi = true
		},

		addGuest(index,options,action) {
			let value = parseInt(document.getElementById(`guest-no-${this.bookingId}-${index}`).value);
			let maxQuantity = options.maxQuantity ? parseInt(options.maxQuantity) : null
			let minQuantity = parseInt(options.minQuantity) || 0
			if(action=='add'){

				value++

				if(value < minQuantity)
				{
				value = minQuantity;
				}

				if(maxQuantity!=null) {
					if(value > maxQuantity) {
						value = maxQuantity;
					}
				}
				
				
			}else{
				value--
				if(value < minQuantity){
					value = 0;
				}
			}
			document.getElementById(`guest-no-${this.bookingId}-${index}`).value=value;

			let guestOptions = document.querySelectorAll(`.guest-no-${this.bookingId}`);
			let guestChoices = [];
			this.guestOptionsData = [];

			for (let x =0; x < guestOptions.length;x++) {
				if(guestOptions[x].value!=0) {
				let getlabel = document.getElementById(`guest-option-label-${this.bookingId}-${x}`).dataset.label;
				let guestId = document.getElementById(`guest-option-label-${this.bookingId}-${x}`).dataset.guestid;
				let getValue = guestOptions[x].value;
				guestChoices.push(getlabel+ " x " +getValue)
				
				let optionsData = {
					index: x,
					id: guestId,
					label : getlabel,
					value : parseInt(getValue)
				}
				this.guestOptionsData.push(optionsData);
				
				}
			}
			guestChoices =guestChoices.join(" , ");
				document.getElementById(`guests-${this.bookingId}`).value = guestChoices;

		},
		/**
		 * 
		 * CheckExpiredItems
		 * @description Checks the cart items for any items we need to remove. It then removes this item in the cart and resets it.
		 * @comment cart.cartItemExp must be the flag that stores the expiry item.
		 * 
		 */
		CheckExpiredItems(){
			if(this.cartList.length>0){
				this.cartList.map((cart,index)=>{
					var now = moment(new Date).toDate();
					if(moment(now).isAfter(cart.cartItemExp)){
						
						let cartData = JSON.parse(decrypt(JSON.parse(localStorage.getItem("cart"))));
						setTimeout(()=>{

							cartData.splice(index, 1);

							let encryptedCartData = encrypt(JSON.stringify(cartData));

							localStorage.setItem("cart",JSON.stringify(encryptedCartData))

						},1300);
					} 
				})
			}
		},
		/**
		 * If
		 * @param {Date} date 
		 */
		disabledBeforeToday(date) {
			const today = new Date();
			today.setHours(0, 0, 0, 0);
			if(date < today) {
				return true;
			}
			else{
				var formattedDate = moment(date).format("DD-MM-YYYY");
				if(this.notAvailableDates.includes(formattedDate)){
					return true;
				}
			}
		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.skeleton-loader{
    background: #e2e2e2;
    color: transparent;
    position: relative;
    overflow: hidden;
}

.skeleton-loader::before {
    content: "";
    position: absolute;
    left: 0%;
    top: 0;
    height: 100%;
    width: 50px;
    background: linear-gradient(to right, #e2e2e2 25%, #d5d5d5 50%, #e2e2e2 100%);
    animation-name: gradient-animation;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    filter: blur(5px);
  }
  
@keyframes gradient-animation {
	from {
		left: 0%;
	}
	to {
		left: 100%;
	}
}
.form-control{
display: block;
width: 100%;
height: calc(1.5em + 0.75rem + 2px);
font-size: 1rem;
font-weight: 400;
line-height: 1.5;
color: #495057;
background-color: #fff;
border: 1px solid #ced4da;
border-radius: 0.25rem;
transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control:focus {
color: #495057;
background-color: #fff;
border-color: #80bdff;
outline: 0;
box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.narnoo-rounded-md {
border-radius: 0.25rem;
}

.guest-input::after{
border-style: solid;
border-color: gray;
border-width: 0.15em 0.15em 0 0;
content: '';
height: 0.55em;
left: 0.15em;
position: relative;
float:right;
top:-26px;
margin-right: 18px;
transform: rotate(133deg);
vertical-align: top;
width: 0.55em;
}

.guest-options-slide-enter-active {
transition: all .1s linear;
transform-origin: left top;
transform: scaleY(1);
}
.guest-options-slide-leave-active {
transition: all .1s linear;
}
.guest-options-slide-enter, .guest-options-slide-leave-to
.guest-options-slide-leave-active  {
transition: all .1s linear;
transform-origin: left top;
transform: scaleY(0);
}
.bg-btn-green{
background: #059669;
	font-size: 13px;
color: white;
width: 80px;
height: 58px;
white-space: nowrap;
justify-content: center;
}

.bg-btn-red{
width: 80px;
height: 58px;
background: #E11D48;
font-size: 13px;
color: white;
white-space: nowrap;
justify-content: center;
}

</style>
