<template>
    <div class="entry">
        <label class="narnoo-block narnoo-pt-3 narnoo-px-5 narnoo-text-gray-900 narnoo-leading-5 narnoo-text-xl narnoo-font-bold narnoo-cursor-pointer" >Product Extras</label>

        <div class="narnoo-w-full narnoo-mx-auto">
        
            <div v-for="(extras,index) in productExtras" :key="index" :id="`extras-tab-${productId}-${extras.id}`" :class="(customTheme === true || customTheme === 'true' ? 'tab narnoo-w-full narnoo-overflow-hidden narnoo-border narnoo-border-gray-300 narnoo-mt-2 narnoo-relative narnoo-secondary-border' : 'tab narnoo-w-full narnoo-overflow-hidden narnoo-border narnoo-border-gray-300 narnoo-mt-2 narnoo-relative')">

                <input class="narnoo-absolute narnoo-opacity-0" :id="`extras-tab-checkbox-${productId}-${index}`" type="checkbox" v-on:change="tabSelected(index)">

                <label class="narnoo-absolute narnoo-w-full  narnoo-mt-1 narnoo-h-10 narnoo-z-20  narnoo-cursor-pointer " :for="`extras-tab-checkbox-${productId}-${index}`"><div :class="(customTheme === true || customTheme === 'true' ? 'narnoo-bg-blue-700 narnoo-mt-1 narnoo-h-7 narnoo-w-8 narnoo-absolute narnoo-mt-2 narnoo-right-5 narnoo-secondary-bg' : 'narnoo-bg-blue-700 narnoo-mt-1 narnoo-h-7 narnoo-w-8 narnoo-absolute narnoo-mt-2 narnoo-right-5')"><div class="chevron-icon narnoo-z-10"></div></div></label>

                <div class="narnoo-w-full narnoo-mt-1 narnoo-z-10 narnoo-cursor-pointer">
                  <div class="narnoo-grid md:narnoo-grid-cols-2 narnoo-grid-cols-1  narnoo-mx-5 narnoo-z-10 narnoo-relative tab-header " >
                    <div>
                      <label class="narnoo-block narnoo-py-3 narnoo-text-gray-900 narnoo-leading-5 narnoo-font-bold narnoo-w-5/6">
                        <svg :id="`cartNotif-${productId}-${extras.id}`" class="narnoo-hidden narnoo-fill-current narnoo-h-6 narnoo-w-6 narnoo-text-green-500 narnoo-mr-4 narnoo-inline" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg> 
                        {{ extras.label }} 
                        <div class="narnoo-inline narnoo-hidden">(added to cart)</div> 
                      </label>
                    </div>
                    <div class="md:narnoo-text-right md:narnoo-pr-5 md:narnoo-py-2 md:narnoo-mr-5">
                      <span class="narnoo-text-md narnoo-leading-7 narnoo-hidden md:narnoo-block narnoo-font-bold narnoo-text-gray-900">
                        <div class="narnoo-inline narnoo-hidden">Price $
                          <div class="narnoo-inline" :id="`priceTotal-${productId}-${extras.id}`">0</div>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
               
                <div class="tab-content narnoo-overflow-hidden narnoo-leading-normal">

                    <span class="narnoo-text-md narnoo-mx-5 md:narnoo-hidden narnoo-leading-7 narnoo-font-bold narnoo-text-gray-900">
                      <div class="narnoo-inline">Price $
                        <div class="narnoo-inline" :id="`pricing-mobile-${productId}-${extras.id}`">0</div>
                      </div>
                    </span> 
                 
                    <p v-if="extras.note" class="narnoo-text-xs narnoo-font-normal narnoo-text-gray-700 narnoo-leading-4 md:narnoo-w-9/12 sm:narnoo-w-full xs:narnoo-w-full narnoo-mt-3 narnoo-mb-3 narnoo-px-5 narnoo-pr-5"> {{extras.note }}</p>
                    
                    <div v-if="extras.note" class="narnoo-pb-2 narnoo-border-gray-200 narnoo-pl-0 narnoo-pb-0 narnoo-border-l-0 narnoo-border-t-2"></div>
                    
                    <div v-else class="narnoo-pb-3"></div>

                    <p class="narnoo-block narnoo-text-gray-900 narnoo-leading-5 narnoo-font-bold narnoo-mb-2 narnoo-px-5">Extra Pricing</p>
                    
                    <div class="narnoo-border-gray-200 narnoo-pl-0 narnoo-pb-0 narnoo-border-l-0 narnoo-border-t-2"></div>
                    
                    <div class="narnoo-mb-2 narnoo-mt-2 narnoo-px-5 narnoo-pr-5" v-for="(extraPrice,index) in extras.price" :key="index">

                      <div class="narnoo-grid narnoo-gap-2 md:narnoo-grid-cols-2 lg:narnoo-grid-cols-2 narnoo-grid-cols-1" v-if="respaxOnlyOptions!=null && filterExtrasLabel(extraPrice.label)">
                        
                        <div class="narnoo-text-xs narnoo-font-normal narnoo-text-gray-700 narnoo-leading-4">{{ extraPrice.label }} {{extras.currency}} ${{ extraPrice.price }} </div>
                        
                        <div class="narnoo-text-right narnoo-text-xs narnoo-font-normal narnoo-text-gray-700 narnoo-leading-4">
                            <div class="narnoo-flex narnoo-justify-start md:narnoo-justify-end lg:narnoo-justify-end narnoo-rounded-lg narnoo-w-25 quantity-picker" role="group" :id="`optionQty-${productId}-${extras.id}-${index}`">
                                <button v-on:click="changeQty(extras.type,extras.label,extras.id,index,'minus')" type="button" class="action-buttons narnoo-bg-white narnoo-text-blue-500 narnoo-border narnoo-border-r-0 narnoo-border-black  narnoo-px-2 narnoo-py-1 narnoo-outline-none focus:narnoo-shadow-outline">
                                  <svg xmlns="http://www.w3.org/2000/svg" class="narnoo-h-6 narnoo-w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18 12H6" />
                                  </svg>
                                </button>
                                <input :data-type="`${extras.type}`" :data-price="`${extraPrice.price}`" :data-qty="0" :data-label="`${extras.label}`" :data-pricinglabel="`${extraPrice.label}`" type="text" name="" value="0" readonly placeholder="0" class="narnoo-text-center narnoo-w-10 narnoo-bg-white narnoo-text-blue-500   narnoo-border narnoo-border-blue-500 narnoo-px-2 narnoo-py-1 narnoo-outline-none focus:narnoo-shadow-outline">
                                <button v-on:click="changeQty(extras.type,extraPrice.label,extras.id,index,'add')" type="button" class="action-buttons narnoo-bg-white narnoo-text-blue-500 narnoo-px-2 narnoo-py-1 narnoo-border narnoo-border-l-0 narnoo-border-blue-500  narnoo-outline-none focus:narnoo-shadow-outline">
                                  <svg xmlns="http://www.w3.org/2000/svg" class="narnoo-h-6 narnoo-w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
                                  </svg>
                                </button>
                            </div>
                        </div>

                      </div>

                      <div class="narnoo-grid narnoo-gap-2 md:narnoo-grid-cols-2 lg:narnoo-grid-cols-2 narnoo-grid-cols-1" v-if="respaxOnlyOptions==null">

                        <div class="narnoo-text-xs narnoo-font-normal narnoo-text-gray-700 narnoo-leading-4">{{ extraPrice.label }} {{extras.currency}} ${{ extraPrice.price }} </div>
                        
                        <div class="narnoo-text-right narnoo-text-xs narnoo-font-normal narnoo-text-gray-700 narnoo-leading-4">
                            <div class="narnoo-flex narnoo-justify-start md:narnoo-justify-end lg:narnoo-justify-end narnoo-rounded-lg narnoo-w-25 quantity-picker" role="group" :id="`optionQty-${productId}-${extras.id}-${index}`">
                                <button v-on:click="changeQty(extras.type,extras.label,extras.id,index,'minus')" type="button" :class="(customTheme === true || customTheme === 'true' ? 'narnoo-bg-white narnoo-text-blue-500 narnoo-border narnoo-border-r-0 narnoo-border-blue-500 narnoo-secondary-button  narnoo-px-2 narnoo-py-1 narnoo-outline-none focus:narnoo-shadow-outline' : 'narnoo-bg-white narnoo-text-blue-500 narnoo-border narnoo-border-r-0 narnoo-border-blue-500 narnoo-px-2 narnoo-py-1 narnoo-outline-none focus:narnoo-shadow-outline')">
                                  <svg xmlns="http://www.w3.org/2000/svg" class="narnoo-h-6 narnoo-w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18 12H6" />
                                  </svg>
                                </button>
                                <input :data-type="`${extras.type}`" :data-price="`${extraPrice.price}`" :data-qty="0" :data-label="`${extras.label}`" :data-pricinglabel="`${extraPrice.label}`" type="text" name="" value="0" readonly placeholder="0" :class="(customTheme === true || customTheme === 'true' ? 'narnoo-text-center narnoo-w-10 narnoo-bg-white narnoo-text-blue-500   narnoo-border narnoo-border-blue-500 narnoo-px-2 narnoo-py-1 narnoo-outline-none focus:narnoo-shadow-outline narnoo-primary-border narnoo-primary-text narnoo-secondary-border narnoo-primary-text narnoo-no-border-radius' : 'narnoo-text-center narnoo-w-10 narnoo-bg-white narnoo-text-blue-500   narnoo-border narnoo-border-blue-500 narnoo-px-2 narnoo-py-1 narnoo-outline-none focus:narnoo-shadow-outline narnoo-primary-border narnoo-primary-text')">
                                <button v-on:click="changeQty(extras.type,extraPrice.label,extras.id,index,'add')" type="button" :class="(customTheme === true || customTheme === 'true' ? 'narnoo-bg-white narnoo-text-blue-500 narnoo-border narnoo-border-l-0 narnoo-border-blue-500 narnoo-secondary-button  narnoo-px-2 narnoo-py-1 narnoo-outline-none focus:narnoo-shadow-outline' : 'narnoo-bg-white narnoo-text-blue-500 narnoo-border narnoo-border-l-0 narnoo-border-blue-500 narnoo-px-2 narnoo-py-1 narnoo-outline-none focus:narnoo-shadow-outline')">
                                  <svg xmlns="http://www.w3.org/2000/svg" class="narnoo-h-6 narnoo-w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
                                  </svg>
                                </button>
                            </div>
                        </div>

                      </div>

                    </div>
                    
                    <div class="narnoo-border-gray-200 narnoo-pl-0 narnoo-pb-0 narnoo-border-l-0 narnoo-border-t-2"></div>
                  

                    <div class="narnoo-mt-5 narnoo-p-2 narnoo-bg-gray-200 narnoo-flex justify-content-end narnoo-primary-bg">
                    <button type="submit" :class="(customTheme === true || customTheme === 'true' ? 'narnoo-flex narnoo-primary-bg narnoo-mr-3 narnoo-ml-auto narnoo-mt-1 narnoo-py-2 narnoo-px-4 narnoo-border narnoo-border-transparent narnoo-shadow-sm narnoo-text-md  narnoo-font-medium narnoo-rounded-md narnoo-text-white narnoo-bg-blue-600 hover:narnoo-bg-blue-700 focus:narnoo-outline-none focus:narnoo-ring-2 focus:narnoo-ring-offset-2 focus:narnoo-ring-indigo-500 narnoo-secondary-button' : 'narnoo-flex narnoo-primary-bg narnoo-mr-3 narnoo-ml-auto narnoo-mt-1 narnoo-py-2 narnoo-px-4 narnoo-border narnoo-border-transparent narnoo-shadow-sm narnoo-text-md  narnoo-font-medium narnoo-rounded-md narnoo-text-white narnoo-bg-blue-600 hover:narnoo-bg-blue-700 focus:narnoo-outline-none focus:narnoo-ring-2 focus:narnoo-ring-offset-2 focus:narnoo-ring-indigo-500')"
                    v-on:click="addToCart(extras.id,'addToCart')"
                    >
                        Add to cart
                        <svg xmlns="http://www.w3.org/2000/svg" v-if="isLoading" class="narnoo-animate-spin narnoo-transform narnoo-rotate-180 narnoo-h-5 narnoo-w-5 narnoo-ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                        </svg>
                    </button>
                    </div>

                </div>
            </div>

        </div>


    </div>
</template>

<script>

/**
 * 
 * Component to render Product extras results
 * 
 * We need to show a lead in price for each extra. Perhaps try "From {min price}" - We could leave it as 0 as perhaps there is an extra
 * that is $0
 * 
 */

const { encrypt,decrypt } = require('../helpers/crypto');
import Analytics from '@expo/rudder-sdk-node';

const client = new Analytics(process.env.VUE_APP_WRITE_KEY, process.env.VUE_APP_DATA_PLAN_URL);

export default {
  name: "ProductExtras",
  components: {},
  props: [
      'productExtras',
      'bookingData',
      'productId',
      'customTheme'
      ],
  data() {
      return {
        isAddedToCart : false,
        isLoading : false,
        respaxOnlyOptions: null
      };
    },
    mounted() {
      this.findGuestOption();      
    },
  methods: {

    filterExtrasLabel(extraLabel){
      if(this.respaxOnlyOptions!=null){

          let familyOptionSelected = this.respaxOnlyOptions.filter(data=>data.label.toLowerCase().indexOf('family')>-1)

          if(familyOptionSelected.length>0){
            return true;
          } 
          else if(extraLabel.toLowerCase().indexOf('adult')>-1 || extraLabel.toLowerCase().indexOf('child')>-1) {

            let labelFound = this.respaxOnlyOptions.filter(data=>data.label.toLowerCase().indexOf(extraLabel.toLowerCase())>-1)

            if(labelFound.length>0){
              return true;
            }
            else{
              return false;
            }
          }
          else{
            return true
          }
      }

    },

    findGuestOption(){
      let cartData = JSON.parse(decrypt(JSON.parse(localStorage.getItem("cartData"))));  

      cartData.map((item)=>{
        if(item.productId===this.productId) {
          if(item.bookingPlatForm.toLowerCase().indexOf('respax')>-1) {
            this.respaxOnlyOptions = item.option;
          }
        }
      });
    },

    operatorCurrency()
        {
        let storedCurrency = JSON.parse(localStorage.getItem("currency"));
        return storedCurrency?.currency ?? null;
        
        },

    addToCart(id,action){

     

      let extrasTab =  document.getElementById(`extras-tab-${this.productId}-${id}`);
      let quantityPickerId = extrasTab.querySelectorAll(".quantity-picker");
      let cartData = JSON.parse(decrypt(JSON.parse(localStorage.getItem("cartData"))));  

      cartData.map((item)=>{
        if(item.productId===this.productId) {
          for ( var extraCtr =0; extraCtr < quantityPickerId.length; extraCtr++){
            let extraPricing = quantityPickerId[extraCtr];
            
            let actionButtons = document.getElementById(extraPricing.attributes.id.value);
            let qty = parseInt(actionButtons.children[1].dataset.qty);
            let price = parseFloat(actionButtons.children[1].dataset.price);
            let label = actionButtons.children[1].dataset.label;
            let pricingLabel = actionButtons.children[1].dataset.pricinglabel;
            let type = actionButtons.children[1].dataset.type;


            
            if(item.extra!=null){
              let isExisting = item.extra.filter(e=>e.id==id && e.label == label && e.pricingLabel==pricingLabel);
              if(isExisting.length>0) {
                item.extra.forEach(extra=>{
                  if(extra.id==id && extra.label==label && extra.pricingLabel == pricingLabel) {
                    extra.quantity = qty;
                  }
                })
              }
              else{
                item.extra.push({
                  id : id,
                  label : label,
                  quantity : qty,
                  pricingLabel : pricingLabel,
                  price : price,
                  type : type,
                  currency: this.operatorCurrency()
                })
              }
              
            }
            else{
              let dataArray = [];
              dataArray.push({
                id : id,
                label : label,
                quantity : qty,
                pricingLabel : pricingLabel,
                price : price,
                type : type,
                currency: this.operatorCurrency()
              })
              item.extra = dataArray;
            }
            let remaingExtras = item.extra.filter(e=> e.quantity!=0 );
            item.extra = remaingExtras;


          }

          let extraTotal = parseFloat(document.getElementById(`priceTotal-${this.productId}-${id}`).innerText);
          
          if(extraTotal>0 && action=='addToCart'){
            document.getElementById(`cartNotif-${this.productId}-${id}`).classList.remove("narnoo-hidden");
            document.getElementById(`cartNotif-${this.productId}-${id}`).parentNode.parentNode.parentNode.parentNode.classList.add("narnoo-bg-blue-100");
            document.getElementById(`cartNotif-${this.productId}-${id}`).parentNode.parentNode.parentNode.parentNode.classList.add("narnoo-border-t-4","narnoo-border-green-500");
            document.getElementById(`cartNotif-${this.productId}-${id}`).nextSibling.nextSibling.classList.remove("narnoo-hidden");
          }
        }
      })

      client.track({
        anonymousId: localStorage.getItem("anonymousId"),
        event: "add extras",
        properties: {
          extras: cartData[0].extra
        },
      })

      let encryptedCartData = encrypt(JSON.stringify(cartData));
      
      localStorage.setItem("cartData",JSON.stringify(encryptedCartData));
        

    },

    tabSelected(index) {
      let tabs = document.querySelectorAll('input[type="checkbox"]');
      let selectedTab =  `extras-tab-checkbox-'${this.productId}-${index}`;
      tabs.forEach(tab=>{
        if (tab.id == selectedTab){
          tab.parentElement.classList.remove("narnoo-border-gray-300");
          tab.parentElement.classList.add("narnoo-border-blue-600");
        }
        else{
          tab.parentElement.classList.remove("narnoo-border-blue-600");
          tab.parentElement.classList.add("narnoo-border-gray-300");
        }
      })
      
    },

    changeQty(type,label,id,index,action) {

        let cartData = JSON.parse(decrypt(JSON.parse(localStorage.getItem("cartData")))); 

        let actionButtons = document.getElementById(`optionQty-${this.productId}-${id}-${index}`);

        let qty = parseInt(actionButtons.children[1].value);
        let price = parseFloat(actionButtons.children[1].dataset.price);

        let totalPrice = parseFloat(document.getElementById(`priceTotal-${this.productId}-${id}`).innerText);
        let totalParticipantCount = 0
        cartData.map((item)=>{
          if(item.productId===this.productId){
            totalParticipantCount = item.option.map(o => o.qty).reduce((a,c) => {return a+c});
          }
        })

        if(action=='add') {
          
            qty++;
            if(type=='perOrder') {
              qty = 1
              totalPrice = price * qty;
              actionButtons.children[1].value=1;
              actionButtons.children[1].dataset.qty = qty
            }
            else if (type=='totalPaxs'){
              qty= totalParticipantCount
              totalPrice = price * qty;
               actionButtons.children[1].value=1;
               actionButtons.children[1].dataset.qty = qty
            }
            else if (type=='perPax'){
              if (qty>totalParticipantCount) {
                qty = totalParticipantCount
              }
              else{
                totalPrice += price;
              }
               
              actionButtons.children[1].value=qty;
              actionButtons.children[1].dataset.qty = qty
            }

            
           
        }
        else{
            
            if(qty>=1) {
                qty--;
                actionButtons.children[1].value=qty;
                actionButtons.children[1].dataset.qty = qty
                totalPrice -= price;
            }
        }
        (totalPrice>0) ? document.getElementById(`priceTotal-${this.productId}-${id}`).parentElement.classList.remove("narnoo-hidden") : document.getElementById(`priceTotal-${this.productId}-${id}`).parentElement.classList.add("narnoo-hidden");
        (totalPrice>0) ? document.getElementById(`pricing-mobile-${this.productId}-${id}`).parentElement.classList.remove("narnoo-hidden") : document.getElementById(`pricing-mobile-${this.productId}-${id}`).parentElement.classList.add("narnoo-hidden");
        
        (qty>0) ? null : this.addToCart(id,'addToCart');
        if(action!='add') {
          document.getElementById(`cartNotif-${this.productId}-${id}`).classList.add("narnoo-hidden");
          document.getElementById(`cartNotif-${this.productId}-${id}`).parentNode.parentNode.parentNode.parentNode.classList.remove("narnoo-bg-blue-100");
          document.getElementById(`cartNotif-${this.productId}-${id}`).parentNode.parentNode.parentNode.parentNode.classList.remove("narnoo-border-t-4","narnoo-border-green-500");
          document.getElementById(`cartNotif-${this.productId}-${id}`).nextSibling.nextSibling.classList.add("narnoo-hidden");
        }
        document.getElementById(`priceTotal-${this.productId}-${id}`).innerText = parseFloat(totalPrice);
        document.getElementById(`pricing-mobile-${this.productId}-${id}`).innerText = parseFloat(totalPrice);
    }
   
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

label {
    margin-bottom: 0px !important;
}
hr{
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

/* set tab content max height limit */
.tab-content {
  max-height: 0;
  -webkit-transition: max-height .35s;
  -o-transition: max-height .35s;
  transition: max-height .35s;
  overflow: hidden;
}
 
/* set tab content to its max height when checkbox button is checked */
.tab input:checked ~ .tab-content {
  max-height:unset !important;
  border-color:#0EA5E9; 
}

        
.tab input[type=checkbox] + label > div > .chevron-icon:after{

  content: "\25BE";
  width:30px;
  height:30px;
  position:absolute;
  color:rgb(255, 255, 255);
  font-size: 1.25rem;
  text-align: center;
  -webkit-transition: all .35s;
  -o-transition: all .35s;
  transition: all .35s;
}


.tab input[type=checkbox]:checked +  label > div > .chevron-icon::after{
  transform:rotate(180deg);
}      


</style>
