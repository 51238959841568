<template>
    <div class="narnoo-m-3" v-if="errorModal.show">
        <div class="min-w-screen narnoo-h-screen animated fadeIn faster  narnoo-fixed  narnoo-left-0 narnoo-top-0 narnoo-flex narnoo-justify-center narnoo-items-center narnoo-inset-0 narnoo-z-50 narnoo-outline-none focus:narnoo-outline-none narnoo-bg-no-repeat narnoo-bg-center narnoo-bg-cover"   id="modal-id">
            <div class="narnoo-absolute narnoo-bg-black narnoo-opacity-80 narnoo-inset-0 narnoo-z-0"></div>
            <div class="narnoo-w-full  narnoo-max-w-lg narnoo-p-5 narnoo-relative narnoo-mx-auto narnoo-my-auto narnoo-rounded-xl narnoo-shadow-lg  narnoo-bg-white ">
            <div class="">
                <div class="narnoo-text-center narnoo-p-5 narnoo-flex-auto narnoo-justify-center">
                    
                    <svg xmlns="http://www.w3.org/2000/svg" class="narnoo-w-16 narnoo-h-16 narnoo-flex narnoo-items-center narnoo-text-red-500 narnoo-mx-auto  narnoo-rounded-full" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>

                    <div class="narnoo-text-xl narnoo-font-bold narnoo-py-4 ">Something went wrong.</div>
                    
                    <div class="narnoo-text-left narnoo-w-64 narnoo-mx-auto">
                        <p v-if="errorModal.errors.length>0">Details:</p>
                        <div class="narnoo-flex narnoo-gap-2 narnoo-items-center" v-for="(error,index) in errorModal.errors" :key="index">
                            <span class="narnoo-font-bold narnoo-mt-2 narnoo-text-red-500">*</span>
                            <span class="">{{error}}</span>
                        </div>
                    </div>
                                
                </div>
                <div class="narnoo-p-3  narnoo-mt-2 narnoo-text-center narnoo-space-x-4 md:narnoo-block">
                <button class="narnoo-mt-5 narnoo-py-2 narnoo-m-auto narnoo-w-32 narnoo-px-4 narnoo-border narnoo-border-transparent narnoo-primary-bg narnoo-shadow-sm narnoo-text-md narnoo-font-medium narnoo-rounded-md narnoo-text-white narnoo-bg-blue-600 hover:narnoo-bg-blue-700 focus:narnoo-outline-none focus:narnoo-ring-2 focus:narnoo-ring-offset-2 focus:narnoo-ring-indigo-500" v-on:click="errorModal.show=!errorModal.show">Ok</button>
                </div>
            </div>
            </div>
        </div>

    </div>
</template>

<script>


export default {
  name: "ErrorModal",
  components: {},
  props: [
      'errorModal'
      ],
  data() {
      return {
      };
    },
    mounted() {
    },
  methods: {

   
  }
};
</script>

<style scoped>

</style>
