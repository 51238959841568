<template>
    <div class="entry">

      <div class="narnoo-bg-yellow-50 narnoo-border-l-4 narnoo-border-red-400 narnoo-p-4" v-if="showPickupError">
        <div class="narnoo-flex">
            <div class="narnoo-flex-shrink-0">
                <!-- Heroicon name: solid/exclamation -->
                <svg xmlns="http://www.w3.org/2000/svg" class="narnoo-h-5 narnoo-w-5 narnoo-text-red-400" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M11.3 1.046A1 1 0 0112 2v5h4a1 1 0 01.82 1.573l-7 10A1 1 0 018 18v-5H4a1 1 0 01-.82-1.573l7-10a1 1 0 011.12-.38z" clip-rule="evenodd" />
                </svg>
            </div>
            <div class="narnoo-ml-3">
                <p class="narnoo-text-sm narnoo-text-yellow-700">
                Please select pickup location first!.
                </p>
            </div>
          </div>
      </div>

        <div class="tab narnoo-w-full narnoo-overflow-hidden narnoo-border narnoo-border-blue-600 narnoo-mt-5">

            <label class="narnoo-block narnoo-px-5 narnoo-py-2 narnoo-text-gray-900 narnoo-leading-5 narnoo-font-bold narnoo-cursor-pointer" >This product requires some pickup information</label>

           
            <div class="narnoo-pb-1 narnoo-border-gray-200 narnoo-pl-0 narnoo-pb-0 narnoo-border-l-0 narnoo-border-t-2"></div>

            <div class="narnoo-grid md:narnoo-grid-cols-2 narnoo-mb-2">

              <div class="narnoo-pl-5 narnoo-pr-5">
                <select :class="(customTheme === true || customTheme === 'true' ? 'narnoo-mt-1 narnoo-w-full narnoo-block narnoo-text-base sm:narnoo-text-sm narnoo-rounded-md' : 'narnoo-mt-1 narnoo-w-full narnoo-block narnoo-text-base narnoo-border-gray-300 focus:narnoo-outline-none focus:narnoo-ring-indigo-500 focus:narnoo-border-indigo-500 sm:narnoo-text-sm narnoo-rounded-md')" ref="pickUpDropdown" :id="`pickUpDropdown-${this.apiData.apiUrlParams.productId}`" v-on:change="selectPickupLocation()">
                    <option value="default">Select pickup location</option>
                    <option v-for="(pickup,index) in pickupOptions" :key="index" :value="`${pickup.id}`"> {{pickup.label}}</option>
                </select>  
              </div>

              <div>
                <div class="narnoo-bg-yellow-50 narnoo-border-l-4 narnoo-mt-1 narnoo-mx-5 narnoo-border-yellow-400 narnoo-p-2" v-if="pickUpLoading">
                  <div class="narnoo-flex">
                      <div class="narnoo-flex-shrink-0">
                          <svg xmlns="http://www.w3.org/2000/svg" class="narnoo-animate-spin narnoo-transform narnoo-rotate-180 narnoo-h-5 narnoo-w-5 narnoo-ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                          </svg>
                      </div>
                      <div class="narnoo-ml-3">
                          <p class="narnoo-text-sm narnoo-text-yellow-700">
                          Updating pickup pricing. please wait.
                          </p>
                      </div>
                    </div>
                </div>
              </div>
              

            </div>

            
            <div class="narnoo-pb-2 narnoo-border-gray-200 narnoo-pl-0 narnoo-pb-0 narnoo-border-l-0 narnoo-border-t-2"></div>
                
            <div class="narnoo-grid narnoo-grid-cols-1 md:narnoo-w-7/12 narnoo-ml-auto narnoo-pr-5 narnoo-pb-2">

                <p class="narnoo-block narnoo-text-gray-900 narnoo-leading-5 narnoo-font-bold narnoo-mb-1 narnoo-px-5">Guest Pricing</p>
                <div class="narnoo-my-1  narnoo-border-gray-200 narnoo-pl-0 narnoo-pb-0 narnoo-border-l-0 narnoo-border-t-2"></div>
                <div class="narnoo-grid narnoo-grid-cols-2  narnoo-mt-1 narnoo-px-5 narnoo-pr-5" v-for="(bookingDataGuestPrice,index) in bookingDataGuestPricing" :key="index">
                    <div v-if="bookingDataGuestPrice.qty > 0" class="narnoo-text-xs narnoo-font-normal narnoo-text-gray-700 narnoo-leading-4">{{ bookingDataGuestPrice.label }} {{ bookingDataGuestPrice.qty }} x ${{ bookingDataGuestPrice.price }}</div>
                    <div v-if="bookingDataGuestPrice.qty > 0" class="narnoo-text-right narnoo-text-xs narnoo-font-normal narnoo-text-gray-700 narnoo-leading-4">{{ bookingDataGuestPrice.currency }}$ {{ bookingDataGuestPrice.total }}</div>
                </div>
                <div class="narnoo-my-1 narnoo-border-gray-200 narnoo-pl-0 narnoo-pb-0 narnoo-border-l-0 narnoo-border-t-2"></div>
            </div>

        </div>


    </div>
</template>

<script>

/**
 * 
 * Component to render PickupLocation results
 * 
 * This requires the confirm price on the pick up if needed. Because we store the previous price we could subtract the new price from
 * the old price and show the cost of pickup. So if Adult was 100 and then a pick up converts the price to 120. We can show a note of Transfer price = $20.00
 * 
 */

import * as axios from "axios";
const { encrypt,decrypt } = require('../helpers/crypto');

import Analytics from '@expo/rudder-sdk-node';

const client = new Analytics(process.env.VUE_APP_WRITE_KEY, process.env.VUE_APP_DATA_PLAN_URL);

export default {
  name: "PickupLocation",
  components: {},
  props: [
      'pickupOptions',
      'bookingData',
      'apiData',
      'showPickupError',
      'errorModal',
      'businessId',
      'customTheme'
      ],
  data() {
      return {
        bookingDataGuestPricing:null,
        pickUpLoading:false
      };
    },
    mounted() {
      this.getProductOptions();

    },
  methods: {

    getProductOptions() {
      let cartData = JSON.parse(decrypt(JSON.parse(localStorage.getItem("cartData"))));
      this.bookingDataGuestPricing = cartData.filter(data=> data.productId===this.apiData.apiUrlParams.productId)
      this.bookingDataGuestPricing = this.bookingDataGuestPricing[0].option
    },

    selectPickupLocation(){

      if(this.$refs.pickUpDropdown.value=="default"){ return; }

      this.apiData.headers.params.pickUp=this.$refs.pickUpDropdown.value;
      let selectedPickup = this.pickupOptions.filter(pickup =>pickup.id==this.$refs.pickUpDropdown.value);


      let cartData = JSON.parse(decrypt(JSON.parse(localStorage.getItem("cartData"))));
      
      if(selectedPickup[0].calculatePrice) {

          this.pickUpLoading=true;

          axios.get(process.env.VUE_APP_API_URL+"/booking/confirm_price_widget/"+this.apiData.apiUrlParams.businessId+"/"+this.apiData.apiUrlParams.businessType+"/"+this.apiData.apiUrlParams.operatorID+"/"+this.apiData.apiUrlParams.productId,this.apiData.headers).then((response)=>{
            this.pickUpLoading=false;
            if(response.data.success){
              
              let newlyUpdatedPriceOptions = response.data.data.productOptions;

              cartData.map((item)=>{
                if(item.productId===this.apiData.apiUrlParams.productId){

                  item.option.forEach(option=>{
                    var newPriceOptions = newlyUpdatedPriceOptions.filter(newOption=>newOption.id==option.id);
                   
                    option.currency = newPriceOptions[0].currency;
                    option.collectLevy = newPriceOptions[0].collectLevy;
                    option.levy = newPriceOptions[0].levy,
                    option.commission = newPriceOptions[0].commission;
                    option.pax = newPriceOptions[0].pax;
                    option.minQuantity = newPriceOptions[0].minQuantity;
                    option.maxQuantity = newPriceOptions[0].maxQuantity;
                    option.group = newPriceOptions[0].group;
                    option.id = newPriceOptions[0].id;
                    option.label = newPriceOptions[0].label;
                    option.price = newPriceOptions[0].price;

                    // var addedLevy = null;

                    // if(option.collectLevy== true && option.levy!=null) {
                    //   addedLevy = option.levy;
                    // }
                    // else{
                    //   addedLevy=0;
                    // }

                    // option.total = (option.qty * option.price) + addedLevy;

                    option.total = (option.qty * option.price) 
                    
                  });
                  
                  item.pickUp = {
                    id : selectedPickup[0].id,
                    label : selectedPickup[0].label,
                    price : (selectedPickup[0].price==null) ? 0 : selectedPickup[0].price
                  }
                }
              })
              

              let encryptedCartData = encrypt(JSON.stringify(cartData));

              localStorage.setItem("cartData",JSON.stringify(encryptedCartData));
            }
          })
          .then(()=>{
            this.getProductOptions();
          })
          .catch( async () => {

            client.track({
              anonymousId: localStorage.getItem("anonymousId"),
              event: "error updating pickup pricing",
              properties: {
                id : selectedPickup[0] ? selectedPickup[0].id : null ,
                label : selectedPickup[0] ? selectedPickup[0].label : null,
                price : selectedPickup[0] ? (selectedPickup[0].price==null) ? 0 : selectedPickup[0].price : null
              },
            })

            await client.flush()

            this.pickUpLoading=false
            this.errorModal.show=true
            this.errorModal.errors=["Error updating pickup data."]
          });

      }
      else{

        cartData.map((item)=>{
          if(item.productId===this.apiData.apiUrlParams.productId){
            item.pickUp = {
              id : selectedPickup[0].id,
              label : selectedPickup[0].label,
              price : (selectedPickup[0].price==null) ? 0 : selectedPickup[0].price
            }
          }
        })

        let encryptedCartData = encrypt(JSON.stringify(cartData));

        localStorage.setItem("cartData",JSON.stringify(encryptedCartData));

        this.getProductOptions();
          
      }

      client.track({
        anonymousId: localStorage.getItem("anonymousId"),
        event: "Selected pickup",
        properties: {
          id : selectedPickup[0].id,
          label : selectedPickup[0].label,
          price : (selectedPickup[0].price==null) ? 0 : selectedPickup[0].price
        },
      })

      
    },

    tabSelected(index) {
      let tabs = document.querySelectorAll('input[type="radio"]');
      let selectedTab = 'tab-'+index;
      tabs.forEach(tab=>{
        if (tab.id == selectedTab){
          tab.parentElement.classList.remove("narnoo-border-gray-300");
          tab.parentElement.classList.add("narnoo-border-blue-600");
        }
        else{
          tab.parentElement.classList.remove("narnoo-border-blue-600");
          tab.parentElement.classList.add("narnoo-border-gray-300");
        }
      })
      
    },

    textToHtmlMarkup(htmlString) {
      let div = document.createElement('div');
      div.innerHTML=htmlString;
      return div.textContent ;
    },

   
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
label {
    margin-bottom: 0px !important;
}
hr{
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

</style>
