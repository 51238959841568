import Vue from 'vue'
import App from './App.vue'
import '@ungap/custom-elements';
import vueCustomElement from 'vue-custom-element'
import '@/assets/css/styles.css';

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(fas)

Vue.component('font-awesome-icon', FontAwesomeIcon)

//import VueSkeletonLoader from 'skeleton-loader-vue';

// Register the component globally
//Vue.component('vue-skeleton-loader', VueSkeletonLoader);

Vue.config.productionTip = false
Vue.config.silent = true
Vue.use(vueCustomElement)

Vue.customElement('narnoo-availability-widget', App)
