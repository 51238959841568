<template>
    <div class="narnoo-bg-blue-50 narnoo-border-l-4 narnoo-border-blue-400 narnoo-p-4 slide-cart-alert">
        <div class="narnoo-flex">
            <div class="narnoo-flex-shrink-0">
            <!-- Heroicon name: solid/exclamation -->
            <svg xmlns="http://www.w3.org/2000/svg" class="narnoo-h-5 narnoo-w-5 narnoo-text-blue-400" viewBox="0 0 20 20" fill="currentColor">
                <path d="M3 1a1 1 0 000 2h1.22l.305 1.222a.997.997 0 00.01.042l1.358 5.43-.893.892C3.74 11.846 4.632 14 6.414 14H15a1 1 0 000-2H6.414l1-1H14a1 1 0 00.894-.553l3-6A1 1 0 0017 3H6.28l-.31-1.243A1 1 0 005 1H3zM16 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM6.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" />
            </svg>
            </div>
            <div class="narnoo-ml-3">
            <p class="narnoo-text-sm narnoo-text-blue-700">
                We've found products in your cart.
                <a href="javascript:void(0)" v-on:click="gotoCartPage()" class="narnoo-font-medium narnoo-underline narnoo-text-blue-700 hover:narnoo-text-blue-600">Take me to my cart.</a>
            </p>
            </div>
        </div>
    </div>
</template>

<script>
import Analytics from '@expo/rudder-sdk-node';
const client = new Analytics(process.env.VUE_APP_WRITE_KEY, process.env.VUE_APP_DATA_PLAN_URL);

export default {
    name: "Cartalert",
    props:['checkoutUrl'],
    methods: {
        async gotoCartPage(){
            
            client.track({
                anonymousId: localStorage.getItem("anonymousId"),
                event: "take me to cart",
                properties: {},
            })

            await client.flush()

            window.location.href=this.checkoutUrl;
        }
    }
}
</script>
<style scoped>
.slide-cart-alert {
    display: block;
    animation: slide-down-alert .3s ease-out;
    -webkit-animation: slide-down-alert .3s ease-out;
    -moz-animation: slide-down-alert .3s ease-out;
}


@keyframes slide-down-alert {
      0% { opacity: 0; -webkit-transform: translateY(-50%); }   
    100% { opacity: 1; -webkit-transform: translateY(0); }
}
@-webkit-keyframes slide-down-alert {
      0% { opacity: 0; -webkit-transform: translateY(-50%); }   
    100% { opacity: 1; -webkit-transform: translateY(0); }
}
@-moz-keyframes slide-down-alert {
      0% { opacity: 0; -moz-transform: translateY(-50%); }   
    100% { opacity: 1; -moz-transform: translateY(0); }
}
</style>