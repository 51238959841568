<template>
<div class="narnoo-w-full narnoo-mx-auto narnoo-mt-7 narnoo-pb-5">

    <div class="narnoo-m-3" v-if="duplicateAprrovalDataModal.show">
        <div class="min-w-screen narnoo-h-screen animated fadeIn faster  narnoo-fixed  narnoo-left-0 narnoo-top-0 narnoo-flex narnoo-justify-center narnoo-items-center narnoo-inset-0 narnoo-z-50 narnoo-outline-none focus:narnoo-outline-none narnoo-bg-no-repeat narnoo-bg-center narnoo-bg-cover"   id="modal-id">
            <div class="narnoo-absolute narnoo-bg-black narnoo-opacity-80 narnoo-inset-0 narnoo-z-0"></div>
            <div class="narnoo-w-full  narnoo-max-w-lg narnoo-p-5 narnoo-relative narnoo-mx-auto narnoo-my-auto narnoo-rounded-xl narnoo-shadow-lg  narnoo-bg-white ">
            <div class="">
                <div class="narnoo-text-center narnoo-p-5 narnoo-flex-auto narnoo-justify-center">

                    <svg xmlns="http://www.w3.org/2000/svg" class="narnoo-w-16 narnoo-h-16 narnoo-flex narnoo-items-center narnoo-text-yellow-500 narnoo-mx-auto  narnoo-rounded-full" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>

                    <div class="narnoo-text-xl narnoo-font-bold narnoo-py-4 ">The same product, with the same booking date is already in your cart.</div>
                  
                      <svg v-if="duplicateAprrovalDataModal.showLoader" xmlns="http://www.w3.org/2000/svg"  class="narnoo-mx-auto narnoo-animate-spin narnoo-transform narnoo-rotate-180 narnoo-h-8 narnoo-w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                      </svg>    
                </div>
                
                <div class="narnoo-pb-3 narnoo-flex narnoo-justify-center narnoo-gap-3 narnoo-flex-wrap">
                  <button class="narnoo-py-2 narnoo-w-42 narnoo-px-4 narnoo-border narnoo-border-transparent narnoo-primary-bg narnoo-shadow-sm narnoo-text-md narnoo-font-medium narnoo-rounded-md narnoo-text-white  focus:narnoo-outline-none focus:narnoo-ring-2 focus:narnoo-ring-offset-2 focus:narnoo-ring-indigo-500" 
                  v-bind:class="[duplicateAprrovalDataModal.disableButton==true ? 'narnoo-bg-gray-300 hover:narnoo-bg-gray-400' : ' narnoo-bg-green-600 hover:narnoo-bg-green-700']"
                  :disabled="duplicateAprrovalDataModal.disableButton==true"
                  v-on:click="duplicateApproval(true)">
                  Add product to cart
                  </button>
                  <button class="narnoo-py-2 narnoo-w-42 narnoo-px-4 narnoo-border narnoo-border-transparent narnoo-primary-bg narnoo-shadow-sm narnoo-text-md narnoo-font-medium narnoo-rounded-md narnoo-text-white focus:narnoo-outline-none focus:narnoo-ring-2 focus:narnoo-ring-offset-2 focus:narnoo-ring-indigo-500" 
                  v-bind:class="[duplicateAprrovalDataModal.disableButton==true ? 'narnoo-bg-gray-300 hover:narnoo-bg-gray-400' : 'narnoo-continue-cart-bg narnoo-bg-green-500 hover:narnoo-bg-green-600']"
                  :disabled="duplicateAprrovalDataModal.disableButton==true"
                  v-on:click="duplicateApproval(false)">
                  Continue to cart
                  </button>
                </div>
            </div>
            </div>
        </div>

    </div>

              <!-- New message for no product options -->
          <!-- <div class="narnoo-rounded-md narnoo-bg-green-50 narnoo-p-4" v-if="pickupOptions==null && productExtras==null">
            <div class="narnoo-flex">
              <div class="narnoo-flex-shrink-0">

                <svg class="narnoo-h-5 narnoo-w-5 narnoo-text-green-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                </svg>

              </div>
              <div class="narnoo-ml-3">
                <div class="narnoo-text-sm narnoo-font-medium narnoo-text-green-800">
                  No additional options provided
                </div>
                <div class="narnoo-mt-2 narnoo-text-sm narnoo-text-green-700">
                  <p>
                    This product does not require a noninated pickup location prior to booking and does not offer additional products to purchase before arrival.
                  </p>
                </div>
                <div class="narnoo-mt-4">
                  <div class="narnoo--mx-2 narnoo--my-1.5 narnoo-flex">
                    <button type="button" class="narnoo-continue-cart-bg narnoo-flex narnoo-bg-green-50 narnoo-px-2 narnoo-py-1.5 narnoo-rounded-md narnoo-text-sm narnoo-font-medium narnoo-text-green-800 hover:narnoo-bg-green-100 focus:narnoo-outline-none focus:narnoo-ring-2 focus:narnoo-ring-offset-2 focus:narnoo-ring-offset-green-50 focus:narnoo-ring-green-600"
                    v-on:click="computeAndRedirect()"
                    >
                      <span>Continue to cart</span>
                      <svg v-if="isLoading" xmlns="http://www.w3.org/2000/svg" class="narnoo-ml-1 narnoo-h-6 narnoo-w-6 narnoo-animate-spin narnoo-transform narnoo-rotate-180" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                    </svg>
                    </button>
                    
                  </div>
                </div>
              </div>
            </div>
          </div> -->

    <!--guest section -->
    <div class="narnoo-col-1">

        <p class="narnoo-block narnoo-text-gray-900 narnoo-leading-5 narnoo-font-bold narnoo-mb-2">Guests</p>
                
        <div :class="(customTheme === true || customTheme === 'true' ? 'narnoo-secondary-border narnoo-pl-0 narnoo-pb-0 narnoo-border-l-0 narnoo-border-t-2' : 'narnoo-border-gray-200 narnoo-pl-0 narnoo-pb-0 narnoo-border-l-0 narnoo-border-t-2')"></div>

        <div class="narnoo-bg-yellow-50 narnoo-border-l-4 narnoo-border-red-400 narnoo-p-4 narnoo-mt-3" v-if="showGuestError">
            <div class="narnoo-flex">
                <div class="narnoo-flex-shrink-0">
                    <!-- Heroicon name: solid/exclamation -->
                    <svg xmlns="http://www.w3.org/2000/svg" class="narnoo-h-5 narnoo-w-5 narnoo-text-red-400" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M11.3 1.046A1 1 0 0112 2v5h4a1 1 0 01.82 1.573l-7 10A1 1 0 018 18v-5H4a1 1 0 01-.82-1.573l7-10a1 1 0 011.12-.38z" clip-rule="evenodd" />
                    </svg>
                </div>
                <div class="narnoo-ml-3">
                    <p class="narnoo-text-sm narnoo-text-yellow-700">
                    Please select guests first!.
                    </p>
                </div>
            </div>
        </div>

        <div class="narnoo-bg-yellow-50 narnoo-border-l-4 narnoo-border-red-400 narnoo-p-4 narnoo-mt-3" v-if="showQtyGuestError">
            <div class="narnoo-flex">
                <div class="narnoo-flex-shrink-0">
                    <!-- Heroicon name: solid/exclamation -->
                    <svg xmlns="http://www.w3.org/2000/svg" class="narnoo-h-5 narnoo-w-5 narnoo-text-red-400" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M11.3 1.046A1 1 0 0112 2v5h4a1 1 0 01.82 1.573l-7 10A1 1 0 018 18v-5H4a1 1 0 01-.82-1.573l7-10a1 1 0 011.12-.38z" clip-rule="evenodd" />
                    </svg>
                </div>
                <div class="narnoo-ml-3">
                    <p class="narnoo-text-sm narnoo-text-yellow-700">
                       Please input less than or equal to current availability. 
                    </p>
                    <span class="narnoo-text-sm narnoo-text-yellow-700 narnoo-font-bold">Availability: {{ productAvailability }}</span>
                </div>
            </div>
        </div>

        <div class="narnoo-object-center narnoo-z-10 narnoo-bg-white narnoo-p-2">
            <div class="narnoo-grid narnoo-grid-cols-2 narnoo-mb-1"  v-for="(options, index) in guestsData" :key="index">
            <div :id="`guest-option-label-${index}`" :data-label ="`${options.label}`">
                {{ options.label }} 
            </div>
            <div class="">
                <div class="narnoo-flex narnoo-justify-center narnoo-rounded-lg" role="group">
                <button type="button" v-on:click="addGuest(index,options,'minus')" :class="(customTheme === true || customTheme === 'true' ? 'narnoo-bg-white narnoo-text-blue-500 narnoo-border narnoo-border-r-0 narnoo-border-blue-500 narnoo-secondary-button  narnoo-px-2 narnoo-py-1 narnoo-outline-none focus:narnoo-shadow-outline' : 'narnoo-bg-white narnoo-text-blue-500 narnoo-border narnoo-border-r-0 narnoo-border-blue-500 narnoo-px-2 narnoo-py-1 narnoo-outline-none focus:narnoo-shadow-outline')">
                    <svg xmlns="http://www.w3.org/2000/svg" class="narnoo-h-6 narnoo-w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                </button>
                <input type="text" name="" :id="`guest-no-${index}`" value="0" readonly placeholder="0" class="guest-no narnoo-text-center narnoo-w-full narnoo-bg-white narnoo-text-blue-500   narnoo-border narnoo-border-blue-500 narnoo-px-2 narnoo-py-1 narnoo-outline-none focus:narnoo-shadow-outline">
                <button  v-on:click="addGuest(index,options,'add')" type="button" :class="(customTheme === true || customTheme === 'true' ? 'narnoo-bg-white narnoo-text-blue-500 narnoo-border narnoo-border-r-0 narnoo-border-blue-500 narnoo-secondary-button  narnoo-px-2 narnoo-py-1 narnoo-outline-none focus:narnoo-shadow-outline' : 'narnoo-bg-white narnoo-text-blue-500 narnoo-border narnoo-border-r-0 narnoo-border-blue-500 narnoo-px-2 narnoo-py-1 narnoo-outline-none focus:narnoo-shadow-outline')">
                    <svg xmlns="http://www.w3.org/2000/svg" class="narnoo-h-6 narnoo-w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                </button>
                </div>
            </div>
            </div>
        </div>
    </div>

    <!--pickup location section-->
    <div class="narnoo-col-1 narnoo-mt-3" v-if="pickupOptions!=null">
        <PickupRetail :bookingDetails="bookingDetails" :showPickupError="showPickupError" :customTheme="customTheme"/>
    </div>

    <!--product extras section-->
    <div class="narnoo-col-1 narnoo-mt-3">
        <Extras :bookingDetails="bookingDetails" :isResly="isResly" :customTheme="customTheme"/>
    </div>

    <div :class="(customTheme === true || customTheme === 'true' ? 'narnoo-mt-2 narnoo-p-2 narnoo-primary-bg narnoo-flex justify-content-end' : 'narnoo-mt-2 narnoo-p-2 narnoo-bg-gray-200 narnoo-flex justify-content-end')">
        <button type="submit" :class="(customTheme === true || customTheme === 'true' ? 'narnoo-continue-cart-bg narnoo-ml-auto narnoo-flex narnoo-mt-1 narnoo-py-2 narnoo-px-4 narnoo-border narnoo-border-transparent narnoo-shadow-sm text-md narnoo-font-medium narnoo-rounded-md narnoo-secondary-button' : 'narnoo-continue-cart-bg narnoo-ml-auto narnoo-flex narnoo-mt-1 narnoo-py-2 narnoo-px-4 narnoo-border narnoo-border-transparent narnoo-shadow-sm text-md narnoo-font-medium narnoo-rounded-md narnoo-text-white narnoo-bg-blue-600 hover:narnoo-bg-blue-700 focus:narnoo-outline-none focus:narnoo-ring-2 focus:narnoo-ring-offset-2 focus:narnoo-ring-indigo-500')"
        v-on:click="computeAndRedirect()"
        >
        <span>Continue</span>

        <svg v-if="isLoading" xmlns="http://www.w3.org/2000/svg" class="narnoo-ml-1 narnoo-h-6 narnoo-w-6 narnoo-animate-spin narnoo-transform narnoo-rotate-180" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
        </svg>
        </button>
    </div>

</div>
</template>

<script>
/* eslint-disable */

  const { encrypt,decrypt } = require('../helpers/crypto');
  import PickupRetail from './PickupRetail.vue';
  import Extras  from './Extras.vue';
  import moment from 'moment';

export default {
    components: {
        Extras,
        PickupRetail
    },
    props: ['bookingDetails','productName', 'isResly', 'checkoutUrl', 'customTheme'],
    data(){
        return{
            guestsData:null,
            pickupOptions:null,
            productExtras:null,
            newCartData:{},
            showPickupError:false,
            showGuestError:false,
            isLoading:false,
            showQtyGuestError:false,
            productAvailability: (localStorage.getItem('current.availability') ? localStorage.getItem('current.availability') : ''),
            duplicateAprrovalDataModal : {
                show:false,
                cart:[],
                cartData:[],
                disableButton:false,
                showLoader:false
            }
        }
    },
    mounted() { 
        let widgetInitData = JSON.parse(decrypt(JSON.parse(localStorage.getItem("widgetInitData"))));
        this.parseBookingDetails()
    },
    methods:{

        parseBookingDetails(){

            this.guestsData = this.bookingDetails.guestOptions
            this.pickupOptions = this.bookingDetails.details.data.data.bookingData.productPickUps
            this.productExtras = this.bookingDetails.details.data.data.bookingData.productExtras
            
            let widgetInitData = JSON.parse(decrypt(JSON.parse(localStorage.getItem("widgetInitData"))));  

            if(this.guestsData){
                this.guestsData.map((pricing,i)=>{
                    pricing.qty=0
                    pricing.total = 0
                })
            }
            
            this.newCartData = {
                productId: this.bookingDetails.productId,
                operatorId: this.bookingDetails.supplierId,
                productTitle : this.bookingDetails.tourOption,
                productName: this.productName,
                featuredImage : widgetInitData.featuredImage,
                bookingCode: this.bookingDetails.bookingCode,
                bookingDate: this.bookingDetails.bookingDate,
                bookingDateDisplay : this.bookingDetails.bookingDateDisplay,
                paymentMethod: this.bookingDetails.details.data.data.bookingData.paymentMethods[0].id,  
                pickUp : null,
                option : null,
                extra: null,
                bookingFields:this.bookingDetails.details.data.data.bookingData.bookingFields,
                bookingFieldsPerParticipantCount: this.bookingDetails.details.data.data.bookingData.bookingFieldsPerParticipantCount,
                bookingFieldsPerBookingCount:this.bookingDetails.details.data.data.bookingData.bookingFieldsPerBookingCount,
                currentCartPriceTotal: null,
                productTime: this.bookingDetails.productTime,
                bookingPlatForm:this.bookingDetails.details.data.data.bookingData.bookingPlatform,
                tourOption: this.bookingDetails.tourOption,
                cartItemExp: moment(new Date).add(30, 'm').toDate(),
                currency: this.bookingDetails.guestOptions[0].currency ?? this.operatorCurrency()
            }

            let encryptedNewCartData = encrypt(JSON.stringify(this.newCartData))
            localStorage.setItem("cartData",JSON.stringify(encryptedNewCartData))
            let encryptedBookingFee = encrypt(JSON.stringify(this.bookingDetails.details.data.data.bookingData.bookingFee))
            localStorage.setItem("bookingFee",JSON.stringify(encryptedBookingFee))
            

        },
        operatorCurrency()
        {
        let storedCurrency = JSON.parse(localStorage.getItem("currency"));
        return storedCurrency?.currency ?? null;
        
        },
        addGuest(index,options,action) {
            this.showGuestError =false
            
            let value = parseInt(document.getElementById("guest-no-"+index).value);
            let maxQuantity = options.maxQuantity ? parseInt(options.maxQuantity) : null
            let minQuantity = parseInt(options.minQuantity) || 0


            if(action=='add'){
                value++

                if(value < minQuantity)
                {
                    value = minQuantity;
                }
                
                if(maxQuantity!=null) {
                    if(value > maxQuantity) {
                        value = maxQuantity;
                    }
                }
                
                
            }else{
                value--
                if(value < minQuantity)
                {
                    value = 0;
                }
            }
            document.getElementById("guest-no-"+index).value=value;

            let newCartData = JSON.parse(decrypt(JSON.parse(localStorage.getItem("cartData"))))
            let sourceData = null
            let sourceExtra = newCartData?.extra
            let sourcePickup = newCartData?.pickUp
            if(newCartData?.option==null) {
                sourceData= this.guestsData
            }
            else{
                sourceData = newCartData.option
            }

            sourceData.map((pricing,i)=>{
                if(i==index){
                    pricing.qty = value
                    pricing.currency = this.operatorCurrency()
                    pricing.total = (pricing.qty * pricing.price)
                }
            })

            this.newCartData.option = sourceData 
            this.newCartData.extra = sourceExtra
            this.newCartData.pickUp = sourcePickup
            let encryptedNewCartData = encrypt(JSON.stringify(this.newCartData))
            localStorage.setItem("cartData",JSON.stringify(encryptedNewCartData))
            
        },

        computeAndRedirect(){
            let newCartData = JSON.parse(decrypt(JSON.parse(localStorage.getItem("cartData"))))
              let totalOptions=null
              let pickUpPrice = null
              let extraTotal = null
              let totalQty = null
              let availability = localStorage.getItem('current.availability')
              if(newCartData.extra!=null) {
                  extraTotal = newCartData.extra.map(o => (o.quantity * o.price)).reduce((a,c) => {return a+c})
              }
              else{
                  extraTotal=0
              }

              if(this.pickupOptions!=null && newCartData.pickUp==null) {
                  this.showPickupError=true
                  pickUpPrice=0
              }
              else{
                  this.showPickupError=false
                  pickUpPrice = (newCartData.pickUp) ? newCartData.pickUp.price : 0
              }

              if(newCartData.option==null) {
                  this.showGuestError = true
              }
              else{
                totalOptions = newCartData.option.map(o => o.total).reduce((a,c) => {return a+c})
                totalQty = newCartData.option.map(o => o.qty).reduce((a,c) => {return a+c})

                if(totalOptions>0) {
                    this.showGuestError = false
                }
                else{
                    this.showGuestError = true
                }
                if(totalQty <= availability){
                    this.showQtyGuestError = false
                }
                else{
                    this.showQtyGuestError = true
                }
              }

              if(!this.showGuestError && !this.showPickupError && !this.showQtyGuestError) {
                this.isLoading=true;
                let newCartTotal =pickUpPrice+extraTotal+totalOptions
                newCartData.currentCartPriceTotal = newCartTotal
                
                let cart = (localStorage.getItem("cart")) ? JSON.parse(decrypt(JSON.parse(localStorage.getItem("cart"))))  : localStorage.setItem("cart",JSON.stringify(encrypt('[]')))
                let checkCartDuplicates = cart.filter(data => data.bookingDate === newCartData.bookingDate && data.bookingCode === newCartData.bookingCode )
                if(checkCartDuplicates.length>0){
                    this.duplicateAprrovalDataModal.show = true 
                    this.duplicateAprrovalDataModal.cart = cart 
                    this.duplicateAprrovalDataModal.cartData = newCartData
                }
                else{
                    this.duplicateAprrovalDataModal.show = false 
                    this.duplicateAprrovalDataModal.cart = []
                    this.duplicateAprrovalDataModal.cartData = []

                    cart.push(newCartData);
                    let encryptedCart = encrypt(JSON.stringify(cart));
                    localStorage.setItem("cart",JSON.stringify(encryptedCart));
                    localStorage.removeItem('cartData')
                    let widgetInitData = JSON.parse(decrypt(JSON.parse(localStorage.getItem("widgetInitData"))));  
                    setTimeout(()=>{
                        this.isLoading=false;
                        window.location.href=this.checkoutUrl;
                    },2500)
                }
            
            }
         },

        duplicateApproval(choice){
            this.duplicateAprrovalDataModal.disableButton=true
            this.duplicateAprrovalDataModal.showLoader=true
            let widgetInitData = JSON.parse(decrypt(JSON.parse(localStorage.getItem("widgetInitData")))); 
            if(choice===true){
                let cart = this.duplicateAprrovalDataModal.cart
                let newCartData = this.duplicateAprrovalDataModal.cartData

                cart.push(newCartData)

                let encryptedCart = encrypt(JSON.stringify(cart));
                localStorage.setItem("cart",JSON.stringify(encryptedCart));
                localStorage.removeItem('cartData')
                setTimeout(()=>{
                this.isLoading=false;
                window.location.href=this.checkoutUrl;
                },2500)
            }
            else{
                localStorage.removeItem("cartData");
                setTimeout(()=>{
                this.isLoading=false;
                window.location.href=this.checkoutUrl;
                },2500)
            }
        },


        formatCurrency(price){
          return '$ '+price.toFixed(2);
        },





    },
    
}
</script>