<template>
    <!-- stock running out alert -->
    <div v-if="this.showWarning" class="narnoo-bg-yellow-50 narnoo-border-l-4 narnoo-border-yellow-400 narnoo-p-4">
        <div class="narnoo-flex">
        <div class="narnoo-flex-shrink-0">
            <svg xmlns="http://www.w3.org/2000/svg" class="narnoo-h-5 narnoo-w-5 narnoo-text-yellow-400" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M11.3 1.046A1 1 0 0112 2v5h4a1 1 0 01.82 1.573l-7 10A1 1 0 018 18v-5H4a1 1 0 01-.82-1.573l7-10a1 1 0 011.12-.38z" clip-rule="evenodd" />
            </svg>
        </div>
        <div class="narnoo-ml-3">
            <p class="narnoo-text-sm narnoo-text-yellow-700">
            There are less than {{this.availability}} spots remaining on this option.
            </p>
        </div>
        </div>
    </div>
    <!-- stock running out alert -->
</template>

<script>
/**
 * 
 * Component to render availability numbers warning
 * We show a warning to the customer that the numbers are low.
 * This should encourage them to purchase the ticket faster
 * 
 */

export default {
  name: "AvailabilityWarning",
  props: ['availability'],
  data() {
      return {
          showWarning: false,
      };
    },
    mounted() {
        //Run a check to see if less than 6 spots remaining
        if(this.availability < process.env.VUE_AVAILABILITY_LIMIT){
            this.showWarning = true
        }

    },
  methods: {
   
  }
};
</script>

<style scoped>

</style>