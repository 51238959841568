<template>
<div>
    <p class="narnoo-block narnoo-text-gray-900 narnoo-leading-5 narnoo-font-bold narnoo-mb-2">This product requires some pickup information</p>

    <div :class="(customTheme === true || customTheme === 'true' ? 'narnoo-secondary-border narnoo-pl-0 narnoo-pb-0 narnoo-border-l-0 narnoo-border-t-2' : 'narnoo-border-gray-200 narnoo-pl-0 narnoo-pb-0 narnoo-border-l-0 narnoo-border-t-2')"></div>

    <div class="narnoo-bg-yellow-50 narnoo-border-l-4 narnoo-border-red-400 narnoo-p-4 narnoo-mt-3" v-if="showPickupError">
        <div class="narnoo-flex">
            <div class="narnoo-flex-shrink-0">
                <!-- Heroicon name: solid/exclamation -->
                <svg xmlns="http://www.w3.org/2000/svg" class="narnoo-h-5 narnoo-w-5 narnoo-text-red-400" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M11.3 1.046A1 1 0 0112 2v5h4a1 1 0 01.82 1.573l-7 10A1 1 0 018 18v-5H4a1 1 0 01-.82-1.573l7-10a1 1 0 011.12-.38z" clip-rule="evenodd" />
                </svg>
            </div>
            <div class="narnoo-ml-3">
                <p class="narnoo-text-sm narnoo-text-yellow-700">
                Please select pickup location first!.
                </p>
            </div>
        </div>
    </div>
            


    <div class="tab narnoo-w-full narnoo-overflow-hidden narnoo-mt-3">
        
        <div class="narnoo-bg-yellow-50 narnoo-border-l-4 narnoo-mt-1 narnoo-border-yellow-400 narnoo-p-2" v-if="pickUpLoading">
            <div class="narnoo-flex">
                <div class="narnoo-flex-shrink-0">
                    <svg xmlns="http://www.w3.org/2000/svg" class="narnoo-animate-spin narnoo-transform narnoo-rotate-180 narnoo-h-6 narnoo-w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                    </svg>
                </div>
                <div class="narnoo-ml-3">
                    <p class="narnoo-text-sm narnoo-text-yellow-700">
                    Updating pickup pricing. please wait.
                    </p>
                </div>
            </div>
        </div>

        <div class="narnoo-grid md:narnoo-grid-cols-2 narnoo-mb-2 narnoo-flex narnoo-items-center">

            <div>
                Please Select location:
            </div>

            <div class="">
                <select class="narnoo-mt-1 narnoo-w-full narnoo-block narnoo-text-base narnoo-border-gray-300 focus:narnoo-outline-none focus:narnoo-ring-indigo-500 focus:narnoo-border-indigo-500 sm:narnoo-text-sm narnoo-rounded-md" @change="selectPicUpLocation($event)">
                    <option value="default">Select pickup location</option>
                    <option v-for="(pickup,index) in pickupOptions" :key="index" :value="`${pickup.id}`"> {{pickup.label}}</option>
                </select>  
            </div>
        </div>

    </div>

</div>
</template>

<style scoped>


</style>

<script>

/* eslint-disable */

  const { decrypt,encrypt } = require('../helpers/crypto');
  import * as axios from "axios";

export default {
    components: {
    },
    props: ['bookingDetails','showPickupError', 'customTheme'],
    data(){
        return{
            pickUpLoading:false,
            pickupOptions:null,
            supplierId:null,
            productId:null,
            bookingCode:null,
            bookingDate:null
        }
    },
    mounted() {
        this.supplierId = this.bookingDetails.supplierId
        this.productId = this.bookingDetails.productId
        this.bookingCode = this.bookingDetails.bookingCode
        this.bookingDate = this.bookingDetails.bookingDate.indexOf(" ")>-1 ? this.bookingDetails.bookingDate.split(" ")[0] : this.bookingDetails.bookingDate
        this.pickupOptions = this.bookingDetails.details.data.data.bookingData.productPickUps
    },
    methods:{
        async selectPicUpLocation(event) {
            let pickupId = event.target.value
            let selectedPickUp = this.pickupOptions.filter(data=> data.id == event.target.value)
            
            if(selectedPickUp[0] && selectedPickUp[0].calculatePrice) {
                this.pickUpLoading=true

                let widgetInitData = JSON.parse(decrypt(JSON.parse(localStorage.getItem("widgetInitData"))));
                let user = window.btoa(process.env.VUE_APP_USER_ID + ":" + process.env.VUE_APP_ACCESS_TOKEN);
                let confirmParams  = {
                    headers: { 'Authorization': "Basic " + user },
                    params: {
                        bookingCode: this.bookingCode,
                        bookingDate :  this.bookingDate,
                        pickUp:pickupId
                    }
                }
                axios.get(process.env.VUE_APP_API_URL+"/booking/confirm_price_widget/"+widgetInitData.businessId+"/"+widgetInitData.businessType+"/"+widgetInitData.operatorId+"/"+widgetInitData.bookingId,confirmParams)
                .then(data => this.updateCartPickupData(data,selectedPickUp[0]))
                .catch(error => console.log('User authentication '+error))
            }
            else{
                let newCartData = JSON.parse(decrypt(JSON.parse(localStorage.getItem("cartData"))));  
                newCartData.pickUp = {
                    id : selectedPickUp[0].id,
                    label : selectedPickUp[0].label,
                    price : (selectedPickUp[0].price==null) ? 0 : selectedPickup.price
                }

                let encryptedNewCartData = encrypt(JSON.stringify(newCartData))
                localStorage.setItem("cartData",JSON.stringify(encryptedNewCartData))
            
              
            }

        },
        updateCartPickupData(data,selectedPickup){
            this.pickUpLoading = false
            if (data.data.success) {
                let newCartData  = JSON.parse(decrypt(JSON.parse(localStorage.getItem("cartData"))));
                let sourceData = null
                let sourceExtra = newCartData.extra

                if(newCartData.option==null) {
                    sourceData= data.data.data.productOptions
                    sourceData.map((pricing,i)=>{
                        pricing.qty=0
                        pricing.total = 0
                    })
                }
                else{
                    
                     newCartData.option.forEach(option=>{
                        let newPriceOptions = data.data.data.productOptions.filter(newOption=>newOption.id==option.id)
                       
                            option.currency = newPriceOptions[0].currency;
                            option.collectLevy = newPriceOptions[0].collectLevy;
                            option.levy = newPriceOptions[0].levy,
                            option.commission = newPriceOptions[0].commission;
                            option.pax = newPriceOptions[0].pax;
                            option.minQuantity = newPriceOptions[0].minQuantity;
                            option.maxQuantity = newPriceOptions[0].maxQuantity;
                            option.group = newPriceOptions[0].group;
                            option.id = newPriceOptions[0].id;
                            option.label = newPriceOptions[0].label;
                            option.price = newPriceOptions[0].price;
                        })
                    sourceData = newCartData.option
                 
                }

                sourceData.map((pricing,i)=>{
                    let addedLevy = null
                    pricing.total = (pricing.qty * pricing.price)
                })

                newCartData.option = sourceData 
                newCartData.pickUp = {
                    id : selectedPickup.id,
                    label : selectedPickup.label,
                    price : (selectedPickup.price==null) ? 0 : selectedPickup.price
                }
                newCartData.extra = sourceExtra
                let encryptedNewCartData = encrypt(JSON.stringify(newCartData))
                localStorage.setItem("cartData",JSON.stringify(encryptedNewCartData))
                

            }
            else{
                let newCartData =JSON.parse(decrypt(JSON.parse(localStorage.getItem("cartData"))));
                newCartData.pickUp = {
                    id : selectedPickup.id,
                    label : selectedPickup.label,
                    price : (selectedPickup.price==null) ? 0 : selectedPickup.price
                }
                let encryptedNewCartData = encrypt(JSON.stringify(newCartData))
                localStorage.setItem("cartData",JSON.stringify(encryptedNewCartData))
                
            }
            

        }
    },
    
}
</script>