<template>
<div>
    <p class="narnoo-block narnoo-text-gray-900 narnoo-leading-5 narnoo-font-bold narnoo-mb-2" v-if="productExtras!=null">Product Extras</p>

    <div :class="(customTheme === true || customTheme === 'true' ? 'narnoo-secondary-border narnoo-pl-0 narnoo-pb-0 narnoo-border-l-0 narnoo-border-t-2' : 'narnoo-border-gray-200 narnoo-pl-0 narnoo-pb-0 narnoo-border-l-0 narnoo-border-t-2')"></div>

    <div class="narnoo-w-full narnoo-mx-auto">

        <div v-for="(extras,tabIndex) in productExtras" :key="tabIndex" :id="`extras-tab-${extras.id}`" :class="(customTheme === true || customTheme === 'true' ? 'tab narnoo-w-full narnoo-overflow-hidden narnoo-border narnoo-secondary-border narnoo-mt-2' : 'tab narnoo-w-full narnoo-overflow-hidden narnoo-border narnoo-border-gray-300 narnoo-mt-2')">

            <input class="narnoo-absolute narnoo-opacity-0 narnoo-bg-black" :id="`extras-tab-radio-${tabIndex}`" type="checkbox" name="" v-on:change="tabSelected(tabIndex)">
            <label class="narnoo-truncate narnoo-whitespace-nowrap narnoo-overflow-hidden narnoo-block narnoo-px-5 narnoo-py-3 narnoo-text-gray-900 narnoo-leading-5 narnoo-font-bold narnoo-cursor-pointer" :for="`extras-tab-radio-${tabIndex}`"> 
                {{ extras.label }} 
                <span class="narnoo-text-lg narnoo-leading-7 narnoo-font-bold narnoo-text-gray-900 narnoo-float-right">
                <div class="narnoo-inline narnoo-hidden">Price 
                    <div class="narnoo-inline" :id="`priceTotal-${extras.id}`">0</div>
                </div>
                </span>
            </label>
            
            <div class="narnoo-tab-content narnoo-overflow-hidden narnoo-leading-normal">
                
                
                <p v-if="extras.note" class="narnoo-text-xs narnoo-font-normal narnoo-text-gray-700 narnoo-leading-4 md:narnoo-w-9/12 sm:narnoo-w-full xs:narnoo-w-full narnoo-mt-3 narnoo-mb-3 narnoo-px-5 narnoo-pr-5"> {{extras.note }}</p>
                
                <div v-if="extras.note" :class="(customTheme === true || customTheme === 'true' ? 'narnoo-secondary-border narnoo-pl-0 narnoo-pb-0 narnoo-border-l-0 narnoo-border-t-2' : 'narnoo-border-gray-200 narnoo-pl-0 narnoo-pb-0 narnoo-border-l-0 narnoo-border-t-2')"></div>
                
                <div v-else class="narnoo-pb-3"></div>

                <p class="narnoo-block narnoo-text-gray-900 narnoo-leading-5 narnoo-font-bold narnoo-mb-2 narnoo-px-5">Extra Pricing</p>
                
                <div :class="(customTheme === true || customTheme === 'true' ? 'narnoo-secondary-border narnoo-pl-0 narnoo-pb-0 narnoo-border-l-0 narnoo-border-t-2' : 'narnoo-border-gray-200 narnoo-pl-0 narnoo-pb-0 narnoo-border-l-0 narnoo-border-t-2')"></div>
                
                <div class="narnoo-grid narnoo-grid-cols-2 narnoo-mb-2 narnoo-mt-2 narnoo-px-5 narnoo-pr-5" v-for="(extraPrice,index) in extras.price" :key="index">
                    <div class="narnoo-text-xs narnoo-font-normal narnoo-text-gray-700 narnoo-leading-4">{{ extraPrice.label }} {{productPrices[index].currency}} ${{ extraPrice.price }} </div>
                    
                    <div class="narnoo-text-right narnoo-text-xs narnoo-font-normal narnoo-text-gray-700 narnoo-leading-4">
                        <div class="narnoo-flex narnoo-justify-end narnoo-rounded-lg narnoo-w-full quantity-picker" role="group">
                            <button v-on:click="tabSelected(tabIndex); addExtra(extraPrice.type,'minus',index)" type="button" :class="(customTheme === true || customTheme === 'true' ? 'narnoo-bg-white narnoo-text-blue-500 narnoo-border narnoo-border-r-0 narnoo-border-blue-500 narnoo-secondary-button  narnoo-px-2 narnoo-py-1 narnoo-outline-none focus:narnoo-shadow-outline' : 'narnoo-bg-white narnoo-text-blue-500 narnoo-border narnoo-border-r-0 narnoo-border-blue-500 narnoo-px-2 narnoo-py-1 narnoo-outline-none focus:narnoo-shadow-outline')">
                                <svg xmlns="http://www.w3.org/2000/svg" class="narnoo-h-6 narnoo-w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </button>
                            <input :id="`optionQty-${extras.id}-${index}`" :data-type="`${extras.type}`" :data-qty="0" :data-price="`${extraPrice.price}`" :data-label="`${extras.label}`" :data-pricinglabel="`${extraPrice.label}`" type="text" name="" value="0" readonly placeholder="0" class="narnoo-text-center narnoo-w-full narnoo-bg-white narnoo-text-blue-500   narnoo-border narnoo-border-blue-500 narnoo-px-2 narnoo-py-1 narnoo-outline-none focus:narnoo-shadow-outline">
                            <button v-on:click="tabSelected(tabIndex); addExtra(extraPrice.type,'add',index)" type="button" :class="(customTheme === true || customTheme === 'true' ? 'narnoo-bg-white narnoo-text-blue-500 narnoo-border narnoo-border-r-0 narnoo-border-blue-500 narnoo-secondary-button  narnoo-px-2 narnoo-py-1 narnoo-outline-none focus:narnoo-shadow-outline' : 'narnoo-bg-white narnoo-text-blue-500 narnoo-border narnoo-border-r-0 narnoo-border-blue-500 narnoo-px-2 narnoo-py-1 narnoo-outline-none focus:narnoo-shadow-outline')">
                                <svg xmlns="http://www.w3.org/2000/svg" class="narnoo-h-6 narnoo-w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
                
                <div :class="(customTheme === true || customTheme === 'true' ? 'narnoo-secondary-border narnoo-pl-0 narnoo-pb-0 narnoo-border-l-0 narnoo-border-t-2' : 'narnoo-border-gray-200 narnoo-pl-0 narnoo-pb-0 narnoo-border-l-0 narnoo-border-t-2')"></div>

            </div>
        </div>

    </div>
</div>
</template>

<style scoped>

/* set tab content max height limit */
.narnoo-tab-content {
  max-height: 0;
  -webkit-transition: max-height .35s;
  -o-transition: max-height .35s;
  narnoo-transition: max-height .35s; 
  overflow: narnoo-hidden;
}
 
/* set tab content to its max height when radio button is checked */
.tab input:checked ~ .narnoo-tab-content {
  max-height:unset !important;
  border-color:#0EA5E9; 
}
        
/* Label formatting when open */
.tab input:checked + label{
  narnoo-border-bottom-width: 1px; 
  border-color: #dfdfdf; 
}
.tab label span::after {
  float:right;
  right: 0;
  top: 0;
  display: narnoo-block;
  width: 1.5em;
  height: 1.5em;
  line-height: 1;
  color:#fff;
  font-size: 1.25rem;
  text-align: center;
  -webkit-transition: all .35s;
  -o-transition: all .35s;
  narnoo-transition: all .35s;
}
.tab input[type=checkbox] + label span::after {
  content: "\25BE";
  font-weight:bold; 
  background: #1D4ED8;
  width:28px;
  height:23px;
  margin-left:10px;
}
.tab input[type=checkbox]:checked + label span::after {
  opacity:0;
}



</style>

<script>
/* eslint-disable */

  const { decrypt,encrypt } = require('../helpers/crypto');

export default {
    components: {
    },
    props: ['bookingDetails', 'customTheme'],
    data(){
        return{
            extraTabId : null,
            extraTabIndex: null,
            newCartData:null,
            productExtras:null,
            productPrices:null
        }
    },
    mounted() {
        this.productExtras = this.bookingDetails.details.data.data.bookingData.productExtras
        // get product prices object to use the currency property in the object
        this.productPrices = this.bookingDetails.details.data.data.bookingData.productPrices

        if(this.productExtras){
            
            this.productExtras.map((extras,i)=>{
                extras.price.map(p=>{
                    p.quantity = 0
                    p.id = extras.id
                    p.extraTitle = extras.label
                    p.type = extras.type
                    p.currency = this.operatorCurrency()
                })
            })
        }
    },
    methods:{

        tabSelected(tabIndex) {
            this.extraTabIndex = tabIndex
            this.extraTabId = this.productExtras[tabIndex].id
            let tabs = document.querySelectorAll('input[type="radio"]')
            let selectedTab = 'tab-availability-product-'+tabIndex
            tabs.forEach(tab=>{
                if (tab.id == selectedTab){
                tab.parentElement.classList.remove("narnoo-border-gray-300")
                tab.parentElement.classList.add("narnoo-border-blue-600")
                }
                else{
                tab.parentElement.classList.remove("narnoo-border-blue-600")
                tab.parentElement.classList.add("narnoo-border-gray-300")
                }
            })

        },
        operatorCurrency()
        {
        let storedCurrency = JSON.parse(localStorage.getItem("currency"));
        return storedCurrency?.currency ?? null;
        
        },
        addExtra(type,action,extaOptIndex){
            let cartData =  JSON.parse(decrypt(JSON.parse(localStorage.getItem("cartData"))));  

            if(cartData.option==null) return;

            let totalParticipantCount = cartData.option.map(o => o.qty).reduce((a,c) => {return a+c});

            let value = document.getElementById("optionQty-"+this.extraTabId+"-"+extaOptIndex).value

            if(action=='add'){
                value++

                if(type=='perOrder') {
                    value = 1
                    document.getElementById("optionQty-"+this.extraTabId+"-"+extaOptIndex).value=value
                }
                else if (type=='totalPaxs'){
                    value= totalParticipantCount
                    document.getElementById("optionQty-"+this.extraTabId+"-"+extaOptIndex).value=1
                }
                else if (type=='perPax'){
                    if (value > totalParticipantCount) {
                        value = totalParticipantCount
                    }
                    document.getElementById("optionQty-"+this.extraTabId+"-"+extaOptIndex).value=value
                }
                
            }else{

                if(value>=1) {
                    value--;
                    document.getElementById("optionQty-"+this.extraTabId+"-"+extaOptIndex).value=value
                }
                
            }
            

            // pick and update the value of quatity by its pricing label
            let pricingLabel = document.getElementById("optionQty-"+this.extraTabId+"-"+extaOptIndex).dataset.pricinglabel
            

            this.productExtras.map((extras,i)=>{

                extras.price.map(p=>{
                    if(p.label== pricingLabel && p.id==this.extraTabId){
                        p.quantity = value
                    }
                })

                if(i == this.extraTabIndex) {
                    let currentTotalExtraPrice = extras.price.map(o => (o.quantity * o.price)).reduce((a,c) => {return a+c})
                    document.getElementById("priceTotal-"+this.extraTabId).innerHTML = this.formatCurrency(currentTotalExtraPrice)
                    if(currentTotalExtraPrice >0) {
                        document.getElementById("priceTotal-"+this.extraTabId).parentElement.classList.remove("narnoo-hidden")
                    }
                    else{
                        document.getElementById("priceTotal-"+this.extraTabId).parentElement.classList.add("narnoo-hidden")
                    }
                }
               
               
            })

            let extraArrays = []
            this.productExtras.map((extras,i)=>{
                extras.price.map(p=>{
                    if(p.quantity>0){
                        extraArrays.push(p)
                    }
                })
            })

            this.newCartData = JSON.parse(decrypt(JSON.parse(localStorage.getItem("cartData"))));  
            this.newCartData.extra = (extraArrays.length>0) ?extraArrays : null

            let encryptedNewCartData = encrypt(JSON.stringify(this.newCartData))
            localStorage.setItem("cartData",JSON.stringify(encryptedNewCartData))            

        },
        
        formatCurrency(price){
          return '$ '+price.toFixed(2);
        }
    },
    
}
</script>