<template>
	<div v-if="retailUi === 'true' || retailUi === true" >
    <!-- Booking steps https://tailwindui.com/components/application-ui/navigation/steps -->
    <nav aria-label="Progress">
      <ol :class="`narnoo-space-y-4 md:narnoo-flex md:narnoo-space-y-0 md:narnoo-space-x-8 narnoo-pr-1 narnoo-px-1 narnoo-mt-3 step-progress-${productId}`">
        <li v-for="step in steps" :key="step.name" class="md:narnoo-flex-1">
        <!-- Current Step -->
          <div v-if="step.status === 'complete'" :class="(customTheme === true || customTheme === 'true' ? 'group narnoo-pl-4 narnoo-py-2 narnoo-flex narnoo-flex-col narnoo-border-l-4 narnoo-border-blue-600 hover:narnoo-border-blue-800 md:narnoo-pl-0 md:narnoo-pt-4 md:narnoo-pb-0 md:narnoo-border-l-0 md:narnoo-border-t-4 narnoo-secondary-border' : 'group narnoo-pl-4 narnoo-py-2 narnoo-flex narnoo-flex-col narnoo-border-l-4 narnoo-border-blue-600 hover:narnoo-border-blue-800 md:narnoo-pl-0 md:narnoo-pt-4 md:narnoo-pb-0 md:narnoo-border-l-0 md:narnoo-border-t-4 narnoo-secondary-border')">
            <span :class="(customTheme === true || customTheme === 'true' ? 'narnoo-text-xs narnoo-text-blue-600 narnoo-font-semibold narnoo-tracking-wide narnoo-uppercase group-hover:narnoo-text-blue-800 narnoo-primary-text' : 'narnoo-text-xs narnoo-text-blue-600 narnoo-font-semibold narnoo-tracking-wide narnoo-uppercase group-hover:narnoo-text-blue-800')">{{ step.id }}</span>
            <span class="narnoo-text-sm narnoo-font-medium">{{ step.name }}</span>
          </div>
          <div v-if="step.status === 'current'" :class="(customTheme === true || customTheme === 'true' ? 'group narnoo-pl-4 narnoo-py-2 narnoo-flex narnoo-flex-col narnoo-border-l-4 narnoo-border-blue-600 hover:narnoo-border-blue-800 md:narnoo-pl-0 md:narnoo-pt-4 md:narnoo-pb-0 md:narnoo-border-l-0 md:narnoo-border-t-4 narnoo-secondary-border' : 'group narnoo-pl-4 narnoo-py-2 narnoo-flex narnoo-flex-col narnoo-border-l-4 narnoo-border-blue-600 hover:narnoo-border-blue-800 md:narnoo-pl-0 md:narnoo-pt-4 md:narnoo-pb-0 md:narnoo-border-l-0 md:narnoo-border-t-4 narnoo-secondary-border')">
            <span :class="(customTheme === true || customTheme === 'true' ? 'narnoo-text-xs narnoo-text-blue-600 narnoo-font-semibold narnoo-tracking-wide narnoo-uppercase group-hover:narnoo-text-blue-800 narnoo-primary-text' : 'narnoo-text-xs narnoo-text-blue-600 narnoo-font-semibold narnoo-tracking-wide narnoo-uppercase group-hover:narnoo-text-blue-800')">{{ step.id }}</span>
            <span class="narnoo-text-sm narnoo-font-medium">{{ step.name }}</span>
          </div>
          <div v-else :class="(customTheme === true || customTheme === 'true' ? 'group narnoo-pl-4 narnoo-py-2 narnoo-flex narnoo-flex-col narnoo-border-l-4 narnoo-border-blue-600 hover:narnoo-border-green-400 md:narnoo-pl-0 md:narnoo-pt-4 md:narnoo-pb-0 md:narnoo-border-l-0 md:narnoo-border-t-4 narnoo-primary-border' : 'group narnoo-pl-4 narnoo-py-2 narnoo-flex narnoo-flex-col narnoo-border-l-4 narnoo-border-blue-600 hover:narnoo-border-blue-800 md:narnoo-pl-0 md:narnoo-pt-4 md:narnoo-pb-0 md:narnoo-border-l-0 md:narnoo-border-t-4 narnoo-secondary-border')">
            <span :class="(customTheme === true || customTheme === 'true' ? 'narnoo-text-xs narnoo-text-blue-600 narnoo-font-semibold narnoo-tracking-wide narnoo-uppercase group-hover:narnoo-text-blue-800 narnoo-primary-text' : 'narnoo-text-xs narnoo-text-blue-600 narnoo-font-semibold narnoo-tracking-wide narnoo-uppercase group-hover:narnoo-text-blue-800')">{{ step.id }}</span>
            <span class="narnoo-text-sm narnoo-font-medium">{{ step.name }}</span>
          </div>
        </li>
      </ol>
    </nav>

    <div v-if="currentStep == 1" class="narnoo-w-full narnoo-mx-auto narnoo-mt-7" id="step1Container">
      <!-- Lead in tab should be open | open tab should be blue narnoo-border and other tabs grey boarder -->
      <div v-for="(availability,index) in availabilityData" :key="index" :class="(customTheme === true || customTheme === 'true' ? 'tab narnoo-w-full narnoo-overflow-hidden narnoo-border narnoo-border-gray-300 narnoo-mt-2 narnoo-relative narnoo-secondary-border' : 'tab narnoo-w-full narnoo-overflow-hidden narnoo-border narnoo-border-gray-300 narnoo-mt-2 narnoo-relative')">

        <input class="narnoo-absolute narnoo-border narnoo-opacity-0" :id="`tab-availability-product-${index}`" type="checkbox"  v-on:change="tabSelected($event, index)">
        <label class="narnoo-absolute narnoo-w-full narnoo-mt-1 narnoo-h-10 narnoo-z-10  narnoo-cursor-pointer" :for="`tab-availability-product-${index}`">
          <div :class="(customTheme === true || customTheme === 'true' ? 'narnoo-bg-blue-700 narnoo-h-7 narnoo-w-8 narnoo-absolute narnoo-right-5 narnoo-secondary-bg' : 'narnoo-bg-blue-700 narnoo-h-7 narnoo-w-8 narnoo-absolute narnoo-right-5')">
            <div class="chevron-icon"> </div>
          </div>
        </label>
        
        <div class="narnoo-grid md:narnoo-grid-cols-2 narnoo-grid-cols-1  narnoo-mx-5 tab-header" >
          <div>
            <label class="md:narnoo-text-md narnoo-block narnoo-pt-3 narnoo-text-gray-900 narnoo-leading-5 narnoo-font-bold narnoo-w-5/6">
              {{ availability.title }}
            </label>
          </div>
          <div class="md:narnoo-text-right md:narnoo-pr-5 narnoo-pb-2 narnoo-pt-1 md:narnoo-mr-8">
            <span v-if="availability.lowestPrice" class="narnoo-text-md narnoo-leading-7 narnoo-font-bold narnoo-text-gray-900 narnoo-hidden md:narnoo-block">
              Prices From: {{ availability.lowestPrice | formatMoney(availability.bookingCodes[index].optionPrice[index]?.currency) }}
            </span>
          </div>
        </div>

        <div :id="`tab-content-product-${index}`" class="narnoo-tab-content narnoo-overflow-hidden narnoo-leading-normal narnoo-w-full">
          <div class="narnoo-pb-2 narnoo-border-gray-200 narnoo-pl-0 narnoo-pb-0 narnoo-border-l-0 narnoo-border-t-2"></div>
          
          <span v-if="availability.lowestPrice" class="narnoo-text-md narnoo-leading-7 narnoo-font-bold narnoo-text-gray-900 narnoo-mx-5 md:narnoo-hidden">
            Prices From: {{ availability.lowestPrice | formatMoney }}
          </span>
          
          <div :class="{ 'narnoo-hidden': (retailUi === true && displayDescription === false) || (retailUi === 'true' && displayDescription === 'false') }">
            <p class="narnoo-text-xs narnoo-font-normal narnoo-text-gray-700 narnoo-leading-4 md:narnoo-w-9/12 sm:narnoo-w-full xs:narnoo-w-full narnoo-mt-3 narnoo-mb-3 narnoo-px-5 narnoo-pr-5"> 
              {{ textToHtmlMarkup(availability.description) }}
            </p>
              
            <div class="narnoo-pb-2 narnoo-border-gray-200 narnoo-pl-0 narnoo-pb-0 narnoo-border-l-0 narnoo-border-t-2"></div>
          </div>

          <div class="narnoo-w-full">
            <div v-if="checkReloadCalendar(index)" class="narnoo-overflow-x-auto" style="max-width:620px;margin-left:auto;margin-right:auto">
              <div class="narnoo-animate-pulse narnoo-mb-3 narnoo-px-2">
                <div class="narnoo-flex narnoo-justify-between narnoo-py-4">
                  <div class="narnoo-h-4 narnoo-bg-gray-200 narnoo-w-32 narnoo-rounded-lg"></div>
                  <div class="narnoo-h-4 narnoo-bg-gray-200 narnoo-w-32 narnoo-rounded-lg"></div>
                </div>
                <div v-for="row in 3" :key="row" class="narnoo-my-2 narnoo-flex narnoo-items-center narnoo-overflow-hidden narnoo-justify-between narnoo-border-gray-300 narnoo-rounded-md">
                  <div v-for="i in 7" :key="i" class="narnoo-flex narnoo-p-3 narnoo-flex-auto narnoo-mx-1 narnoo-space-y-1 narnoo-flex-col narnoo-items-center narnoo-w-14 narnoo-h-14 narnoo-bg-gray-300">
                      <div class="narnoo-h-2 narnoo-bg-gray-200 narnoo-w-full narnoo-rounded-lg"></div>
                      <div class="narnoo-h-2 narnoo-bg-gray-200 narnoo-w-3/5 narnoo-rounded-lg"></div>
                      <div class="narnoo-h-2 narnoo-bg-gray-200 narnoo-w-full narnoo-rounded-lg"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="!toggleCalendar">
            <div v-if="retailUi === 'true' || retailUi === true">
              <CalenderRetail v-if="useRangeCalendar==false" :tourOption="availability.title" :inputDates="availability.productAvailability" :productIndex="index" :nextDays="nextDays" :prevDays="prevDays" :navigateSteps="navigateSteps"/>
            </div>
            <div v-else>
              <div class="narnoo-overflow-x-auto">
                <Calendar v-if="useRangeCalendar==false" :tourOption="availability.title" :inputDates="availability.productAvailability" :productIndex="index" :nextDays="nextDays" :prevDays="prevDays" :navigateSteps="navigateSteps"/>
              </div>
            </div>
            <div class="narnoo-w-full">
              <RangeCalendar v-if="useRangeCalendar==true" :tourOption="availability.title"  :inputDates="availability.productAvailability" :productIndex="index" :navigateSteps="navigateSteps" />
            </div>
          </div>
          <div v-if="availability.guestPricing" :class="(retailUi === true && displayGuestPrice === false || retailUi === 'true' && displayGuestPrice === 'false' ? 'narnoo-hidden' : 'narnoo-grid narnoo-grid-cols-1  narnoo-ml-auto')">
            <div class="lg:narnoo-col-span-1">
                <p class="narnoo-block narnoo-mt-5 narnoo-text-gray-900 narnoo-leading-5 narnoo-font-bold narnoo-mb-2 narnoo-px-5">Guest Pricing</p>
                
                <div class=" narnoo-border-gray-200 narnoo-pl-0 narnoo-pb-0 narnoo-border-l-0 narnoo-border-t-2"></div>
                
                <div class="narnoo-grid narnoo-grid-cols-2 narnoo-mt-2 narnoo-px-5 narnoo-pr-5" v-for="(priceOption,index) in availability.guestPricing" :key="index">
                  <div v-if="priceOption.price > 0" class="narnoo-text-xs narnoo-font-normal narnoo-text-gray-700 narnoo-leading-4"> 
                    {{ priceOption.label }} {{ priceOption.qty }} 
                  </div>
                  <div v-if="priceOption.price > 0" class="narnoo-text-right narnoo-text-xs narnoo-font-normal narnoo-text-gray-700 narnoo-leading-4"> 
                    {{ availability.bookingCodes[0].optionPrice[0].currency }} {{ priceOption.price | formatMoney }} 
                  </div>
                </div>
                
                <div class="narnoo-border-gray-200 narnoo-pl-0 narnoo-pb-0 narnoo-border-l-0 narnoo-border-t-2"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

		<div v-else class="narnoo-w-full narnoo-mx-auto narnoo-mt-7" id="step2Container">
			<div :class="(customTheme === true || customTheme ==='true' ? 'tab narnoo-w-full narnoo-relative narnoo-overflow-hidden relative narnoo-border narnoo-secondary-border narnoo-mt-2' : 'tab narnoo-w-full narnoo-relative narnoo-overflow-hidden relative narnoo-border narnoo-border-gray-300 narnoo-mt-2')">
				<Guest :bookingDetails="bookingDetails" :checkoutUrl ="checkoutUrl" :productName="productName" :isResly="isResly" :customTheme="customTheme" />
			</div>
		</div>
    </div>
    <div v-else class="entry">
		<div class="narnoo-m-3" v-if="duplicateAprrovalDataModal.show">
			<div class="min-w-screen narnoo-h-screen animated fadeIn faster  narnoo-fixed  narnoo-left-0 narnoo-top-0 narnoo-flex narnoo-justify-center narnoo-items-center narnoo-inset-0 narnoo-z-50 narnoo-outline-none focus:narnoo-outline-none narnoo-bg-no-repeat narnoo-bg-center narnoo-bg-cover"   id="modal-id">
				<div class="narnoo-absolute narnoo-bg-black narnoo-opacity-80 narnoo-inset-0 narnoo-z-0"></div>
				<div class="narnoo-w-full  narnoo-max-w-lg narnoo-p-5 narnoo-relative narnoo-mx-auto narnoo-my-auto narnoo-rounded-xl narnoo-shadow-lg  narnoo-bg-white ">
					<div class="">
						<div class="narnoo-text-center narnoo-p-5 narnoo-flex-auto narnoo-justify-center">
							<svg xmlns="http://www.w3.org/2000/svg" class="narnoo-w-16 narnoo-h-16 narnoo-flex narnoo-items-center narnoo-text-yellow-500 narnoo-mx-auto  narnoo-rounded-full" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
								<path stroke-linecap="round" stroke-linejoin="round" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
							</svg>
							<div class="narnoo-text-xl narnoo-font-bold narnoo-py-4 ">The same product, with the same booking date is already in your cart.</div>
						
							<svg v-if="duplicateAprrovalDataModal.showLoader" xmlns="http://www.w3.org/2000/svg"  class="narnoo-mx-auto narnoo-animate-spin narnoo-transform narnoo-rotate-180 narnoo-h-8 narnoo-w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
								<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
							</svg>    
						</div>
						
						<div class="narnoo-pb-3 narnoo-flex narnoo-justify-center narnoo-gap-3 narnoo-flex-wrap">
							<button class="narnoo-py-2 narnoo-w-42 narnoo-px-4 narnoo-border narnoo-border-transparent narnoo-primary-bg narnoo-shadow-sm narnoo-text-md narnoo-font-medium narnoo-rounded-md narnoo-text-white  focus:narnoo-outline-none focus:narnoo-ring-2 focus:narnoo-ring-offset-2 focus:narnoo-ring-indigo-500" 
							v-bind:class="[duplicateAprrovalDataModal.disableButton==true ? 'narnoo-bg-gray-300 hover:narnoo-bg-gray-400' : ' narnoo-bg-green-600 hover:narnoo-bg-green-700']"
							:disabled="duplicateAprrovalDataModal.disableButton==true"
							v-on:click="duplicateApproval(true)">
								Add product to cart
							</button>
							<button class="narnoo-py-2 narnoo-w-42 narnoo-px-4 narnoo-border narnoo-border-transparent narnoo-primary-bg narnoo-shadow-sm narnoo-text-md narnoo-font-medium narnoo-rounded-md narnoo-text-white focus:narnoo-outline-none focus:narnoo-ring-2 focus:narnoo-ring-offset-2 focus:narnoo-ring-indigo-500" 
							v-bind:class="[duplicateAprrovalDataModal.disableButton==true ? 'narnoo-bg-gray-300 hover:narnoo-bg-gray-400' : ' narnoo-bg-green-500 hover:narnoo-bg-green-600']"
							:disabled="duplicateAprrovalDataModal.disableButton==true"
							v-on:click="duplicateApproval(false)">
								Continue to cart
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="narnoo-m-3" v-if="duplicateAprrovalDataModal.show">
			<div class="min-w-screen narnoo-h-screen animated fadeIn faster  narnoo-fixed  narnoo-left-0 narnoo-top-0 narnoo-flex narnoo-justify-center narnoo-items-center narnoo-inset-0 narnoo-z-50 narnoo-outline-none focus:narnoo-outline-none narnoo-bg-no-repeat narnoo-bg-center narnoo-bg-cover"   id="modal-id">
				<div class="narnoo-absolute narnoo-bg-black narnoo-opacity-80 narnoo-inset-0 narnoo-z-0"></div>
				<div class="narnoo-w-full  narnoo-max-w-lg narnoo-p-5 narnoo-relative narnoo-mx-auto narnoo-my-auto narnoo-rounded-xl narnoo-shadow-lg  narnoo-bg-white ">
					<div class="">
						<div class="narnoo-text-center narnoo-p-5 narnoo-flex-auto narnoo-justify-center">

							<svg xmlns="http://www.w3.org/2000/svg" class="narnoo-w-16 narnoo-h-16 narnoo-flex narnoo-items-center narnoo-text-yellow-500 narnoo-mx-auto  narnoo-rounded-full" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
								<path stroke-linecap="round" stroke-linejoin="round" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
							</svg>

							<div class="narnoo-text-xl narnoo-font-bold narnoo-py-4 ">The same product, with the same booking date is already in your cart.</div>
						
							<svg v-if="duplicateAprrovalDataModal.showLoader" xmlns="http://www.w3.org/2000/svg"  class="narnoo-mx-auto narnoo-animate-spin narnoo-transform narnoo-rotate-180 narnoo-h-8 narnoo-w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
								<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
							</svg>    
						</div>
						
						<div class="narnoo-pb-3 narnoo-flex narnoo-justify-center narnoo-gap-3 narnoo-flex-wrap">
							<button class="narnoo-py-2 narnoo-w-42 narnoo-px-4 narnoo-border narnoo-border-transparent narnoo-primary-bg narnoo-shadow-sm narnoo-text-md narnoo-font-medium narnoo-rounded-md narnoo-text-white  focus:narnoo-outline-none focus:narnoo-ring-2 focus:narnoo-ring-offset-2 focus:narnoo-ring-indigo-500" 
							v-bind:class="[duplicateAprrovalDataModal.disableButton==true ? 'narnoo-bg-gray-300 hover:narnoo-bg-gray-400' : ' narnoo-bg-green-600 hover:narnoo-bg-green-700']"
							:disabled="duplicateAprrovalDataModal.disableButton==true"
							v-on:click="duplicateApproval(true)">
								Add product to cart
							</button>
							<button class="action-buttons narnoo-bg-white narnoo-text-blue-500 narnoo-px-2 narnoo-py-1 narnoo-border narnoo-border-l-0 narnoo-border-blue-500  narnoo-outline-none focus:narnoo-shadow-outline" 
							v-bind:class="[duplicateAprrovalDataModal.disableButton==true ? 'narnoo-bg-gray-300 hover:narnoo-bg-gray-400' : ' narnoo-bg-green-500 hover:narnoo-bg-green-600']"
							:disabled="duplicateAprrovalDataModal.disableButton==true"
							v-on:click="duplicateApproval(false)">
								Continue to cart
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>

        <!-- Booking steps https://tailwindui.com/components/application-ui/navigation/steps -->
		<nav aria-label="Progress">
			<ol :class="`narnoo-space-y-4 md:narnoo-flex md:narnoo-space-y-0 md:narnoo-space-x-8 narnoo-pr-1 narnoo-px-1 narnoo-mt-3 step-progress-${productId}`">
				<li class="md:narnoo-flex-1">
				<!-- Current Step -->
					<div :class="(customTheme === true || customTheme === 'true' ? 'group narnoo-pl-4 narnoo-py-2 narnoo-flex narnoo-flex-col narnoo-border-l-4 narnoo-border-blue-600 hover:narnoo-border-blue-800 md:narnoo-pl-0 md:narnoo-pt-4 md:narnoo-pb-0 md:narnoo-border-l-0 md:narnoo-border-t-4 narnoo-secondary-border' : 'group narnoo-pl-4 narnoo-py-2 narnoo-flex narnoo-flex-col narnoo-border-l-4 narnoo-border-blue-600 hover:narnoo-border-blue-800 md:narnoo-pl-0 md:narnoo-pt-4 md:narnoo-pb-0 md:narnoo-border-l-0 md:narnoo-border-t-4 narnoo-secondary-border')">
						<span :class="(customTheme === true || customTheme === 'true' ? 'narnoo-text-xs narnoo-text-blue-600 narnoo-font-semibold narnoo-tracking-wide narnoo-uppercase group-hover:narnoo-text-blue-800 narnoo-primary-text' : 'narnoo-text-xs narnoo-text-blue-600 narnoo-font-semibold narnoo-tracking-wide narnoo-uppercase group-hover:narnoo-text-blue-800')">Step 1</span>
            <!-- need to change -->
						<!-- <span class="narnoo-text-sm narnoo-font-medium">Select Tour</span> -->
						<span class="narnoo-text-sm narnoo-font-medium">Check Availability</span>
					</div>
				</li>

				<li class="md:narnoo-flex-1">
				<!-- Upcoming Step -->
					<div class="narnoo-pl-4 narnoo-py-2 narnoo-flex narnoo-flex-col narnoo-border-l-4 narnoo-border-gray-200 hover:narnoo-border-gray-300 md:narnoo-pl-0 md:narnoo-pt-4 md:narnoo-pb-0 md:narnoo-border-l-0 md:narnoo-border-t-4" aria-current="step">
						<span class="narnoo-text-xs narnoo-text-gray-500 narnoo-font-semibold narnoo-tracking-wide narnoo-uppercase">Step 2</span>
						<span class="narnoo-text-sm narnoo-font-medium">Select Options</span>
					</div>
				</li>

				<li class="md:narnoo-flex-1">
				<!-- Upcoming Step -->
					<div class="group narnoo-pl-4 narnoo-py-2 narnoo-flex narnoo-flex-col narnoo-border-l-4 narnoo-border-gray-200 hover:narnoo-border-gray-300 md:narnoo-pl-0 md:narnoo-pt-4 md:narnoo-pb-0 md:narnoo-border-l-0 md:narnoo-border-t-4">
						<span class="narnoo-text-xs narnoo-text-gray-500 narnoo-font-semibold narnoo-tracking-wide narnoo-uppercase group-hover:narnoo-text-gray-700">Step 3</span>
						<span class="narnoo-text-sm narnoo-font-medium">Checkout</span>
					</div>
				</li>
			</ol>
		</nav>
<div class="narnoo-w-full narnoo-mx-auto narnoo-mt-7" :id="`step1Container-${productId}`">
<!-- Lead in tab should be open | open tab should be blue narnoo-border and other tabs grey boarder -->
<div v-for="(availability,index) in availabilityResults" :key="index" :class="(customTheme === true || customTheme === 'true' ? 'tab narnoo-w-full narnoo-overflow-hidden narnoo-border narnoo-border-gray-300 narnoo-mt-2 narnoo-relative narnoo-secondary-border' : 'tab narnoo-w-full narnoo-overflow-hidden narnoo-border narnoo-border-gray-300 narnoo-mt-2 narnoo-relative')">
<div v-if="availability.totalGuests > availability.availabilityDetails[0].availability" class="narnoo-bg-yellow-50 narnoo-border-l-4 narnoo-border-red-400 narnoo-p-4">
<div class="narnoo-flex">
	<div class="narnoo-flex-shrink-0">
		<!-- Heroicon name: solid/exclamation -->
		<svg xmlns="http://www.w3.org/2000/svg" class="narnoo-h-5 narnoo-w-5 narnoo-text-red-400" viewBox="0 0 20 20" fill="currentColor">
			<path fill-rule="evenodd" d="M11.3 1.046A1 1 0 0112 2v5h4a1 1 0 01.82 1.573l-7 10A1 1 0 018 18v-5H4a1 1 0 01-.82-1.573l7-10a1 1 0 011.12-.38z" clip-rule="evenodd" />
		</svg>
	</div>
	<div class="narnoo-ml-3">
		<p class="narnoo-text-sm narnoo-text-yellow-700">
		The selected guest total exceeds the product availability limit ({{ availability.availabilityDetails[0].availability }})
		</p>
	</div>
</div>
</div>

<input class="narnoo-absolute narnoo-border narnoo-opacity-0" :id="`tab-availability-product-${availability.apiData.apiUrlParams.productId}-${index}`" type="checkbox"  v-on:change="tabSelected(index,availability.apiData.apiUrlParams.productId)">
<label class="narnoo-absolute narnoo-w-full  narnoo-mt-1 narnoo-h-10 narnoo-z-10  narnoo-cursor-pointer" :for="`tab-availability-product-${availability.apiData.apiUrlParams.productId}-${index}`"><div :class="(customTheme === true || customTheme === 'true' ? 'narnoo-bg-blue-700 narnoo-h-7 narnoo-w-8 narnoo-absolute narnoo-right-5 narnoo-secondary-bg' : 'narnoo-bg-blue-700 narnoo-h-7 narnoo-w-8 narnoo-absolute narnoo-right-5')"><div class="chevron-icon"></div></div></label>
<div class="narnoo-grid md:narnoo-grid-cols-2 narnoo-grid-cols-1 narnoo-mx-5 tab-header">
<div>
<label class="md:narnoo-text-md narnoo-block narnoo-pt-3 narnoo-text-gray-900 narnoo-leading-5 narnoo-font-bold narnoo-w-5/6">
	{{ (availability.availabilityDetails[0].label!= undefined) ? availability.availabilityDetails[0].label :  availability.productTitle }}
</label>
</div>
<div class="md:narnoo-text-right md:narnoo-pr-5 narnoo-pb-2 narnoo-pt-1 md:narnoo-mr-5">
<span class="narnoo-text-md narnoo-leading-7 narnoo-font-bold narnoo-text-gray-900 narnoo-hidden md:narnoo-block">
	Prices From: {{availability.prices[0].currency}} {{ availability.lowestPrice | formatMoney }}
</span>
</div>
</div>

<div class="tab-content narnoo-overflow-hidden narnoo-leading-normal narnoo-w-full">
<div class="narnoo-pb-2 narnoo-border-gray-200 narnoo-pl-0 narnoo-pb-0 narnoo-border-l-0 narnoo-border-t-2">

</div>
<AvailabilityWarning v-bind:availability="availability.availabilityDetails[0].availability"></AvailabilityWarning>

<span class="narnoo-text-md narnoo-leading-7 narnoo-font-bold narnoo-text-gray-900 narnoo-mx-5 md:narnoo-hidden">
Prices From: {{ availability.lowestPrice | formatMoney }}
</span>
<p class="narnoo-text-xs narnoo-font-normal narnoo-text-gray-700 narnoo-leading-4 md:narnoo-w-9/12 sm:narnoo-w-full xs:narnoo-w-full narnoo-mt-3 narnoo-mb-3 narnoo-px-5 narnoo-pr-5" v-if="(displayDescription===undefined || displayDescription===null || displayDescription==='' || displayDescription===true || displayDescription==='true') && availability.description!=null ">
{{ textToHtmlMarkup(availability.description) }}
</p>

<div class="narnoo-pb-2 narnoo-border-gray-200 narnoo-pl-0 narnoo-pb-0 narnoo-border-l-0 narnoo-border-t-2" v-if="(displayDescription===undefined || displayDescription===null || displayDescription==='' || displayDescription===true || displayDescription==='true') && availability.description!=null"></div>

<div class="narnoo-grid narnoo-grid-cols-1 md:narnoo-w-4/12 narnoo-mb-2" v-if="dayTimeOptions.length>1 && timeSlot==null && !rangeDate">
	<p class="narnoo-block narnoo-text-gray-900 narnoo-leading-5 narnoo-font-bold narnoo-mb-2 narnoo-px-5">Select a tour time</p>

	<select :class="(customTheme === true || customTheme === 'true' ? 'narnoo-ml-5 narnoo-mr-5 narnoo-mt-1 narnoo-block  narnoo-pl-3 narnoo-pr-10 narnoo-py-2 narnoo-text-base sm:narnoo-text-sm narnoo-rounded-md' : 'narnoo-ml-5 narnoo-mr-5 narnoo-mt-1 narnoo-block  narnoo-pl-3 narnoo-pr-10 narnoo-py-2 narnoo-text-base narnoo-border-gray-300 focus:narnoo-outline-none focus:narnoo-ring-indigo-500 focus:narnoo-border-indigo-500 sm:narnoo-text-sm narnoo-rounded-md')" ref="timeSlot">
	<option v-for="(time,index) in dayTimeOptions" :key="index" :value="`${ time.bookingDateDisplay.split(' ')[1] }`"> {{time.bookingDateDisplay.split(" ")[1] }}</option>
	</select>  

</div>
<!-- <Calendar v-if="!rangeDate" :tourOption="rangeData[0].tourOption" :inputDates="rangeData[0].inputDates" :nextDay="nextDay" :prevDay="prevDay" :productIndex="index" :navigateSteps="navigateSteps" :bookingCutoff="bookingCutoff" :showPrevButton="showPrevButton" @getBookingDetails="BookingData" @getBookingtimeIndex="bookingTimeIndex" class="narnoo-w-full md:narnoo-w-1/2 narnoo-mx-auto"/> -->

<RangeCalendar v-if="rangeDate" :tourOption="rangeData[0].tourOption" :inputDates="rangeData[0].inputDates" :productIndex="index" :navigateSteps="navigateSteps" :bookingCutoff="bookingCutoff" @getBookingDetails="BookingData" @getBookingtimeIndex="bookingTimeIndex" class="narnoo-p-1"/>

<div v-if="dayTimeOptions.length>1 && timeSlot==null" class="narnoo-pb-2 narnoo-border-gray-200 narnoo-pl-0 narnoo-pb-0 narnoo-border-l-0 narnoo-border-t-2"></div>

<div class="narnoo-grid narnoo-grid-cols-1 md:narnoo-w-7/12 narnoo-ml-auto narnoo-pr-5">

	<p class="narnoo-block narnoo-text-gray-900 narnoo-leading-5 narnoo-font-bold narnoo-mb-2 narnoo-px-5">Guest Pricing</p>
	
	<div class=" narnoo-border-gray-200 narnoo-pl-0 narnoo-pb-0 narnoo-border-l-0 narnoo-border-t-2"></div>
	
	<div class="narnoo-grid narnoo-grid-cols-2 narnoo-mt-2 narnoo-px-5 narnoo-pr-5" v-for="(priceOption,index) in availability.prices" :key="index">
		<div v-if="priceOption.qty > 0" class="narnoo-text-xs narnoo-font-normal narnoo-text-gray-700 narnoo-leading-4">{{ priceOption.label }} {{ priceOption.qty }} x {{ priceOption.price | formatMoney }}</div>
		<div v-if="priceOption.qty > 0" class="narnoo-text-right narnoo-text-xs narnoo-font-normal narnoo-text-gray-700 narnoo-leading-4">{{ priceOption.currency }} {{ priceOption.total | formatMoney }}</div>
	</div>
	
	<div class="narnoo-border-gray-200 narnoo-pl-0 narnoo-pb-0 narnoo-border-l-0 narnoo-border-t-2"></div>
</div>
<div :class="(customTheme === true || customTheme === 'true' ? 'narnoo-mt-5 narnoo-p-2 narnoo-bg-gray-200 narnoo-flex justify-content-end narnoo-primary-bg' : 'narnoo-mt-5 narnoo-p-2 narnoo-bg-gray-200 narnoo-flex justify-content-end')">
	<button v-if="!rangeDate" type="submit" :class="(availability.totalGuests > availability.availabilityDetails[0].availability  ? 'narnoo-flex narnoo-ml-auto narnoo-mt-1 narnoo-py-2 narnoo-px-4 narnoo-border narnoo-border-transparent narnoo-shadow-sm narnoo-text-md narnoo-font-medium narnoo-rounded-md narnoo-text-white focus:narnoo-outline-none focus:narnoo-ring-2 focus:narnoo-ring-offset-2 focus:narnoo-ring-indigo-500 narnoo-bg-gray-400 hover:narnoo-bg-gray-400' : customTheme === true || customTheme === 'true' ? 'narnoo-flex narnoo-ml-auto narnoo-mt-1 narnoo-py-2 narnoo-px-4 narnoo-border narnoo-border-transparent narnoo-shadow-sm narnoo-text-md narnoo-font-medium narnoo-rounded-md narnoo-text-white   focus:narnoo-outline-none focus:narnoo-ring-2 focus:narnoo-ring-offset-2 focus:narnoo-ring-indigo-500 narnoo-secondary-button' : 'narnoo-flex narnoo-ml-auto narnoo-mt-1 narnoo-py-2 narnoo-px-4 narnoo-border narnoo-border-transparent narnoo-shadow-sm narnoo-text-md narnoo-font-medium narnoo-rounded-md narnoo-text-white   focus:narnoo-outline-none focus:narnoo-ring-2 focus:narnoo-ring-offset-2 focus:narnoo-ring-indigo-500 narnoo-bg-blue-600 hover:narnoo-bg-blue-700')"
	v-on:click="nextStep(
	availability.availabilityDetails[0].bookingDateDisplay,
	availability.apiData,
	index,
	availability.productTitle,
	availability.productName,
	availability.bookingPlatForm,
	availability.availabilityDetails[0].bookingDate,
	)"
	:disabled = "availability.totalGuests > availability.availabilityDetails[0].availability"
	>
		Continue
		<svg :id="`options-loader-${availability.apiData.apiUrlParams.productId}-${index}`" xmlns="http://www.w3.org/2000/svg" class="narnoo-hidden narnoo-animate-spin narnoo-transform narnoo-rotate-180 narnoo-h-5 narnoo-w-5 narnoo-ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
			<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
		</svg>
	</button>
</div>
</div>
</div>

</div>
        <div class="narnoo-w-full narnoo-mx-auto narnoo-mt-7 narnoo-hidden" :id="`step2Container-${productId}`">

          <!-- New message for no product options -->
          <!-- <div class="narnoo-rounded-md narnoo-bg-green-50 narnoo-p-4" v-if="isPickupsAvailable==false && isProductExtrasAvailable==false">
            <div class="narnoo-flex">
              <div class="narnoo-flex-shrink-0">

                <svg class="narnoo-h-5 narnoo-w-5 narnoo-text-green-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                </svg>

              </div>
              <div class="narnoo-ml-3">
                <div class="narnoo-text-sm narnoo-font-medium narnoo-text-green-800">
                  No additional options provided
                </div>
                <div class="narnoo-mt-2 narnoo-text-sm narnoo-text-green-700">
                  <p>
                    This product does not require a noninated pickup location prior to booking and does not offer additional products to purchase before arrival.
                  </p>
                </div>
                <div class="narnoo-mt-4">
                  <div class="narnoo--mx-2 narnoo--my-1.5 narnoo-flex">
                    <button type="button" class="narnoo-flex narnoo-bg-green-50 narnoo-px-2 narnoo-py-1.5 narnoo-rounded-md narnoo-text-sm narnoo-font-medium narnoo-text-green-800 hover:narnoo-bg-green-100 focus:narnoo-outline-none focus:narnoo-ring-2 focus:narnoo-ring-offset-2 focus:narnoo-ring-offset-green-50 focus:narnoo-ring-green-600"
                    v-on:click="computeAndRedirect()"
                    >
                      Continue to cart
                      <svg xmlns="http://www.w3.org/2000/svg" v-if="isLoading" class="narnoo-animate-spin narnoo-transform narnoo-rotate-180 narnoo-h-5 narnoo-w-5 narnoo-ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                      </svg>
                    </button>
                    
                  </div>
                </div>
              </div>
            </div>
          </div> -->

          <PickupLocation 
            v-bind:pickupOptions="pickupOptions"
            v-bind:bookingData="bookingData"
            v-bind:apiData="apiData"
            v-if="isPickupsAvailable"
            v-bind:pickupId= "pickupId"
            v-bind:showPickupError="showPickupError"
            v-bind:customTheme="customTheme"
            v-bind:businessId="businessId"
            v-bind:errorModal="errorModal"
          >
          </PickupLocation>
          <ProductExtras
            v-bind:productExtras="productExtras"
            v-bind:bookingData="bookingData"
            v-bind:productId="productId"
            v-bind:customTheme="customTheme"
            v-if="isProductExtrasAvailable && (displayExtras===true || displayExtras==='' || displayExtras===undefined || displayExtras==='true' || displayExtras==='undefined') "
          >
          </ProductExtras>

          <div :class="(customTheme === true || customTheme === 'true' ? 'narnoo-mt-2 narnoo-p-2 narnoo-bg-gray-200 narnoo-flex justify-content-end narnoo-primary-bg' : 'narnoo-mt-2 narnoo-p-2 narnoo-bg-gray-200 narnoo-flex justify-content-end')">
            <button type="submit" class="narnoo-flex narnoo-primary-bg narnoo-ml-auto narnoo-mt-1 narnoo-py-2 narnoo-px-4 narnoo-border narnoo-border-transparent narnoo-shadow-sm narnoo-text-md narnoo-font-medium narnoo-rounded-md narnoo-text-white focus:narnoo-outline-none focus:narnoo-ring-2 focus:narnoo-ring-offset-2 focus:narnoo-ring-indigo-500"
            :disabled = "!isPickUpCallDone"
            v-bind:class="[ !isPickUpCallDone  ? 'narnoo-bg-gray-400 hover:narnoo-bg-gray-400' : 'narnoo-bg-blue-600 hover:narnoo-bg-blue-700 narnoo-secondary-button' ]"
            v-on:click="computeAndRedirect()"
            >
                Continue
                <svg xmlns="http://www.w3.org/2000/svg" v-if="isLoading || !isPickUpCallDone" class="narnoo-animate-spin narnoo-transform narnoo-rotate-180 narnoo-h-5 narnoo-w-5 narnoo-ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                </svg>
              
            </button>
          </div>

        </div>

    </div>
</template>

<script>

/**
 * 
 * Component to render availability results
 * 
 * Component to add to the cart
 * Need to store:
 * BookingCode for the option
 * Availability BookingDate for the option
 * Quantity
 * Fare Option
 * 
 * 
 * Then we go to a new screen with pick up location and any extra options
 * 
 */

import AvailabilityWarning from './AvailabilityWarning.vue';
import PickupLocation from './PickupLocation.vue';
import ProductExtras from './ProductExtras.vue';
import RangeCalendar from './RangeCalendar.vue';
import numeral from 'numeral';
import * as axios from "axios";
import moment from 'moment';
import Calendar from './Calendar.vue';
import CalenderRetail from './CalenderRetail.vue';
import Guest from './Guest.vue';

import Analytics from '@expo/rudder-sdk-node';

const client = new Analytics(process.env.VUE_APP_WRITE_KEY, process.env.VUE_APP_DATA_PLAN_URL);

const { encrypt,decrypt } = require('../helpers/crypto');

/**
 * 
 */
export default {
  name: "AvailabilityResult",
  components: {
    AvailabilityWarning,
    PickupLocation,
    ProductExtras,
    RangeCalendar,
    Calendar,
    CalenderRetail,
    Guest
    },
  props: [
    'availabilityResults',
    'checkoutUrl',
    'timeSlot',
    'errorModal',
    'pickupId',
    'displayDescription',
    'displayExtras',
    'businessId',
    'operatorId',
    'bookingCutoff',
    'generateMiniCalender',
    'rangeDate',
    'rangeData',
    'navigateSteps',
    'nextDays',
    'prevDays',
    'showPrevButton',
    'customTheme',
    'retailUi',
    'availabilityData',
    'steps',
    'currentStep',
    'productName',
    'bookingDetails',
    'useRangeCalendar',
    'calendarIndex',
    'reloadCalendar',
    'displayGuestPrice'
  ],
  data() {
    /**
     * @param {array} dayTimeOptions
     * @param {array} pickupOptions - List of pickup items for the product
     * @param {array} productExtras - List of extras for the product
     * @param bookingData
     * @param apiData
     * @param {array} bookingDataGuestPricing 
     * @param {boolean} isLoading 
     * @param {boolean} isPickupsAvailable - Do we have pickups for this product
     * @param {boolean} isProductExtrasAvailable - Does the product have any extras available
     * @param {boolean} showPickupError - Was there an error returning Pickups
     * @param {int} productId
     * @param {object} duplicateAprrovalDataModal
     * @param {boolean} isPickUpCallDone - Can we close the pickup loader
     * @param {boolean} isPickupIdValid - Was there pickup information returned with the requested ID. 
     * @param {int} timeIndex 
     * @param {boolean} toggleCalendar 
     */
      return {
        dayTimeOptions : [],
        pickupOptions : [],
        productExtras : [],
        bookingData : null,
        apiData:null,
        bookingDataGuestPricing : [],
        isLoading: false,
        isPickupsAvailable : false,
        isProductExtrasAvailable : false,
        showPickupError:false,
        productId:null,
        duplicateAprrovalDataModal : {
          show:false,
          cart:[],
          cartData:[],
          disableButton:false,
          showLoader:false
        },
        isPickUpCallDone:false,
        isPickupIdValid:false,
        timeIndex: null,
        toggleCalendar:false,
      };
    },
    mounted() { 
      if(this.retailUi !== 'true' && this.retailUi !== true){
        this.checkMultipleTimeSlot();
        this.productId= this.availabilityResults[0].apiData.apiUrlParams.productId
        if(this.availabilityResults.length==1){
          document.getElementById(`tab-availability-product-${this.productId}-0`).click();
        }
      }
    },
  methods: {
    /**
     * checkReloadCalendar
     * 
     * @description - Checks if we have to reload the calendar
     * 
     * @param {int} index 
     */
    checkReloadCalendar(index){
      if(this.reloadCalendar && this.calendarIndex==index){
        this.toggleCalendar=true
        return true
      }
      else{
        this.toggleCalendar=false
        return false
      }
    },
    /**
     * BookingData
     * 
     * @description - Manage the booking data
     * 
     * @param {Object} getBookingDetails 
     */
    BookingData(getBookingDetails) {
      this.nextStep(getBookingDetails.bookingDateDisplay, this.availabilityResults[0].apiData, 0, this.availabilityResults[0].productTitle, this.availabilityResults[0].productName, this.availabilityResults[0].bookingPlatForm,getBookingDetails.bookingDate)
    },
    /**
     * bookingTimeIndex
     * 
     * @description - Gets the booking time index
     * 
     * @param {int} getBookingtimeIndex 
     */
    bookingTimeIndex(getBookingtimeIndex) {
      this.timeIndex = getBookingtimeIndex
    },
    /**
     * nextStep
     * 
     * @description - Moves the UI to the next step which can be extras and pick up location
     * 
     * @param {*} itemBookingDateDisplay 
     * @param {*} apiData 
     * @param {*} index 
     * @param {*} productTitle 
     * @param {*} productName 
     * @param {*} bookingPlatForm 
     * @param {*} itemBookingDate 
     */
	async nextStep(itemBookingDateDisplay,apiData,index,productTitle,productName,bookingPlatForm,itemBookingDate) {

        // document.getElementById(`options-loader-${apiData.apiUrlParams.productId}-${index}`).classList.remove      ("narnoo-hidden");
        document.getElementById(`options-loader-${apiData.apiUrlParams.productId}-${index}`).classList.remove("narnoo-hidden");
        this.isLoading=true;
        let steps = document.querySelectorAll(`.step-progress-${apiData.apiUrlParams.productId} > li`);
        // document.getElementById(`bookingBtnLoader-${this.timeIndex}`).classList.remove("narnoo-hidden")
        let bookingFee = null;

       /* let parseBookingDate = () =>{ 
        if (this.$refs.timeSlot && this.$refs.timeSlot[0].value!=='') {
          let bdate = this.dayTimeOptions.filter(e=>e.bookingDateDisplay.split(" ")[1]==this.$refs.timeSlot[0].value);
          return bdate;
        }
        else {
          let bdate = this.dayTimeOptions.filter(e=>e.bookingDateDisplay.split(" ")[1]== itemBookingDateDisplay.split(" ")[1]);
          return bdate;
        }
        }*/

        let newCartData = {
          productId: apiData.apiUrlParams.productId,
          operatorId: apiData.apiUrlParams.operatorID,
          productTitle : productTitle,
          featuredImage : apiData.apiUrlParams.featuredImage,
          bookingCode: apiData.headers.params.bookingCode,
          bookingDate: itemBookingDate,
          bookingDateDisplay: itemBookingDateDisplay,
          paymentMethod: null,  
          pickUp : null,
          option : null,
          extra: null,
          bookingFields:null,
          bookingFieldsPerParticipantCount: null,
          bookingFieldsPerBookingCount:null,
          currentCartPriceTotal: null,
          cartItemExp: moment(new Date).add(30, 'm').toDate(),
          productName : productName,
          bookingPlatForm : bookingPlatForm,
          productTime : itemBookingDateDisplay.split(" ")[1],
          currency: this.availabilityResults[0].prices[0].currency
        }

        let allowedByCutOff = this.isAllowedByCutOff(newCartData.bookingDateDisplay)
      
        if(allowedByCutOff == false) {
        this.generateMiniCalender()
        return
        }
      
        //Rudderstack tracking
        client.track({
        anonymousId: localStorage.getItem("anonymousId"),
        event: "move to step 2",
        properties: {
          optionSelected: productTitle,
          bookingCode: apiData.headers.params.bookingCode,
          bookingDate: itemBookingDateDisplay,
        },
        })
        await client.flush()
        //Rudderstack tracking

        axios.get(process.env.VUE_APP_API_URL+"/booking/data_widget/"+apiData.apiUrlParams.businessId+"/"+apiData.apiUrlParams.businessType+"/"+apiData.apiUrlParams.operatorID+"/"+apiData.apiUrlParams.productId,apiData.   headers).then((response)=>{
        this.isLoading=false;
        if(response.data.data.success){

			bookingFee = response.data.data.bookingData.bookingFee;

			newCartData.paymentMethod = response.data.data.bookingData.paymentMethods[0].id;
			newCartData.bookingFields = response.data.data.bookingData.bookingFields;
			newCartData.bookingFieldsPerParticipantCount = response.data.data.bookingData.bookingFieldsPerParticipantCount;
			newCartData.bookingFieldsPerBookingCount = response.data.data.bookingData.bookingFieldsPerBookingCount;

			let bookingData = response.data.data.bookingData;
			
			if (bookingData.productPickUps !=null ) {
				if( !(this.pickupId !== 'null' && this.pickupId !== ''  && this.pickupId !== undefined) ) {
          this.isPickupsAvailable =true;
				} 
			} 
			if (bookingData.productExtras !=null ) this.isProductExtrasAvailable =true;

      // Iterate through each item in the array
      bookingData.productExtras.forEach(item => {
          // Add the "currency" property to the item
          item.currency = this.availabilityResults[0].prices[0].currency;
      });

			this.productExtras = bookingData.productExtras;

			this.apiData = apiData;

			let generatePickupAndPricingOptions = () =>{
				this.pickupOptions = bookingData.productPickUps;
				this.bookingData =bookingData;
				
				this.bookingDataGuestPricing= this.availabilityResults[index].prices;

				let options = [];

        //Create the product options based on the API response
				this.bookingDataGuestPricing.forEach(option=>{
					options.push({
						currency : option.currency ?? this.operatorCurrency(),
						collectLevy : option.collectLevy,
						levy : option.levy,
						commission : option.commission,
						pax : option.pax,
						minQuantity : option.minQuantity,
						maxQuantity : option.maxQuantity,
						group : option.group,
						id : option.id,
						label : option.label,
						qty : option.qty,
						price : option.price,
						total : option.total
					})
				})

				newCartData.option = options;
        
        
				let cartData = JSON.parse(decrypt(JSON.parse(localStorage.getItem("cartData"))));
				cartData.push(newCartData)  
        
				let encryptedDataArray = encrypt(JSON.stringify(cartData));

				localStorage.setItem("cartData", JSON.stringify(encryptedDataArray));
			}

          /**
           * pickupId prop condition
           * - pickupId as '' or empty string (will show the pickup dropdown and the entire step 2 process)
           * - pickupId as valid pickup id  (will hide the pickup dropdown and step 2 process. if its present in the response pickup options, then we carry on with confirm pricing call and pickup checking otherwise we remove pickup checking and confirm price call)
           * - pickupId as false (will hide pickup dropdown. this will prevent any confirm price call and pickup checking and move through cart page with pickup set to null )
           * 
           */

          
          // if user provided a proper pickup id in embed code
          if(this.pickupId !== 'null' && this.pickupId !== '' && this.pickupId !== 'false' && this.pickupId !== false  && this.pickupId !== undefined) {
            generatePickupAndPricingOptions()

            let selectedPickup = this.pickupOptions.filter(pickup =>pickup.id==this.pickupId);
            if(selectedPickup.length>0) {
              // if pickup defined in prop actually exist in pickup options then we
              // generatr pickup data and confirm pricing if need.
              // and continue to the cart page
              this.isPickupIdValid=true
              this.selectPickupLocation()

              if(this.displayExtras===true || this.displayExtras==='' || this.displayExtras===undefined || this.displayExtras==='true' || this.displayExtras==='undefined'){
       
                steps[1].children[0].classList.remove("narnoo-border-gray-200");
                steps[1].children[0].classList.remove("hover:narnoo-border-gray-300");
                steps[1].children[0].classList.add("narnoo-border-blue-600");
                steps[1].children[0].classList.add("hover:narnoo-border-blue-800");
                steps[1].children[0].children[0].classList.remove("narnoo-text-gray-500");
                steps[1].children[0].children[0].classList.add("narnoo-text-blue-600");
                document.getElementById(`step2Container-${this.productId}`).classList.remove('narnoo-hidden');
                document.getElementById(`step1Container-${this.productId}`).classList.add('narnoo-hidden');
              }
            }
            else{
              // otherwise we omit any confirm price change. and move a long to cart 
              // this is the block thats gets to run if we set pickup id in prop but is not existing in pickup options.
              // we conclude it as false and let it pass through the cart with pickup as null
              if(this.displayExtras===false ||  this.displayExtras==='false'){
                this.isPickupsAvailable =true;
                this.isPickUpCallDone=true;
                
                steps[1].children[0].classList.remove("narnoo-border-gray-200");
                steps[1].children[0].classList.remove("hover:narnoo-border-gray-300");
                steps[1].children[0].classList.add("narnoo-border-blue-600");
                steps[1].children[0].classList.add("hover:narnoo-border-blue-800");
                steps[1].children[0].children[0].classList.remove("narnoo-text-gray-500");
                steps[1].children[0].children[0].classList.add("narnoo-text-blue-600");
                document.getElementById(`step2Container-${this.productId}`).classList.remove('narnoo-hidden');
                document.getElementById(`step1Container-${this.productId}`).classList.add('narnoo-hidden');
              }
              else{

                this.isPickUpCallDone=true;
                this.isPickupsAvailable =true;

                steps.forEach((step,i)=>{
                  if(i == 1) {
                    step.children[0].classList.remove("narnoo-border-gray-200 narnoo-secondary-border");
                    step.children[0].classList.remove("hover:narnoo-border-gray-300");
                    step.children[0].classList.add("narnoo-border-blue-600 narnoo-secondary-border");
                    step.children[0].classList.add("hover:narnoo-border-blue-800");

                    step.children[0].children[0].classList.remove("narnoo-text-gray-500 narnoo-primary-text");
                    step.children[0].children[0].classList.add("narnoo-text-blue-600 narnoo-primary-text");

                    document.getElementById(`step2Container-${this.productId}`).classList.remove('narnoo-hidden');
                    document.getElementById(`step1Container-${this.productId}`).classList.add('narnoo-hidden');
                    
                    generatePickupAndPricingOptions()
                  }
                  else{
                    step.children[0].children[0].classList.add("narnoo-text-gray-500 narnoo-secondary-border");
                    step.children[0].children[0].classList.remove("narnoo-text-blue-600 narnoo-primary-text");

                    step.children[0].classList.add("narnoo-border-gray-200 narnoo-secondary-border");
                    step.children[0].classList.add("hover:narnoo-border-gray-300");
                    step.children[0].classList.remove("narnoo-border-blue-600 narnoo-secondary-border");
                    step.children[0].classList.remove("hover:narnoo-border-blue-800");
                  }
                })
              }
            }
          }
           // other wise we continue to second step form which include the pickup dropdown and extras option
          else{
            // if we did not set the pickup id in prop, then we did not run any pickup data or price update api and simply let the continue button enabled.
            this.isPickUpCallDone=true

            steps.forEach((step,i)=>{
              if(i == 1) {
                if(this.customTheme === 'true' || this.customTheme === true){
                  step.children[0].classList.remove("narnoo-border-gray-200");
                  step.children[0].classList.remove("hover:narnoo-border-gray-300");
                  step.children[0].classList.add("narnoo-secondary-border");
                  step.children[0].children[0].classList.remove("narnoo-text-gray-500");
                  step.children[0].children[0].classList.add("narnoo-primary-text");
                  document.getElementById(`step2Container-${this.productId}`).classList.remove('narnoo-hidden');
                  document.getElementById(`step1Container-${this.productId}`).classList.add('narnoo-hidden');
                }
                else{
                  step.children[0].classList.remove("narnoo-border-gray-200");
                  step.children[0].classList.remove("hover:narnoo-border-gray-300");
                  step.children[0].classList.add("narnoo-border-blue-600");
                  step.children[0].classList.add("hover:narnoo-border-blue-800");
                  step.children[0].children[0].classList.remove("narnoo-text-gray-500");
                  step.children[0].children[0].classList.add("narnoo-text-blue-600");
                  document.getElementById(`step2Container-${this.productId}`).classList.remove('narnoo-hidden');
                  document.getElementById(`step1Container-${this.productId}`).classList.add('narnoo-hidden');
                }
                generatePickupAndPricingOptions()
              }
              else{
                step.children[0].children[0].classList.add("narnoo-text-gray-500");
                step.children[0].children[0].classList.remove("narnoo-text-blue-600");
                step.children[0].classList.add("narnoo-border-gray-200");
                step.children[0].classList.add("hover:narnoo-border-gray-300");
                step.children[0].classList.remove("narnoo-border-blue-600");
                step.children[0].classList.remove("hover:narnoo-border-blue-800");
              }
            })
          }

        }


        if(bookingFee!=null) {

          let encryptedbookingFee = encrypt(JSON.stringify(bookingFee));

          localStorage.setItem("bookingFee",JSON.stringify(encryptedbookingFee) );
        }
       
      })
      .catch( async (err)=>{
        this.errorModal.show=true
        this.errorModal.errors=["Error generating cart data."]       

        client.track({
          anonymousId: localStorage.getItem("anonymousId"),
          event: "error",
          properties: {
            error: err
          },
        })

        await client.flush()
      })
      
    },

    isAllowedByCutOff(bookingDate){
      bookingDate = bookingDate.split(" ")

      if(bookingDate.length>1){
        bookingDate = `${bookingDate[0].split("-").reverse().join("-")} ${bookingDate[1]}`
      }
      if (this.bookingCutoff !== null && this.bookingCutoff !== undefined && this.bookingCutoff !== '' ) {

        let userDate = new Date()
        let attemptedBookingDate = new Date(bookingDate)
        let dateDiff = (attemptedBookingDate.valueOf() - userDate.valueOf() ) / 36e5;

        // make sure datedifference is not a negative one. negative date difference means user tries to book but his current time already over the booking date time.
        if(dateDiff < 0 ) {
          return false
        }
        else if (parseFloat(this.bookingCutoff)>= dateDiff) {
          // if bookingcutoff hours is greater then that means we will not allow the booking since date difference is within the bookingcutoff
          return false
        }
        else{
          // we return true if date diff is outside bookingcutoff 
          return true
        }
      }
      else{
        // if user did not configure booking cutoff we automatically set the booking as allowed
        return true
      }
    },

    /**
     * selectPickupLocation
     * 
     * @description - When a pick up location has been selected we have to check to see if we have need to adjust the pricing.
     * 
     * @comment - If the pickup option has a 'calculatePrice' flag set to true then we need to recalculate the pricing options.
     *          - We set the pricing options by doing another API call with the pickup details attached to it.
     */
    async selectPickupLocation(){

      this.isPickUpCallDone=false
      
      let cartData = JSON.parse(decrypt(JSON.parse(localStorage.getItem("cartData"))));

      this.apiData.headers.params.pickUp=this.pickupId;
      let selectedPickup = this.pickupOptions.filter(pickup =>pickup.id==this.pickupId);

       if(selectedPickup[0].calculatePrice) {

        try{
         
          let response = await axios.get(process.env.VUE_APP_API_URL+"/booking/confirm_price_widget/"+this.apiData.apiUrlParams.businessId+"/"+this.apiData.apiUrlParams.businessType+"/"+this.apiData.apiUrlParams.operatorID+"/"+this.apiData.apiUrlParams.productId,this.apiData.headers)
          if(response.data.success){
            let newlyUpdatedPriceOptions = response.data.data.productOptions;

            cartData.map((item)=>{
              if(item.productId===this.apiData.apiUrlParams.productId){
                item.option.forEach(option=>{
                  var newPriceOptions = newlyUpdatedPriceOptions.filter(newOption=>newOption.id==option.id);
                  
                  option.currency = newPriceOptions[0].currency ?? this.operatorCurrency();
                  option.collectLevy = newPriceOptions[0].collectLevy;
                  option.levy = newPriceOptions[0].levy,
                  option.commission = newPriceOptions[0].commission;
                  option.pax = newPriceOptions[0].pax;
                  option.minQuantity = newPriceOptions[0].minQuantity;
                  option.maxQuantity = newPriceOptions[0].maxQuantity;
                  option.group = newPriceOptions[0].group;
                  option.id = newPriceOptions[0].id;
                  option.label = newPriceOptions[0].label;
                  option.price = newPriceOptions[0].price;
                
                  option.total = (option.qty * option.price) 
                  
                });
                
                item.pickUp = {
                  id : selectedPickup[0].id,
                  label : selectedPickup[0].label,
                  price : (selectedPickup[0].price==null) ? 0 : selectedPickup[0].price
                }
              }
            })

            let encryptedCartData = encrypt(JSON.stringify(cartData));

            localStorage.setItem("cartData",JSON.stringify(encryptedCartData));
            
          }
        }
        catch(err){

          this.isPickUpCallDone=true
          this.errorModal.show=true
          this.errorModal.errors=["Error updating pickup pricing."]

        }

       }
       else{

         cartData.map((item)=>{
          if(item.productId===this.apiData.apiUrlParams.productId){
            item.pickUp = {
              id : selectedPickup[0].id,
              label : selectedPickup[0].label,
              price : (selectedPickup[0].price==null) ? 0 : selectedPickup[0].price
            }
          }
        })

        this.isPickUpCallDone=true

        let encryptedCartData = encrypt(JSON.stringify(cartData));

        localStorage.setItem("cartData",JSON.stringify(encryptedCartData));

      }

      if( (this.displayExtras===false || this.displayExtras==='false') && this.isPickupIdValid===true ){
        this.computeAndRedirect()
      }
       

    },

    tabSelected(event = null, index) {
      const isChecked = event ? event.target.checked : false;
      let selected = document.querySelector(`#tab-content-product-${index}`);
      isChecked ? selected.classList.add('narnoo-hidden') : selected.classList.remove('narnoo-hidden');

      let tabs = document.querySelectorAll('input[type="checkbox"]');
      let selectedTab = `tab-availability-product-${index}`;

      tabs.forEach(tab=>{
        if (tab.id == selectedTab){
          tab.parentElement.classList.remove("narnoo-border-gray-300");
          tab.parentElement.classList.add("narnoo-border-blue-600");
        }
        else{
          tab.parentElement.classList.remove("narnoo-border-blue-600");
          tab.parentElement.classList.add("narnoo-border-gray-300");
        }
      })
      
    },

    textToHtmlMarkup(htmlString) {
      let div = document.createElement('div');
      div.innerHTML=htmlString;
      return div.textContent ;
    },
    /**
     * checkMultipleTimeSlot
     * @description - Checks to see if there are multiple timeslots for the option and create a "day" view.
     */
    checkMultipleTimeSlot() {
      let AvailableTimeCount = this.availabilityResults[0].availabilityDetails.length;

      if(AvailableTimeCount > 0) {
        let dayTimeOptions = this.availabilityResults[0].availabilityDetails.filter(data=>data.bookingDateDisplay.split(" ")[0]===this.availabilityResults[0].apiData.headers.params.bookingDate);

      dayTimeOptions.forEach(timeOption => {
          this.dayTimeOptions.push(timeOption);
        });
      }

    },

    // nextDays(index,start,end){

    //     let startDate = new Date(end).addDays(1)
    //     let endDate = new Date(end).addDays(6)

    //     this.showPrevButton = true;

    //     this.loadProduct(this.bookingProducts,startDate,endDate,index)
    // },

    // prevDays(index,start,end){
    //     let startDate = new Date(start).subtractDays(6)
    //     let endDate =  new Date(start).subtractDays(1)

    //     this.loadProduct(this.bookingProducts,startDate,endDate,index)
    // },
    
    async computeAndRedirect() {

      let cartData = JSON.parse(decrypt(JSON.parse(localStorage.getItem("cartData"))));  
      cartData = cartData.filter(data=>data.productId===this.productId)


      let doWeHavePickup = document.querySelector(`#pickUpDropdown-${this.productId}`);
      let pricePickUp = 0;

      if(doWeHavePickup!=null){
          if(document.getElementById(`pickUpDropdown-${this.productId}`).value=="default"){
            this.showPickupError=true;
            return
            // window.scrollTo(0, 300);
          }
          else{
            this.showPickupError=false;
            
          }
          pricePickUp = (cartData[0].pickUp!=null) ? cartData[0].pickUp.price : 0;
      }
      else{
        pricePickUp = (cartData[0].pickUp!=null) ? cartData[0].pickUp.price : 0;
      }

      let totalOptions = cartData[0].option.map(o => o.total).reduce((a,c) => {return a+c});
      let totalExtras = (cartData[0].extra==null || cartData[0].extra.length==0) ? 0 : cartData[0].extra.map(o => (o.quantity * o.price)).reduce((a,c) => {return a+c});
      

      cartData[0].currentCartPriceTotal = pricePickUp + totalOptions + totalExtras;
      

      if(!this.showPickupError){
        this.isLoading=true;
        let cart = JSON.parse(decrypt(JSON.parse(localStorage.getItem("cart"))));
        let checkCartDuplicates = cart.filter(data => data.bookingDate === cartData[0].bookingDate && data.bookingCode === cartData[0].bookingCode )
        if(checkCartDuplicates.length>0){
          this.duplicateAprrovalDataModal.show = true 
          this.duplicateAprrovalDataModal.cart = cart 
          this.duplicateAprrovalDataModal.cartData = cartData[0] 
        }
        else{
          this.duplicateAprrovalDataModal.show = false 
          this.duplicateAprrovalDataModal.cart = []
          this.duplicateAprrovalDataModal.cartData = []

          cart.push(cartData[0]);
          let encryptedCart = encrypt(JSON.stringify(cart));
          localStorage.setItem("cart",JSON.stringify(encryptedCart));
          localStorage.removeItem("cartData");

          client.track({
            anonymousId: localStorage.getItem("anonymousId"),
            event: "continue to cart",
            properties: {
              cart: cart
            },
          })

          await client.flush()

          setTimeout(()=>{
            this.isLoading=false;
            window.location.href=this.checkoutUrl;
          },2500)
        }
       
      }

    },

    operatorCurrency()
    {
      let storedCurrency = JSON.parse(localStorage.getItem("currency"));
      return storedCurrency?.currency ?? null;
      
    },
    /**
     * duplicateApproval
     * 
     * @description - if there are duplicate products and the customer accepts this as true.
     * 
     * @param {Boolean} choice
     * 
     */
    async duplicateApproval(choice){
      this.duplicateAprrovalDataModal.disableButton=true
      this.duplicateAprrovalDataModal.showLoader=true
        if(choice===true){
            let cart = this.duplicateAprrovalDataModal.cart
            let newCartData = this.duplicateAprrovalDataModal.cartData

            cart.push(newCartData)

            let encryptedCart = encrypt(JSON.stringify(cart));
            localStorage.setItem("cart",JSON.stringify(encryptedCart));
            localStorage.removeItem('cartData')
            setTimeout(()=>{
            this.isLoading=false;
            window.location.href=this.checkoutUrl;
            },2500)
        }
        else{
            localStorage.removeItem("cartData");
            setTimeout(()=>{
            this.isLoading=false;
            window.location.href=this.checkoutUrl;
            },2500)
        }
    }
   
  },
  filters: {
    formatMoney: (string, currencySymbol = '') => {
        return `${currencySymbol} $${numeral(string).format('0.00')}`;
      }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

hr{
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

label {
    margin-bottom: 0px !important;
}


/* set tab content max height limit */
.tab-content {
  max-height: 0;
  -webkit-transition: max-height .35s;
  -o-transition: max-height .35s;
  transition: max-height .35s;
  overflow: hidden;
}
 
/* set tab content to its max height when checkbox button is checked */
.tab input:checked ~ .tab-content {
  max-height:unset !important;
  border-color:#0EA5E9; 
}


        
.tab input[type=checkbox] + label > div > .chevron-icon:after{

  content: "\25BE";
  width:30px;
  height:30px;
  position:absolute;
  color:rgb(255, 255, 255);
  font-size: 1.25rem;
  text-align: center;
  -webkit-transition: all .35s;
  -o-transition: all .35s;
  transition: all .35s;
}


.tab input[type=checkbox]:checked +  label > div > .chevron-icon::after{
  transform:rotate(180deg);
} 

</style>
